import React from 'react';
import { Form } from 'antd';

// eslint-disable-next-line react/display-name
const makeField = Component => ({
  input,
  meta,
  children,
  label,
  placeholder,
  defaultValue,
  getRef,
  onCustomKeyPress,
  ...rest
}) => {
  return (
    <Form.Item label={label}>
      <Component
        children={children}
        placeholder={placeholder}
        getRef={getRef}
        onCustomKeyPress={onCustomKeyPress}
        {...meta}
        {...input}
        {...rest}
      />
    </Form.Item>
  );
};

export default makeField;
