import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { compose } from '~utils';

import { passwordRecovery, resetPasswordByEmail } from '~redux/profile/actions';
import {
  recoveryPasswordValues,
  isPasswordChanged,
} from '~redux/profile/selectors';
import ChangePassword from './ChangePassword';

const selectors = createStructuredSelector({
  recoveryPasswordValues,
  isPasswordChanged,
});
const actions = { passwordRecovery, resetPasswordByEmail, onFormReset: reset };

const formConect = reduxForm({
  form: 'passwordRecovery',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
});

const reduxConect = connect(
  selectors,
  actions,
);

export default compose(
  reduxConect,
  formConect,
)(ChangePassword);
