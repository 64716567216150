import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.LOAD_REQUEST:
      case actions.DECLINE_REQUEST:
      case actions.APPROVE_REQUEST:
      case actions.ADD_REQUEST:
      case actions.LOAD_BY_OWNER_REQUEST:
      case actions.SEND_CONTRIBUTION_EMAIL_REQUEST:
        return true;
      case actions.LOAD_SUCCESS:
      case actions.LOAD_FAILURE:
      case actions.DECLINE_SUCCESS:
      case actions.DECLINE_FAILURE:
      case actions.APPROVE_SUCCESS:
      case actions.APPROVE_FAILURE:
      case actions.ADD_SUCCESS:
      case actions.ADD_FAILURE:
      case actions.LOAD_BY_OWNER_SUCCESS:
      case actions.LOAD_BY_OWNER_FAILURE:
      case actions.SEND_CONTRIBUTION_EMAIL_SUCCESS:
      case actions.SEND_CONTRIBUTION_EMAIL_FAILURE:
        return false;
      default:
        return state;
    }
  },
  list(state = [], action) {
    switch (action.type) {
      case actions.LOAD_SUCCESS:
      case actions.LOAD_BY_OWNER_SUCCESS:
        return action.data;
      case actions.UPDATED:
        return state.map(contribution =>
          contribution.id === action.data.id ? action.data : contribution,
        );
      case actions.ADD_SUCCESS:
        return [...state, action.data.contribution];
      // not sure if this is still ever called, since I don't think users
      // can edit their contributions (they could add public key before)
      // case actions.UPDATE_SUCCESS:
      case actions.APPROVE_SUCCESS:
      case actions.DECLINE_SUCCESS:
        return state.map(contribution =>
          contribution.id === action.data.contribution.id
            ? action.data.contribution
            : contribution,
        );
      default:
        return state;
    }
  },
});
