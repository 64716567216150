import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { userInvestedAssets } from '~redux/contributions/selectors';

import Asset from './Asset';

const selectors = createStructuredSelector({
  userInvestedAssets,
});

export default connect(
  selectors,
  null,
)(Asset);
