var _require = require('../constants/assets'),
    programmeMemorandumUrl = _require.programmeMemorandumUrl;

var getDocumentName = require('./getDocumentName');

module.exports = getDocLinks = function getDocLinks(files) {
  var _files$assetMemorandu = files.assetMemorandum,
      assetMemorandum = _files$assetMemorandu === void 0 ? [] : _files$assetMemorandu,
      _files$assetSubscript = files.assetSubscription,
      assetSubscription = _files$assetSubscript === void 0 ? [] : _files$assetSubscript;
  var memorandumDoc = assetMemorandum[0] || {};
  var subscriptionDoc = assetSubscription[0] || {};
  return [{
    url: programmeMemorandumUrl,
    name: getDocumentName(programmeMemorandumUrl)
  }, {
    url: memorandumDoc.url || '',
    name: memorandumDoc.name || getDocumentName(memorandumDoc.url)
  }, {
    url: subscriptionDoc.url || '',
    name: subscriptionDoc.name || getDocumentName(subscriptionDoc.url)
  }];
};