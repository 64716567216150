import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Onboarding from './Onboarding';

import { loading } from '~redux/profile/selectors';
import { getAccessToken, updateUser } from '~redux/profile/actions';
import { profile } from '~redux/profile/selectors';

const selectors = createStructuredSelector({
  loading,
  profile,
});
const actions = {
  getAccessToken,
  updateUser,
};

export default connect(
  selectors,
  actions,
)(Onboarding);
