import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import Helmet from './components/HelmetWrapper/';
import { addLocationQuery } from './helpers/routeParams';
import ScrollToTop from './components/ScrollToTop';

import 'antd/dist/antd.css';
import 'react-phone-input-2/dist/style.css';

import api from './api';
import configureStore from './redux/store';

import Routes from './routes';

const history = createBrowserHistory();

addLocationQuery(history);

history.listen(() => addLocationQuery(history));

const store = configureStore(api, history);

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Helmet />
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </ConnectedRouter>
      </Provider>
    );
  }
}
