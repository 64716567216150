import React, { Component } from 'react';
import { Tabs, Icon } from 'antd';
import { Link } from 'react-router-dom';
import Aside from './components/Aside';
import Description from './components/Description';
import AssetProgressBar from './components/AssetProgressBar';
import EmailForm from '../AssetContent/components/AsideForm';
import AssetReport from './components/AssetReport';

import { USER_STATUS } from '~constants/users';
import { ROUTES } from '~constants/routes';

import ss from './AssetContent.module.scss';

const { TabPane } = Tabs;

const TABS = {
  DESCRIPTION: 'Description',
  ASSET_REPORT: 'Asset report',
  FEATURES: 'Features',
};

export default class AssetContent extends Component {
  state = {
    activeTab: TABS.DESCRIPTION,
  };

  onTabChange = activeTab => {
    this.setState({ activeTab });
  };

  renderTabs = () => {
    const { profileStatus, asset } = this.props;
    const { activeTab } = this.state;
    const showReportTab = profileStatus === USER_STATUS.KYC_APPROVED;

    return (
      <Tabs activeKey={activeTab} onChange={this.onTabChange}>
        <TabPane tab={TABS.DESCRIPTION} key={TABS.DESCRIPTION}>
          <Description asset={asset} />
        </TabPane>
        {showReportTab && (
          <TabPane tab={TABS.ASSET_REPORT} key={TABS.ASSET_REPORT}>
            <AssetReport asset={asset} />
          </TabPane>
        )}
      </Tabs>
    );
  };

  render() {
    const {
      logo,
      asset: { financed },
      isAuthenticated,
      isClosed,
      rootClass,
      remainingSaleDays,
    } = this.props;

    return (
      <div className={ss.content}>
        <div className={ss.block}>
          <div className={ss.head}>
            <div className={ss.top}>
              <Link to={ROUTES.PROJECTS}>
                <Icon type="left" className={ss.backIcon} />
                <span>Back to the list</span>
              </Link>
              <p>
                <span className={ss.days}>{remainingSaleDays} </span>
                Days left
              </p>
            </div>
            <AssetProgressBar progress={financed} />
          </div>

          <Aside
            className={ss.aside}
            isClosed={isClosed}
            isAuthenticated={isAuthenticated}
            logo={logo}
            rootClass={rootClass}
          />

          <div className={ss.body}>
            <div className={ss.subHeader}>{this.renderTabs()}</div>
          </div>
          <div className={ss.footer}>
            <p>
              Be the first to know when a new tokenized asset will be for sale.
            </p>
            <EmailForm source="Asset Page" />
          </div>
        </div>
      </div>
    );
  }
}
