import { connect } from 'react-redux';
import { compose } from '~utils';

import {
  declineContribution,
  approveContribution,
  loadContributions,
  sendContributionEmail,
} from '~redux/contributions/actions';

import Table from './Table';

const actions = {
  declineContribution,
  loadContributions,
  approveContribution,
  sendContributionEmail,
};

const reduxConnect = connect(
  null,
  actions,
);

export default compose(reduxConnect)(Table);
