import React from 'react';

import PrivacyText from './components';
import s from './PrivacyPolicy.module.scss';

const PrivacyPolicy = () => {
  return (
    <div className={s.container}>
      <div className={s.title}>Privacy Policy</div>
      <div className={s.content}>
        {/* <div className={s.cookies}>Cookies</div>
        <div className={s.devider} /> */}
        <div className={s.text}>
          <PrivacyText />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
