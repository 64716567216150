import { reduxForm } from 'redux-form';

import AssetDocuments from './AssetDocuments';

const formConect = reduxForm({
  form: 'assetFiles',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default formConect(AssetDocuments);
