import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  notifications,
  isAdmin,
  isSuperAdmin,
  username,
} from '~redux/profile/selectors';
import { logout } from '~redux/profile/actions';

import LoggedInMenu from './LoggedInMenu';

const selector = createStructuredSelector({
  isAdmin,
  notifications,
  isSuperAdmin,
  username,
});

const actions = {
  logout,
};

export default connect(
  selector,
  actions,
)(LoggedInMenu);
