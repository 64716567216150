import benefit1 from '~image-assets/home/benefit_1.svg';
import benefit2 from '~image-assets/home/benefit_2.svg';
import benefit3 from '~image-assets/home/benefit_3.svg';

import process1 from '~image-assets/home/process_1.svg';
import process2 from '~image-assets/home/process_2.svg';
import process3 from '~image-assets/home/process_3.svg';

import proposition1 from '~image-assets/home/proposition_1.svg';
import proposition2 from '~image-assets/home/proposition_2.svg';
import proposition3 from '~image-assets/home/proposition_3.svg';

import discover1 from '~image-assets/home/discover_1.svg';
import discover2 from '~image-assets/home/discover_2.svg';
import discover3 from '~image-assets/home/discover_3.svg';
import discover4 from '~image-assets/home/discover_4.svg';
import discover5 from '~image-assets/home/discover_5.svg';

import leTempsLogo from '~image-assets/logos/letemps.png';
import bilanLogo from '~image-assets/logos/logo_bilan.png';
import cominmagLogo from '~image-assets/logos/cominMag.png';

export const advantages = [
  {
    title: 'Hardware-as-a-Service',
    img: benefit1,
    content:
      'A business model in which hardware usually belonging to a managed service provider (MSP) is generating revenues based on usage by end customers.',
  },
  {
    title: 'Profit sharing approach',
    img: benefit2,
    content:
      'Eltenemo pushes the model in a profit-sharing approach to accelerate adoption of impact technologies whilst benefiting all key stakeholders.',
  },
  {
    title: 'Owned by the investors',
    img: benefit3,
    content:
      'In this approach, Hardware is now owned by the investors via Eltenemo’s dedicated SPV. The Tech Co acts as the MSP and retains IP.',
  },
];

export const processes = [
  {
    title: 'Create an account',
    img: process1,
    content: [
      {
        text: [
          {
            bold: true,
            content: 'Identify yourself ',
          },
          {
            content: 'and get your credentials.',
          },
        ],
      },
      {
        text: [
          {
            content:
              'Before being authorized to see the details of our offering, be whitelisted via an ',
          },
          {
            bold: true,
            content: 'automated and online KYC/AML process',
          },
        ],
      },
    ],
  },
  {
    title: 'Check the different offering',
    img: process2,
    content: [
      {
        text: [
          {
            bold: true,
            content: 'Discover ',
          },
          {
            content: 'current, past and future ',
          },
          {
            bold: true,
            content: 'offering.',
          },
        ],
      },
      {
        text: [
          {
            content: 'All offering are promoted directly by the issuer on a ',
          },
          {
            bold: true,
            content: 'white-label ',
          },
          {
            content: 'basis.',
          },
        ],
      },
    ],
  },
  {
    title: 'Invest ',
    img: process3,
    content: [
      {
        text: [
          {
            bold: true,
            content: 'Check ',
          },
          {
            content:
              'all investment details via the bond note offering memorandum.',
          },
        ],
      },
      {
        text: [
          {
            bold: true,
            content: 'Confirm ',
          },
          {
            content: 'the amount via a subscription note.',
          },
        ],
      },
      {
        text: [
          {
            bold: true,
            content: 'Provide ',
          },
          {
            content: 'us with your details to receive investment documents.',
          },
        ],
      },
      {
        text: [
          {
            bold: true,
            content: 'Instruct ',
          },
          {
            content: 'your payment. ',
          },
        ],
      },
    ],
  },
];

export const propositions = [
  {
    title: 'Improve the quality of people’s lives',
    img: proposition1,
    content: [
      'Greenhouse gas reduction',
      'Decarbonized electrification',
      'Mobility',
      '(Affordable) Healthcare',
      'Affordable & Sustainable housing',
    ],
  },
  {
    title: 'IRR and ROI of the quantified savings or profit',
    img: proposition3,
    content: [
      'Based on the complete lifecycle of the product',
      'Cash flow requirements',
      'Overall return',
    ],
  },
  {
    title: 'Products specs must include a minimum of IoT features',
    img: proposition2,
    content: [
      'Localization of the asset 24/7',
      'Measurement and report of specific Impact KPI’s',
      'On/Off switch to enable contractually authorized remote control',
    ],
  },
];

export const discovers = [
  {
    title: 'Stay informed on your token holdings via a dedicated dashboard.',
    img: discover1,
  },
  {
    title: 'Direct contact with the issuer.',
    img: discover2,
  },
  {
    title: 'Inform the community about your interest to buy or sell',
    img: discover3,
  },
  {
    title: 'Keep an eye on reporting data',
    img: discover4,
  },
  {
    title: 'Be informed if any dividend is paid  by the issuer.',
    img: discover5,
  },
];

export const news = [
  {
    date: '17.01.2019',
    resource: leTempsLogo,
    title: 'La fintech WeCan.Fund change de nom',
    body:
      'L’entreprise genevoise WeCan.Fund, créée en 2015, révise son architecture de marque et devient Wecan.',
  },
  {
    date: '17.01.2019',
    resource: bilanLogo,
    title: 'Vers une certification plus transparente des guides en',
    body:
      'Valais L’entreprise genevoise WeCan.Fund, créée en 2015, révise son architecture de marque et devient Wecan.',
  },
  {
    date: '16.01.2019',
    resource: cominmagLogo,
    title:
      'Des vignerons aux guide de montagne, les PME suisses découvrent la blockchain',
    body:
      'Les technologies décentralisées peuvent faciliter le financement des petites entreprises et améliorer leurs processus. Un projet blockchain adapté à leur taille peut coûter de 100’000 à 200’000 francs.',
  },
  {
    date: '17.01.2019',
    resource: leTempsLogo,
    title: 'La fintech WeCan.Fund change de nom',
    body:
      'L’entreprise genevoise WeCan.Fund, créée en 2015, révise son architecture de marque et devient Wecan.',
  },
  {
    date: '17.01.2019',
    resource: bilanLogo,
    title: 'La fintech WeCan.Fund change de nom',
    body:
      'L’entreprise genevoise WeCan.Fund, créée en 2015, révise son architecture de marque et devient Wecan.',
  },
  {
    date: '16.01.2019',
    resource: cominmagLogo,
    title:
      'Des vignerons aux guide de montagne, les PME suisses découvrent la blockchain',
    body:
      'Les technologies décentralisées peuvent faciliter le financement des petites entreprises et améliorer leurs processus. Un projet blockchain adapté à leur taille peut coûter de 100’000 à 200’000 francs.',
  },
];
