import React, { Component } from 'react';
import { Table, Button, Tag } from 'antd';
import styles from './AdminAssets.module.scss';
import UserInfo from '../../components/UserInfo';

import { STATUSES } from '~constants/assets';

const { Column } = Table;

const statusColors = {
  [STATUSES.PENDING]: 'cyan',
  [STATUSES.APPROVED]: 'green',
  [STATUSES.ON_SALE]: 'green',
  [STATUSES.ENDED]: undefined,
};

class AdminAssets extends Component {
  componentWillMount() {
    this.props.allAssets();
  }

  renderStatus = status => <Tag color={statusColors[status]}>{status}</Tag>;

  renderActions = (id, { status }) => (
    <span>
      {status === STATUSES.PENDING && (
        <Button
          id={id}
          size="small"
          onClick={() => this.props.approveAsset(id)}
        >
          approve
        </Button>
      )}
      {status === STATUSES.APPROVED && (
        <Button
          id={id}
          size="small"
          onClick={() => this.props.startSaleAsset(id)}
        >
          start sale
        </Button>
      )}
    </span>
  );

  render() {
    const { loading, list } = this.props;

    return (
      <div className={styles.table}>
        <UserInfo />
        <Table
          rowKey="id"
          size="small"
          loading={loading}
          dataSource={list}
          pagination={false}
        >
          <Column title="name" dataIndex="name" />
          <Column title="type" dataIndex="type" />
          <Column title="tokenName" dataIndex="tokenName" />
          <Column title="totalOfTokens" dataIndex="totalOfTokens" />
          <Column
            title="status"
            dataIndex="status"
            render={this.renderStatus}
          />
          <Column title="value" dataIndex="value" />
          <Column title="valueOfOneToken" dataIndex="valueOfOneToken" />
          <Column title="actions" dataIndex="id" render={this.renderActions} />
        </Table>
      </div>
    );
  }
}

export default AdminAssets;
