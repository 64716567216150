import React, { useEffect } from 'react';
import { statutDocumentUrl } from '~constants/assets';

import s from '../LegalsMentions.module.scss';

const TermsText = () => {
  useEffect(() => {
    const modal = document.getElementsByClassName(
      'ant-modal-confirm-body-wrapper',
    )[0];

    if (!modal) return;

    modal.scrollTo(0, 0);
  }, []);

  return (
    <>
      <p className={s.center}>
        <strong>A. GENERAL CONDITIONS</strong>
      </p>
      <p>
        1. These terms and conditions (the "
        <strong>Terms and Conditions"</strong>) of Wecan Tokenize SA, Route du Manège 54A, 1965 Sion, Switzerland (<strong>"WCT"</strong>),
        govern the conditions of use and the rights and obligations of users of
        the website{' '}
        <u>
          <a
            href="https://www.invest.wecantokenize.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.invest.wecantokenize.com
          </a>
        </u>{' '}
        (the <strong>"User(s)"</strong>) and the services available on the
        Website.
      </p>
      <p>
        2. By accessing and browsing the Website, the User acknowledges that it
        has read, understood and agreed to be bound by:
      </p>
      <p>
        - these Terms and Conditions: if the User does not agree to be bound by
        the Terms and Conditions set forth below, it shall refrain from
        accessing the Website or any of its pages;
      </p>
      <p>
        - the Website’s Privacy Policy (hereby incorporated to the present Terms
        and Conditions per reference).
      </p>
      <p>
        3. Any special agreements with respect to individual services or
        products of WCT shall be complementary to these Terms and Conditions and
        is expressly reserved. For instance, regarding the Promoter, it shall
        execute a specific agreement with WCT before being referred on the
        Website, covering in particular the marketing &amp; introducer fees due
        to WCT for its crowdfunding activities. In the event of any conflict,
        the provisions contained in any such special agreements shall prevail.
        Regarding the Issuer, the private placement memorandum (the{' '}
        <strong>"PPM"</strong>) and subscription agreement are expressly
        reserved.
      </p>
      <p>
        4. With regards to the amounts subscribed by the Contributor (as defined
        in Section B. below) to the benefit of the Issuer and ultimate benefit
        of the Promoter (as defined in Section B below), such subscription is
        performed under the responsibility of the Promoter as well as the Issuer
        and according to their contractual documentation (i.e. under their
        specific agreement, terms &amp; conditions, PPM and/or subscription form
        or agreement). Neither WCT nor the Website is part of any of these
        agreements (in particular between the Contributor and Issuer) and, more
        generally, of any of these separate contractual relationships.
      </p>
      <p>
        5.{' '}
        <a href={statutDocumentUrl} target="_blank" rel="noopener noreferrer">
          Deed of incorporation.
        </a>
      </p>
      <p className={s.center}>
        <strong>B. DEFINITIONS</strong>
      </p>
      <p>
        <strong>1. Promoter(s): </strong>Swiss or foreign companies having
        issued tokens and willing to use the Website to contact Contributors in
        order to finance themselves.
      </p>
      <p>
        <strong>2. Issuer :</strong> WeCan Tokenize Luxembourg (securitization
        company / <em>société de titrisation</em>), a securitization company (
        <em>société de titrisation</em>) governed by the Luxembourg law of March
        22, 2014, as amended (
        <em>Loi du 22 mars 2004 relative à la titrisation</em>) (the{' '}
        <strong>"Securitisation Law"</strong>).
      </p>
      <p>
        <strong>3. Contributor(s): </strong>Professional / qualified investors,
        whether natural persons (of minimum 18 years of age with unrestricted
        capacity to act) or duly incorporated legal entities, Swiss or foreign,
        wishing to support the projects proposed on the Website are referred to
        as Contributors.
      </p>
      <p>
        <strong>4. User(s): </strong>Collectively refers to Promoters and
        Contributors.
      </p>
      <p>
        <strong>5. Website: </strong>Designates the digital crowdfunding
        platform operated by WCT and exclusively aimed at putting Users into
        contact.
      </p>

      <p className={s.center}>
        <strong>C. ACTIVITY</strong>
      </p>
      <p>
        1. The Website operated by WCT allows Promoters to enter into contact
        with Contributors interested by their fundraising (crowdfunding)
        projects. In this respect, the Website's purpose is strictly limited to
        connecting Promoters with Contributors.
      </p>
      <p>
        2. The Issuer is a securitization company (
        <em>société de titrisation</em>) governed by Securitization Law. The
        issuance performed by the Issuer shall be limited to a limited number of
        private qualified investors. According to Article 1-1(2)(p) of the
        Luxembourgish law of April 5, 1993 on the financial sector, as amended,
        a securitization company does not need to be regulated by the
        “Commission de Surveillance du Secteur Financier” in Luxembourg (the{' '}
        <strong>"CSSF"</strong>), provided that specific conditions are met (for
        instance, maximum of three issuances during a twelve-month period
        authorized without a license).
      </p>
      <p>
        3. The Issuer does not qualify as a foreign (i.e. non-Swiss) collective
        investment schemes within the meaning of Article 119 of the Swiss
        Collective Investment Schemes Act of June 23, 2006. More particularly,
        the Issuer does not qualify as a Luxembourgish collective investment
        schemes (<em>fonds</em>). No fund management company (including asset
        manager) is making any decision for Contributors as third party (absence
        of management by a third party / <em>Fremdverwaltung</em>). Moreover,
        the Issuer does not perform any collective capital investment for
        Contributors.
      </p>
      <p>
        4. Each fundraising projects on the Website is limited to a limited
        number of qualified, professional and selected Contributors.
      </p>
      <p>
        5. Neither WCT nor the Website undertakes any of the following
        activities:
      </p>
      <p>
        - Banking activities: WCT does not accept deposits from the public in a
        professional capacity or use the public to obtain them;
      </p>
      <p>
        - Securities dealer (<em>négociant en valeurs mobilières</em>,{' '}
        <em>maison de titre</em> / <em>Wertpapierhäuser</em>) activities: WCT
        does not, in a professional capacity:
      </p>
      <p className={s.squarePoints}>
        ▪ trade securities in its own name, on behalf of customers;
      </p>
      <p className={s.squarePoints}>
        ▪ trade securities on a short-term basis for its own account and is not
        mainly active on financial markets and could thus not jeopardize the
        proper functioning of said markets or operate as a member of a trading
        platform;
      </p>
      <p className={s.squarePoints}>
        ▪ Trade in securities on a short-term basis and publicly offer, whether
        permanently or upon request, a price for certain securities (market
        making);
      </p>
      <p>
        - Financial market infrastructure activities: WCT is not an exchange or
        trading platform, more particularly does not allow a simultaneous
        exchange of securities (on the our Website).
      </p>
      <p>
        - Asset management: WCT does not administer or manage assets of third
        parties (including Contributors or Users), neither acts as trustee. The
        Issuer does not manage the shares or units of a collective investment
        scheme;
      </p>
      <p>
        - Financial advice: WTC does not perform any advice, including any
        financial advice.
      </p>
      <p>
        - Execution only: WTC does not communicate any execution order in the
        name and on behalf of the User. If interested by a project, the User
        shall communicate directly with the Issuer in relation to a specific
        Promoter’s crowdfunding project.
      </p>
      <p>
        The Users will have their own individual account with a bank, but at no
        time will they have any account or sub-account with WCT or the Website.
        The amounts subscribed by Contributors will not transit via WCT or the
        Website’s account, but will be transmitted directly from the Contributor
        to the Issuer, under the Issuer’s contractual documentation. Given the
        above, neither WCT nor the Website is acting as a financial intermediary
        within the meaning of the Swiss Anti-Money Laundering (AMLA).
      </p>
      <p className={s.center}>
        <strong>D. USER ACCESS AND USER ACCOUNT</strong>
      </p>
      <p>
        1. Access to the Website requires a prior registration process and the
        opening of a User Account, on the basis of the Website’s own Know Your
        Customer (KYC) process.
      </p>
      <p>
        2. Each User may register for one account only, which is personal and
        not transferable.
      </p>
      <p>
        3. Access to the Website or to its services may be restricted at any
        time, including when necessary for security reasons or for
        implementation of technical measures. Users may not derive any claim
        from such restrictions.
      </p>
      <p>
        4. Any special agreements with respect to individual WCT services or
        products shall be complementary to the present Terms and Conditions. In
        the event of any conflict, the provisions contained in any such special
        agreements shall prevail.
      </p>
      <p>
        5. Access to the information of each User Account shall be kept secret
        under the sole responsibility and at the sole risk of the User. WCT may
        not be held liable in case of undue access to the User Account by a
        third party.
      </p>
      <p>
        6. The User guarantees that any information provided by the User to WCT
        via the Website is complete, accurate and truthful. The User is
        responsible for keeping this information up to date and notifying WCT
        should a change occur.
      </p>
      <p> </p>
      <p className={s.center}>
        <strong>E. ONBOARDING</strong>
      </p>
      <p>
        1. Promoters wishing to seek financing on the Website may submit an
        application in order to be eligible as project owners on the Website.
        Approved projects are only available to Contributors (on the Website)
        for a specified period of time, i.e. the subscription period.
      </p>
      <p>
        2. Persons interested in participating in the projects proposed by the
        Website may submit a file in order to be eligible as Contributors. Once
        accepted as such by the Website, Contributors have the possibility to
        make their funds directly available to the Promoters by completing an
        online subscription form.
      </p>
      <p>
        3. Due diligence (including KYC and AML checks) is performed on all
        applications (i.e. of Promoters as well as Contributors), by WCT or its
        mandatories, and any User registration can freely be refused by WCT
        without giving any specific grounds.
      </p>
      <p className={s.center}>
        <strong>F. ACCEPTABLE USE</strong>
      </p>
      <p>
        The User must only use the content or services provided through the
        Website for their stated purpose and not:
      </p>
      <p>
        - publish, post, send, upload, submit, display or disseminate any
        information or material and/or otherwise make available or engage in any
        conduct that is unlawful, discriminatory, harassing, libelous,
        defamatory, abusive, threatening, harmful, offensive, obscene, tortious
        or otherwise objectionable;
      </p>
      <p>
        - display, upload or transmit material that encourages conduct that may
        constitute a criminal offence, result in civil liability or otherwise
        violate or breach any applicable laws, regulations or code of practice;
      </p>
      <p>
        - interfere or violate the legal rights (such as rights of privacy and
        publicity) of others or violate others use or enjoyment of the Website;
      </p>
      <p>- violate any applicable laws or regulations;</p>
      <p>
        - use the Website or links on the Website in any manner that could
        interfere with, disrupt, negatively affect or inhibit other users from
        using the Website or links on the Website or that could damage, disable,
        overburden or impair the functioning of the Website or our servers or
        any networks connected to any of our servers in any manner;
      </p>
      <p>
        - create a false identity for the purpose of misleading others or
        fraudulently or otherwise misrepresent yourself to be another person or
        a representative of another entity including, but not limited to, an
        authorized user of the Website or a WCT representative, or fraudulently
        or otherwise misrepresent that you have an affiliation with a person,
        entity or group;
      </p>
      <p>
        - mislead or deceive us, our representatives and any third parties who
        may rely on the information provided by you, by providing inaccurate or
        false information, which includes omissions of information;
      </p>
      <p>
        - disguise the origin of any material transmitted through the services
        provided by the Website;
      </p>
      <p>
        - violate, infringe or misappropriate any intellectual or industrial
        property right of any person (such as copyright, trademarks, patents, or
        trade secrets, or other proprietary rights of any party) or commit a
        tort;
      </p>
      <p>
        - upload files that contain viruses, Trojan horses, worms, time bombs,
        cancelbots, corrupted files, or any other similar software or programs
        that may damage the operation of another’s computer or property;
      </p>
      <p>
        - send, upload, display or disseminate or otherwise make available
        material containing or associated with spam, junk mail, advertising for
        pyramid schemes, chain letters, virus warnings (without first confirming
        the authenticity of the warning), or any other form of unauthorized
        advertising or promotional material;
      </p>
      <p>
        - access any content, area or functionality of the Website that you are
        prohibited or restricted from accessing or attempt to bypass or
        circumvent measures employed to prevent or limit your access to any
        content, area or functionality of the Website;
      </p>
      <p>
        - obtain unauthorized access to or interfere with the performance of the
        servers which host the Website or provide the services on the Website or
        any servers on any associated networks or otherwise fail to comply with
        any policies or procedures relating to the use of those servers;
      </p>
      <p>
        - attempt to gain unauthorized access to any services or products, other
        accounts, computer systems, or networks connected to any of our servers
        through hacking, password mining, or any other means;
      </p>
      <p>
        - obtain or attempt to obtain any materials or information through any
        means not intentionally made available through the Website or its
        services;
      </p>
      <p>
        - harvest or otherwise collect, whether aggregated or otherwise, data
        about others including e-mail addresses and/or distribute or sell such
        data in any manner;
      </p>
      <p>
        - use any part of the Website other than for its intended purpose; or
      </p>
      <p>
        - use the Website to engage in or promote any activity that violates
        these terms.
      </p>

      <p className={s.center}>
        <strong>G. REMUNERATION</strong>
      </p>
      <p>
        1. The fees due by the Contributor to WTC shall be mentioned for each
        project in the description of the project. [to be confirmed by WTC]
      </p>
      <p>
        2. In addition to the above, the Promoter undertakes to remunerate WCT
        with a marketing &amp; introducer fee (<strong>the "Commission"</strong>
        ) in exchange for its project being referred to on the Website under a
        specific marketing &amp; introducer agreement.
      </p>
      <p>
        3. The amount of the Commission due to WCT by the Promoter is based on
        several factors and can include a percentage of the amount raised.
      </p>
      <p>
        4. In case the Contributor considers that the Commission puts WCT in a
        situation of conflict of interest, the User shall refrain from being a
        User and more particularly a Contributor.
      </p>
      <p className={s.center}>
        <strong>H. NO ADVICE, NO RECOMMENDATION</strong>
      </p>
      <p>
        1. Neither information or opinion expressed on the Website constitutes a
        solicitation or recommendation to enter into any transactions, or to
        conclude any legal act of any kind.
      </p>
      <p>
        2. WCT is not responsible for the Contributor’s subscription decision.
        Any subscription (i.e. investment) is speculative by nature and can
        involve the risk of loss of one's entire investment. Nothing contained
        on the Website shall be construed as an investment recommendation or
        advice. The Contributor should carefully evaluate its own financial
        position before making any investments. <strong>WCT</strong>
        <strong>
          {' '}
          does not perform any verification of the suitability or
          appropriateness of the subscription made by the Contributor with its
          experience, knowledge, financial situation and investment objectives
        </strong>
        .{' '}
        <strong>
          The Contributor hereby warrants that he understood and expressly
          accepts the absence of said tests
        </strong>
        .
      </p>
      <p>
        3. Furthermore, WCT recommends to the User to independently analyse the
        projects that are referred to on the Website and to consult with
        independent qualified sources of investment advice and/or other legal
        and tax professionals, prior to considering any potential investment.
      </p>
      <p>
        4. WCT does not provide either asset management services or financial,
        tax and/or legal advice to the User through the Website. The User shall
        remain liable for its tax obligations (or any other legal obligations)
        associated with the investment it makes. WCT shall not accept any
        liability in this respect and hereby recommends that the User proceed
        with its own verifications on the project and associated risks and
        request advice from experts within its jurisdiction (such as notary,
        real estate expert, financial or tax advisor) at its own costs and
        liability.
      </p>
      <p className={s.center}>
        <strong>I. LIABILITY</strong>
      </p>
      <p>
        1. WCT may not be held liable unless in presence of gross negligence or
        wilful intent, In particular, in no event, subject to mandatory
        applicable laws, the Website, WCT, its directors, officers, employees,
        mandatories and auxiliary persons shall be liable, including in the case
        of negligence, for any damages, losses or liabilities including without
        limitation, direct or indirect, special, incidental, consequential
        damages, losses or liabilities, in connection with the use of the
        Website, account hacking, User identity spoofing or the User’s reliance
        on or use or inability to use the information and services of the
        Website, or in connection with any failure of performance, error,
        omission, interruption, defect, delay in operation or transmission,
        computer virus or line or system failure, even if the User advises WCT
        of the possibility of such damages, losses or expenses.- furthermore,
        WCT disclaims all liability for any tampering with the User’s computer
        system by unauthorized parties.
      </p>
      <p>
        2. WCT does not warrant the accuracy, adequacy, completeness or
        timeliness of the information and services or the error-free use of the
        Website.
      </p>
      <p>
        3. WCT does not represent or warrant that the Website will be available
        or that it will meet the User’s requirements, that access will be
        uninterrupted, that there will be no delays, failures, errors or
        omissions or loss of transmitted information, that no viruses or other
        contaminating or destructive properties will be transmitted or that no
        damage will occur to the User’s computer system. The User shall be
        solely responsible for its adequate protection and back up of data
        and/or equipment and for undertaking reasonable and appropriate
        precautions to scan for computer viruses or other destructive
        properties.
      </p>
      <p>
        4. WCT is not liable for the information provided by the Promoters and
        Issuer on the Website, including the description of the fundraising
        projects. WCT does not verify the factual content of such information.
        Its role is limited to connecting the Promoter with the Contributor.
      </p>
      <p className={s.center}>
        <strong>J. DATA PROTECTION</strong>
      </p>
      <p>
        The User's access to and use of the Website constitutes its acceptance
        of and agreement to abide by WCT’s <strong>Privacy Policy</strong> which
        is hereby incorporated in the present Terms and Conditions by reference.
      </p>
      <p className={s.center}>
        <strong>
          K. POTENTIAL CONFLICT OF INTERESTS WITH OTHER WCT ACTIVITIES
        </strong>
      </p>
      <p>
        1. The User is expressly informed that WCT may provide specific
        technical assistance services to Promoters, for instance in relation to
        the set up and functionalities of smart contracts (and tokens).
      </p>
      <p>
        2. These activities are performed on the basis of specific Service Level
        Agreements executed between WCT and the Promoter and WCT is entitled to
        dedicated fees for said services.
      </p>
      <p>
        3. Other WCT activities notably consist in IT research and technological
        development services. In case the User considers that said services put
        WCT in a situation of potential conflict of interest, the User shall
        refrain from using the Website and more particularly from acting as a
        Contributor on the Website.
      </p>
      <p className={s.center}>
        <strong>L. LINKED SITES</strong>
      </p>
      <p>
        Links on the Website may be directed to third-party websites, which are
        not owned nor controlled by WCT. Access to these third-party websites
        is, at the User’s own risk and sole responsibility, subject to the terms
        and conditions of the relevant third-party providers.
      </p>
      <p className={s.center}>
        <strong>M. INTELLECTUAL PROPERTY</strong>
      </p>
      <p>
        1. Some information available on the Website (including logos, designs,
        brands, softwares, code and others) are protected by copyrights,
        patents, trade secrets, trademarks or other intellectual property rights
        to the sole benefit of WCT. No information contained on this Website
        shall be construed as granting any license or right to use any of this
        information. Nothing on the Website may be copied, imitated or used, in
        whole or in part, without WCT’s prior written consent.
      </p>
      <p>
        2. In no event shall the User acquire title to any software or material
        by downloading it, or otherwise copying it, from the Website.
      </p>
      <p>
        3. Property rights of third parties, including the Promoters, are
        expressly reserved.
      </p>
      <p className={s.center}>
        <strong>N. CHANGES TO THE TERMS AND CONDITIONS</strong>
      </p>
      <p>
        1. WCT reserves the right to amend the present Terms and Conditions in
        its sole discretion, at any time and without any prior notice.
      </p>
      <p>
        2. The most current version, published on the Website, applies between
        the User and WCT.
      </p>

      <p className={s.center}>
        <strong>O. PLACE OF JURISDICTION AND APPLICABLE LAW</strong>
      </p>
      <p>
        Use of the Website shall be subject to Swiss substantive law, which
        shall exclusively govern the interpretation, application, and effect of
        all the conditions of use set out above, without regard to its conflict
        of laws provision. The courts of Geneva, Switzerland shall have
        exclusive jurisdiction over all claims or disputes arising out of or in
        connection with the Site and its use.
      </p>

      <p>Last updated on 09/10/2019</p>
      <p className={s.center}>* * * *</p>
    </>
  );
};

export default TermsText;
