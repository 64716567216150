import Base from './base';

export default class Contributions extends Base {
  create(data) {
    return this.apiClient.post('contributions', data);
  }

  load(id) {
    return this.apiClient.get(`contributions/${id}`);
  }

  loadByOwner(id) {
    return this.apiClient.get(`contributions/byOwner/${id}`);
  }

  approve(id, email) {
    return this.apiClient.patch(`contributions/approve/${id}/${email}`);
  }

  decline(id, email) {
    return this.apiClient.put(`contributions/decline/${id}/${email}`);
  }

  updateContribution(data) {
    return this.apiClient.patch('contributions/update', data);
  }

  sendContributionEmail(id) {
    return this.apiClient.post('contributions/sendContributionEmail', id);
  }
}
