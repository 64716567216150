export const TIMEFRAMES = {
  MONTH_1: '1_MONTH',
  MONTH_6: '6_MONTH',
  YTD: 'YTD',
  YEAR_1: '1_YEAR',
};

export const TIMEFRAMES_SHORTNAME = {
  [TIMEFRAMES.MONTH_1]: '1M',
  [TIMEFRAMES.MONTH_6]: '6M',
  [TIMEFRAMES.YTD]: 'YTD',
  [TIMEFRAMES.YEAR_1]: '1Y',
};

export const PERIODS = {
  [TIMEFRAMES.MONTH_1]: 'daily',
  [TIMEFRAMES.MONTH_6]: 'daily',
  [TIMEFRAMES.YTD]: 'daily',
  [TIMEFRAMES.YEAR_1]: 'daily',
};
