import React from 'react';
import { Icon } from 'antd';
import moment from 'moment';
import cn from 'classnames';

import { NOTIFICATION_STATUS } from '~constants/users';
import ss from './Notifications.module.scss';

import noNotificationsImg from '~image-assets/no-notifications1.png';

const { SUCCESS, ERROR } = NOTIFICATION_STATUS;

const getNotificationType = message => {
  if (SUCCESS.includes(message)) return 'check';
  if (ERROR.includes(message)) return 'close';
  return 'warning';
};

class Notifications extends React.Component {
  render() {
    const { notifications, onRemove } = this.props;

    return (
      <div className={ss.root}>
        <div className={ss.divider} />
        <div className={ss.head}>
          <h6 className={ss.title}>Notifications</h6>
          {!!notifications.length && (
            <div className={ss.counter}>{notifications.length} New</div>
          )}
        </div>
        <div className={ss.divider} />
        {notifications.length ? (
          <div className={ss.body}>
            {notifications.map(
              ({ id, createdAt, message, description, type }) => (
                <div key={id} className={ss.item}>
                  <div className={ss.row}>
                    <div>
                      <Icon
                        className={cn(ss.icon, {
                          [ss.success]: SUCCESS.includes(message),
                          [ss.error]: ERROR.includes(message),
                        })}
                        type={getNotificationType(message)}
                      />
                    </div>
                    <div className={ss.left}>
                      <div
                        className={cn(ss.subTitle, {
                          [ss.success]: SUCCESS.includes(message),
                          [ss.error]: ERROR.includes(message),
                        })}
                      >
                        {description || message}
                      </div>
                      <div className={ss.text}>
                        {moment(createdAt).format('MMM D, YYYY - HH:mm')}
                      </div>
                    </div>
                  </div>
                  <div className={ss.row}>
                    <div className={ss.vDivider} />
                    <div className={ss.right}>
                      {type === 'KYC_FLOW' && (
                        <div className={ss.primaryButton}>Complete KYC</div>
                      )}
                      {type === 'KYC_FLOW' && <div className={ss.divider} />}
                      <div
                        onClick={() => onRemove(id)}
                        className={ss.secondaryButton}
                      >
                        <Icon type="close" />
                      </div>
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
        ) : (
          <div className={cn(ss.row, ss.rowEmpty)}>
            <img src={noNotificationsImg} alt="" />
            <p>No notifications for the moment</p>
          </div>
        )}
      </div>
    );
  }
}

export default Notifications;
