import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { ROUTES } from '~constants/routes';
import { isMobile as mobileDetector } from '~utils';

const isMobile = mobileDetector();

const GuestMenu = ({ styles, onMenuClick }) => (
  <>
    {/* <label className={styles.item} onClick={onMenuClick}>
      <Link className={styles.link} to={ROUTES.PROJECTS}>
        Projects
      </Link>
    </label> */}
    <label className={styles.item} onClick={onMenuClick}>
      <Link className={styles.link} to={ROUTES.ABOUT_US}>
        About us
      </Link>
    </label>
    {/* <label className={styles.item} onClick={onMenuClick}>
      <Link className={styles.link} to={ROUTES.FAQ}>
        FAQ
      </Link>
    </label> */}
    {!isMobile && <li className={styles.divider} />}
    {/* <label className={cn(styles.item, styles.login)} onClick={onMenuClick}>
      <Link className={cn(styles.link, styles.button)} to={ROUTES.LOGIN}>
        Login
      </Link>
    </label>
    <label className={styles.item} onClick={onMenuClick}>
      <Link className={cn(styles.link, styles.button)} to={ROUTES.SIGNUP}>
        Sign Up
      </Link>
    </label> */}
    <label className={cn(styles.item, styles.login)} onClick={onMenuClick}>
      <Link className={cn(styles.link, styles.button)} to={ROUTES.CONTACT_US}>
        Contact Us
      </Link>
    </label>
  </>
);

export default GuestMenu;
