import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { uploadAssetFile, removeAssetFile } from '~redux/assets/actions';
import { asset } from '~redux/assets/selectors';

import FileUpload from './FileUpload';

const selector = createStructuredSelector({ asset });
const actions = { uploadAssetFile, removeAssetFile };

const reduxConect = connect(
  selector,
  actions,
);

export { FileUpload };

export default reduxConect(FileUpload);
