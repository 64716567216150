import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AssetsSettings from './AssetsSettings';

import { loadAssets } from '~redux/assets/actions';

import { list, loading, asset } from '~redux/assets/selectors';

const selector = createStructuredSelector({
  list,
  loading,
  asset,
});

const actions = {
  loadAssets,
};

const reduxConect = connect(
  selector,
  actions,
);

export { AssetsSettings };

export default reduxConect(AssetsSettings);
