import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { compose } from '~utils';
import { passwordRecovery } from '~redux/profile/actions';
import { recoveryPasswordValues, loading } from '~redux/profile/selectors';

import PasswordRecoveryForm from './PasswordRecoveryForm';

const selectors = createStructuredSelector({
  recoveryPasswordValues,
  loading,
});
const actions = { passwordRecovery };

const formConect = reduxForm({
  form: 'passwordRecovery',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
});

const reduxConect = connect(
  selectors,
  actions,
);

export { PasswordRecoveryForm };

export default compose(
  reduxConect,
  formConect,
)(PasswordRecoveryForm);
