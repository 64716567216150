import React, { Component } from 'react';
import { Modal } from 'antd';

import Stepper from '../../Stepper';
import Form from '../Form';
import { STEPS, STEP_INFO } from '~constants/assets';

import ss from './EditModal.module.scss';
import styles from '../../../pages/AddAsset';

export default class EditModal extends Component {
  state = { step: 0 };

  handleNextClick = () => {
    const { step } = this.state;

    if (step < STEPS.ASSETS_INFORMATION) this.setState({ step: step + 1 });
  };

  handlePrevClick = () => {
    const { step } = this.state;

    if (step > STEPS.TYPE) this.setState({ step: step - 1 });
  };

  handleAddClick = async () => {
    const { updateAssetFields, formValues } = this.props;

    await updateAssetFields(formValues);

    this.handleNextClick();
  };

  handleCancel = () => {
    this.props.removeSelectedAsset();
  };

  render() {
    const { visible, history } = this.props;
    const { step } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={this.handleCancel}
        footer={null}
        width="90%"
        className={ss.modal}
      >
        <div className={styles.header}>
          <Stepper
            steps={STEP_INFO}
            step={step}
            extraClass="editAssetStepper"
          />
        </div>
        <div>
          <Form
            step={step}
            onNext={this.handleNextClick}
            onPrev={this.handlePrevClick}
            onSubmit={this.handleAddClick}
            history={history}
            fieldDisabled={true}
          />
        </div>
      </Modal>
    );
  }
}
