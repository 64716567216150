export const MB_IN_BYTES = 1048576; // bytes in 1 MB
export const MAX_IMG_SIZE = MB_IN_BYTES * 2; // bytes in 2 MB
export const MAX_DOCUMENT_SIZE = MB_IN_BYTES * 20; // bytes in 20 MB
export const MAX_VIDEO_SIZE = MB_IN_BYTES * 96; // bytes in 4 * 24 = 96 MB

export const USER_ROLES = {
  USER: 'USER',
  INVESTOR: 'INVESTOR',
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  KYC_APPROVED: 'KYC_APPROVED',
  KYC_DECLINED: 'KYC_DECLINED',
  KYC_PENDING: 'KYC_PENDING',
};

export const NOTIFICATION_STATUS = {
  SUCCESS: ['SUCCESS', 'CREATED', 'UPDATED'],
  ERROR: ['ERROR', 'DECLINED'],
};

export const INVESTOR_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  CORPORATE: 'CORPORATE',
};

export const INVESTOR_TYPE_LABELS = {
  INDIVIDUAL: 'Private investor',
  CORPORATE: 'Corporate investor',
};

export const SWITCHED_INVESTOR_TYPE = {
  INDIVIDUAL: 'CORPORATE',
  CORPORATE: 'INDIVIDUAL',
};

export const SIGNUP_STEPS = {
  PERSONAL: 0,
  INVESTMENT: 1,
};

export const STEP_INFO = {
  [SIGNUP_STEPS.PERSONAL]: {
    name: 'Personal info',
  },
  [SIGNUP_STEPS.INVESTMENT]: {
    name: 'Investment info',
  },
};

export const SIGNUP_INVESTOR_TYPE = [
  { value: INVESTOR_TYPE.INDIVIDUAL, label: 'Private' },
  {
    value: INVESTOR_TYPE.CORPORATE,
    label: 'Institutional or corporate',
  },
];
