import React, { Component } from 'react';
import { Form, Button, Row, Checkbox, Modal } from 'antd';
import { RadioField, TextField } from 'redux-form-antd';
import { Field } from 'redux-form';
import cn from 'classnames';

import { required } from '~redux/redux-form-validators';
import { SIGNUP_INVESTOR_TYPE } from '../../../../constants/users';
import makeField from '../../../../components/WrappedFormElement';

import TermsText from '../../../LegalsMentions/components/';
import PrivacyText from '../../../PrivacyPolicy/components';

import formStyle from '../../../../components/Assets/Form/Form.module.scss';
import styles from '../RightForm/RightForm.module.scss';

const CheckboxField = makeField(Checkbox);

export default class InvestmentInfo extends Component {
  renderModal(source) {
    Modal.info({
      title: source === 'terms' ? 'Terms and conditions' : 'Privacy Policy',
      icon: null,
      maskClosable: true,
      className: styles.termsModal,
      okButtonProps: {
        type: 'danger',
      },
      content: source === 'terms' ? <TermsText /> : <PrivacyText />,
      onOk() {},
    });

    setTimeout(() => {
      document
        .getElementsByClassName('ant-modal-confirm-body-wrapper')[0]
        .scrollTo(0, 0);
    }, 100);
  }

  render() {
    const { isTermsChecked, onCancel, handleSubmit, valid } = this.props;

    return (
      <Form onSubmit={handleSubmit} className={styles.optionalLabel}>
        <Form.Item className={styles.input}>
          <Field
            name="referral"
            label={
              <>
                <span>Referred by</span>
                <span className={styles.optional}>Optional</span>
              </>
            }
            type="text"
            component={TextField}
            placeholder="Fill in the name or referent entity"
          />
        </Form.Item>
        <Form.Item className={styles.input}>
          <Field
            name="type"
            label={
              <label>
                <span>What type of investor are you? </span>
                <span className={styles.required}>*</span>
              </label>
            }
            component={RadioField}
            validate={[required]}
            options={SIGNUP_INVESTOR_TYPE}
          />
        </Form.Item>
        <div className={styles.divider} />
        <Form.Item className={styles.input}>
          <Field name="newsletterAccepted" component={CheckboxField}>
            I agree to receive information and be contacted by Efiko
          </Field>
        </Form.Item>
        <Form.Item className={cn(styles.input, styles.input__flex)}>
          <Field
            name="terms"
            component={CheckboxField}
            validate={[required]}
            checked={isTermsChecked}
          >
            I confirm having read, understood and hereby accept these{' '}
            <span
              onClick={() => this.renderModal('terms')}
              className={styles.input__terms}
            >
              Terms and Conditions
            </span>{' '}
            and{' '}
            <span
              onClick={() => this.renderModal('privacy')}
              className={styles.input__terms}
            >
              Privacy Policy
            </span>
          </Field>
        </Form.Item>
        <Row className={styles.actions}>
          <Button onClick={onCancel} className={formStyle.cancel}>
            go back
          </Button>
          <Button
            disabled={!valid}
            className={formStyle.submit}
            htmlType="submit"
          >
            CREATE AN ACCOUNT
          </Button>
        </Row>
      </Form>
    );
  }
}
