import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Form, Button, Spin } from 'antd';
import { TextField } from 'redux-form-antd';
import { Field } from 'redux-form';
import { required, email } from '~redux/redux-form-validators';
import { ROUTES } from '~constants/routes.js';

import styles from './PasswordRecoveryForm.module.scss';
import formStyle from '../../components/Assets/Form/Form.module.scss';

const { Content } = Layout;

export default class PasswordRecoveryForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  handleSubmit = () => {
    const {
      passwordRecovery,
      history,
      recoveryPasswordValues: { email },
    } = this.props;

    passwordRecovery({ email });
    history.push(ROUTES.RESET);
  };

  render() {
    const { valid, loading } = this.props;

    return (
      <Spin spinning={loading} size="large">
        <Layout>
          <Content className={styles.content}>
            <Form className={styles.form}>
              <Form.Item className={styles.input}>
                <Field
                  name="email"
                  type="text"
                  placeholder="Email"
                  component={TextField}
                  validate={[email, required]}
                />
              </Form.Item>
              <div className={styles.bottom}>
                <Button
                  disabled={!valid}
                  onClick={this.handleSubmit}
                  className={formStyle.next}
                >
                  Reset my password
                </Button>
                <a href={ROUTES.LOGIN}>Back to login</a>
              </div>
            </Form>
          </Content>
        </Layout>
      </Spin>
    );
  }
}
