import React, { Component } from 'react';
import { Button } from 'antd';
import { ROUTES } from '~constants/routes';
import { Link } from 'react-router-dom';

import ss from './CookieBar.module.scss';
import formStyle from '../../components/Assets/Form/Form.module.scss';

const COOKIE_TEXT = {
  large:
    'We respect your concerns about privacy and value the relationship that we have with you. Like many companies, we use technology on our website to collect information that helps us enhance your experience and our products and services. The cookies that we use, allow our website to work and help us to understand what information is most useful to visitors. We invite you to check our cookie practices and please don’t hesitate to contact us for any question you may have. ',
  small:
    'By using this site, you accept our use of cookies to provide and improve our services.',
};

export default class CookieBar extends Component {
  state = {
    areCookiesAccepted: localStorage.getItem('cookiesAccepted'),
  };

  acceptCookies = () => {
    this.setState({
      areCookiesAccepted: true,
    });
    localStorage.setItem('cookiesAccepted', true);
  };

  render() {
    const { areCookiesAccepted } = this.state;
    return (
      <div className={ss[window.location.pathname.split('/').pop()]}>
        {!areCookiesAccepted && (
          <div className={ss.container}>
            <div className={ss.text}>
              <p>WE USE COOKIES TO IMPROVE YOUR BROWSING EXPERIENCE</p>
              <p className={ss.cookieText}>
                {window.innerWidth > 480
                  ? COOKIE_TEXT.large
                  : COOKIE_TEXT.small}
              </p>
              <p>
                <Link to={ROUTES.PRIVACY_POLICY}>Learn more.</Link>
              </p>
            </div>
            <Button
              className={formStyle.submit}
              htmlType="button"
              onClick={this.acceptCookies}
            >
              Accept
            </Button>
          </div>
        )}
      </div>
    );
  }
}
