import React, { Component } from 'react';
import { Form, Button, Row, Select } from 'antd';
import { Field } from 'redux-form';

import { required, iban } from '~redux/redux-form-validators';
import makeField from '../../WrappedFormElement';

import styles from './IBAN.module.scss';
import formStyle from '../../Assets/Form/Form.module.scss';

const SelectField = makeField(Select);

class IBAN extends Component {
  handleChange = val => {
    this.props.change('iban', val);
  };

  handleSubmit = e => {
    const { onSubmit, usedIbans, ibanFromForm, updateUser } = this.props;

    if (!usedIbans.includes(ibanFromForm)) {
      const newIbans = [...usedIbans, ibanFromForm];
      updateUser({
        iban: newIbans,
        notificationMessage: 'IBAN has been added to your profile',
      });
    }

    onSubmit(e);
  };

  render() {
    const { onCancel, valid, ibanFromForm, usedIbans } = this.props;

    return (
      <>
        <h4 className={styles.title}>
          Your IBAN will be used to receive interest payment.
        </h4>
        <Form>
          <div className={styles.form}>
            <Form.Item className={styles.input}>
              <Field
                name="iban"
                type="text"
                label="IBAN"
                placeholder={ibanFromForm ? '' : 'CH5604835012345678009'}
                component={SelectField}
                onSearch={this.handleChange}
                value={ibanFromForm}
                notFoundContent={null}
                showSearch
                validate={[required, iban]}
              >
                {usedIbans.map(iban => (
                  <Select.Option key={iban} value={iban}>
                    {iban}
                  </Select.Option>
                ))}
              </Field>
            </Form.Item>

            <p className={styles.text}>
              Please be aware that you may change this IBAN only if the ultimate
              beneficial owner remains the same person. If a change of IBAN is
              required before the end of the maturity date, or for any questions
              in relation to this matter, please contact us.
            </p>
          </div>
          <Row className={formStyle.actions}>
            <Button onClick={onCancel} className={formStyle.back}>
              Go back
            </Button>
            <Button
              disabled={!valid}
              className={formStyle.submit}
              htmlType="submit"
              onClick={this.handleSubmit}
            >
              submit
            </Button>
          </Row>
        </Form>
      </>
    );
  }
}

export default IBAN;
