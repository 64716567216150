import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Button } from 'antd';

// import PartnerCard from './components/PartnerCard';
import TeamCard from './components/TeamCard';

import {
  // PARTNERS,
  TEAM,
} from './constants';

import formStyle from '../../components/Assets/Form/Form.module.scss';
import s from './AboutUs.module.scss';

import { ROUTES } from '~constants/routes';

const AboutUs = () => {
  return (
    <div className={s.container}>
      <div className={s.title}>About us</div>
      <div className={s.content}>
        <div className={s.subtitle}>
          From our work as impact-investors & Tech CO founders/advisors, we
          started to notice that a platform focused on accelerating the
          commercialization of impact-driven innovation while addressing
          governance and investors management issues was both missing and
          needed. We want to empower investors and Tech CO alike as specialist
          of a novel way to raise money.
        </div>

        <div className={s.heading}>Team</div>

        <h2>Management team</h2>
        <div className={classnames(s.row, s.rowContainer, s.teamContainer)}>
          {TEAM.map(props => (
            <TeamCard key={props.name} {...props} />
          ))}
        </div>

        {/* <div className={s.heading}>Our partners</div>
        {PARTNERS.map(props => (
          <PartnerCard key={props.name} {...props} />
        ))} */}

        <div className={s.contact}>
          <span>Want to start?</span>
          <Link to={ROUTES.CONTACT_US}>
            <Button className={classnames(formStyle.submit, s.btn)}>
              Contact us
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
