import React, { Component } from 'react';
import { Form, Button, Row } from 'antd';
import { TextField } from 'redux-form-antd';
import { Field } from 'redux-form';
import styles from './BankInfo.module.scss';
import formStyle from '../Form.module.scss';

import {
  required,
  minLength,
  maxLength,
  iban,
  bic,
} from '~redux/redux-form-validators';

class BankInfo extends Component {
  state = {
    isCountrySelected: false,
  };

  showPlaceholder = value => {
    if (!value[0]) {
      this.setState({
        isCountrySelected: false,
      });
    }
  };

  render() {
    const { onCancel, handleSubmit, valid } = this.props;

    return (
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Form.Item className={styles.mInput}>
          <Field
            name="bank"
            type="text"
            label="Bank name"
            placeholder="JP Morgan"
            component={TextField}
            validate={[required, minLength(3), maxLength(50)]}
          />
        </Form.Item>
        <Form.Item className={styles.mInput}>
          <Field
            name="iban"
            type="text"
            label="IBAN"
            placeholder="CH9300762011623852957"
            component={TextField}
            validate={[required, iban]}
          />
        </Form.Item>
        <Form.Item className={styles.mInput}>
          <Field
            name="bic_code"
            type="text"
            label="BIC_CODE"
            placeholder="BSABESBBXXX"
            component={TextField}
            validate={[required, bic]}
          />
        </Form.Item>
        <Form.Item className={styles.mInput}>
          <Field
            name="beneficiary"
            type="text"
            label="Beneficiary"
            placeholder="GMG"
            component={TextField}
            validate={[required, minLength(3)]}
          />
        </Form.Item>
        <Row className={formStyle.actions}>
          <Button onClick={onCancel} className={formStyle.back}>
            go back
          </Button>
          <Button
            className={formStyle.next}
            htmlType="submit"
            disabled={!valid}
          >
            continue
          </Button>
        </Row>
      </Form>
    );
  }
}

export default BankInfo;
