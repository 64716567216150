import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import InvestorCreationForm from './Form';
import { compose } from '~utils';
import { allAssets } from '~redux/assets/actions';
import {
  list as projects,
  loading as assetsLoading,
} from '~redux/assets/selectors';
import { loading as userLoading } from '~redux/users/selectors';

const loading = createSelector(
  userLoading,
  assetsLoading,
  (iLoading, aLoading) => iLoading || aLoading,
);

const selector = createStructuredSelector({
  projects,
  loading,
});

const actions = {
  allAssets,
};

const reduxConnect = connect(
  selector,
  actions,
);

const formConect = reduxForm({
  form: 'investorCreationForm',
  forceUnregisterOnUnmount: true,
});

export default compose(
  reduxConnect,
  formConect,
)(InvestorCreationForm);
