import React from 'react';
import { Layout } from 'antd';
import Form from '../../components/Investor/Form';
import Modal from '../../components/Modal';
import investor from '~image-assets/investor/investor.png';

import styles from './AddInvestor.module.scss';

const { Content } = Layout;

class AddInvestor extends React.Component {
  state = { isOpenModal: false };

  handleCloseModal = () => {
    this.setState({ isOpenModal: false });
  };

  handleAddClick = async investor => {
    const { addInvestor } = this.props;

    await addInvestor(investor);

    this.setState({ isOpenModal: true });
  };

  render() {
    const { isOpenModal } = this.state;

    return (
      <Layout>
        <Content className={styles.content}>
          <Form onSubmit={this.handleAddClick} />
        </Content>
        <Modal
          img={investor}
          header="You've just finished investor creating!"
          visible={isOpenModal}
          onCancel={this.handleCloseModal}
        />
      </Layout>
    );
  }
}

export default AddInvestor;
