import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Form, Button, Select } from 'antd';
import cn from 'classnames';
import { TextField, TextAreaField, CheckboxField } from 'redux-form-antd';
import {
  maxLength,
  minLength,
  required,
  email,
} from '~redux/redux-form-validators';
import { COUNTRIES } from '~constants/assets';
import makeField from '../../components/WrappedFormElement';
import { ROUTES } from '~constants/routes';

import ss from './ContactUs.module.scss';
import formStyle from '../../components/Assets/Form/Form.module.scss';

const SelectElement = makeField(Select);

const checkboxOptions = ['investing', 'issuing'];

export default class ContactUs extends Component {
  state = {
    isCountrySelected: false,
    sendNewsLetter: false,
  };

  componentDidMount() {
    this.props.reset(); // to reset form fields if the page was not refreshed
  }

  selectCountry = () => {
    this.setState({
      isCountrySelected: true,
    });
  };

  showPlaceholder = value => {
    if (!value[0]) {
      this.setState({
        isCountrySelected: false,
      });
    }
  };

  handleSubmit = async e => {
    const { sendContactUs, formValues, history } = this.props;

    // e.preventDefault();

    const { data } = await sendContactUs({
      ...formValues,
      subject: 'Contact Us Page',
    });

    if (data && data.success) {
      history.push(ROUTES.HOME);
    }
  };

  handleNewsLetterClick = ({ target: { name, checked } }) => {
    if (name !== 'newsletter') return;

    this.setState({
      sendNewsLetter: checked,
    });
  };

  render() {
    const { valid } = this.props;
    const { isCountrySelected } = this.state;

    return (
      <div className={ss.container}>
        <div className={ss.title}>Contact Us</div>
        <div className={ss.content}>
          <div className={ss.subtitle}>All the fields below are required</div>
        </div>
        <Form onSubmit={this.handleSubmit} className={ss.form}>
          <Form.Item className={ss.sInput}>
            <Field
              name="name"
              type="text"
              label={<i>Name</i>}
              placeholder="Name"
              component={TextField}
              validate={[required, minLength(3), maxLength(30)]}
            />
          </Form.Item>
          <Form.Item className={ss.sInput}>
            <Field
              name="email"
              type="text"
              label={<i>Email</i>}
              placeholder="Email"
              component={TextField}
              validate={[required, email]}
            />
          </Form.Item>
          <Form.Item className={ss.sInput}>
            <Field
              name="country"
              type="text"
              label={<i>Country of residence</i>}
              placeholder={isCountrySelected ? '' : 'Select a country'}
              component={SelectElement}
              validate={[required]}
              onFocus={this.selectCountry}
              onBlur={this.showPlaceholder}
              showSearch
            >
              {COUNTRIES.map(({ value, label }) => (
                <Select.Option key={value} value={label}>
                  {label}
                </Select.Option>
              ))}
            </Field>
          </Form.Item>
          <Form.Item
            className={ss.checkbox}
            label={<i>Are you interested in:</i>}
          >
            {checkboxOptions.map(option => (
              <Field
                key={option}
                name={option}
                component={CheckboxField}
                label={<CustomLabel option={option} />}
                id={option}
              />
            ))}
          </Form.Item>
          <Form.Item className={ss.sInput}>
            <Field
              name="message"
              type="text"
              label={<i>Your message</i>}
              placeholder="Write here"
              component={TextAreaField}
              rows={4}
              validate={[required, minLength(3), maxLength(30)]}
            />
          </Form.Item>

          <p className={ss.text}>
            Eltenemo is committed to protecting and respecting your privacy. We’ll
            only use your personal information to administer your account and to
            answer to your request.
          </p>

          <Button
            disabled={!valid}
            className={cn(ss.submit, formStyle.next)}
            htmlType="submit"
          >
            SUBMIT
          </Button>
        </Form>
      </div>
    );
  }
}

const CustomLabel = ({ option }) => {
  return (
    <label className={ss.customLabel} htmlFor={option}>
      {option}
    </label>
  );
};
