import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { ROUTES } from '~constants/routes';

import Form from './Form';

import styles from './ResetPasswordPage.module.scss';

export default class ResetPasswordPage extends Component {
  static propTypes = {
    isPasswordChanged: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    code: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onFormReset: PropTypes.func.isRequired,
    onStateReset: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    const { onStateReset, onFormReset } = this.props;

    onStateReset();
    onFormReset('passwordRecovery');
    onFormReset('resetPassword');
  }

  handleSubmit = data => {
    const { code, onSubmit } = this.props;

    onSubmit({ ...data, code });
  };

  render() {
    const { isPasswordChanged, code, loading, history } = this.props;

    return (
      <Spin spinning={loading} size="large">
        <div className={styles.container}>
          {code && !isPasswordChanged && (
            <Form history={history} onSubmit={this.handleSubmit} />
          )}
          {isPasswordChanged && (
            <div className={styles.success}>
              <h2>Your password has been successfully changed!</h2>
              <Link className={styles.link} to={ROUTES.LOGIN}>
                Return to login page
              </Link>
            </div>
          )}
        </div>
      </Spin>
    );
  }
}
