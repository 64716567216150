import React from 'react';
import { Icon } from 'antd';
import styles from './Item.module.scss';

class Item extends React.Component {
  handleClick = () => {
    const {
      item: { id },
      getAsset,
    } = this.props;

    getAsset(id);
  };

  render() {
    const {
      item: { files, name, city, country },
      isEditActive,
    } = this.props;

    const logo = files?.coverImage?.[0];

    return (
      <div className={styles.container}>
        <div className={styles.logo}>
          {logo && (
            <img className={styles.image} src={logo.url} alt="Asset Logo" />
          )}
        </div>
        <div className={styles.container}>
          <div className={styles.info}>
            <span className={styles.name}>{name}</span>
            <span>
              <Icon type="star" theme="filled" />
              <span className={styles.location}>{`${city}, ${country}`}</span>
            </span>
          </div>
          <div className={styles.navigation}>
            {isEditActive && (
              <span className={styles.link} onClick={this.handleClick}>
                Edit this asset >
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Item;
