import React, { Component } from 'react';
import cn from 'classnames';

import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

import { ROUTES } from '~constants/routes';

import classes from './Layout.module.scss';
import CookieBar from '../../../components/CookieBar/CookieBar';

export default class Layout extends Component {
  componentDidMount() {
    this.props.init();
  }

  render() {
    const { pathname, children } = this.props;
    const simpleLayout = [ROUTES.LOGIN, ROUTES.SIGNUP].includes(pathname);

    return (
      <div className={classes.root}>
        <Header simpleLayout={simpleLayout} pathname={pathname} />
        <div
          className={cn(classes.body, {
            [classes.resetPassword]: pathname === ROUTES.RESET,
          })}
        >
          {children}
        </div>
        <CookieBar />
        <Footer simpleLayout={simpleLayout} />
      </div>
    );
  }
}
