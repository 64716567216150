import React, { Component } from 'react';

import s from './AssetReport.module.scss';

import getDocumentName from '~common/utils/getDocumentName';
import { programmeMemorandumUrl } from '~common/constants/assets';
import icon from '~image-assets/icons/imageIcon.png';
import { createDownloadLink } from '~utils';

export default class AssetReport extends Component {
  state = {
    isLoading: false,
  };

  handleDownload = (url, name) => async e => {
    const { isLoading } = this.state;
    if (isLoading) {
      return;
    }
    this.setState({
      isLoading: true,
    });

    await createDownloadLink(url, name);
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const {
      asset: {
        files: { assetDocuments, assetMemorandum, assetSubscription },
      },
    } = this.props;

    const programMemorandum = {
      name: 'Programme Memorandum.pdf',
      url: programmeMemorandumUrl,
    };

    return [
      programMemorandum,
      ...assetMemorandum,
      ...assetSubscription,
      ...assetDocuments,
    ].map(({ name, url }) => {
      if (!url) return null;
      const filename = name || getDocumentName(url);
      const assetMemorandumUrl = assetMemorandum[0]?.url;
      return (
        <div key={url} className={s.row}>
          <div className={s.left}>
            <img src={icon} alt="icon" className={s.icon} />
            <p>{filename}</p>
          </div>
          <div className={s.right}>
            <p className={s.see}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                Preview
              </a>
            </p>
            {![programMemorandum.url, assetMemorandumUrl].includes(url) && (
              <p className={s.dowload}>
                <span onClick={this.handleDownload(url, name)}>Download</span>
              </p>
            )}
          </div>
        </div>
      );
    });
  }
}
