import React from 'react';
import { Link } from 'react-router-dom';

import s from './Sections.module.scss';
import { ROUTES } from '~constants/routes';
import appstoreQrImg from '~image-assets/appstoreQR.png';
import gplayQrImg from '~image-assets/gplayQR.png';

export const faqObj = {
  'About Efiko': [
    {
      header: 'What is new with Efiko (Switzerland)?',
      content: (
        <>
          <p className={s.par}>
            Efiko (Switzerland) enables you to have access to a digital
            experience allowing you to tokenize financial notes in which you can
            invest. It brings liquidity, in the form of tokens, to an illiquid
            asset such as real estate.
          </p>
          <p className={s.par}>
            The platform gives access to easy on-boarding, enabling the wider
            public to have access to investments in real estate, an activity
            that was once reserved to wealth managers only. There is thus a
            wider opening of the bond market thanks to this new technology.
          </p>
          <p className={s.par}>
            You can withdraw your money at any time and modifications of your
            shares are easier than in the traditional method. The process is
            more transparent, as every transaction is registered on the
            blockchain.
          </p>
          <p className={s.par}>
            You have more control over your own investments and the audit
            process is made easier. Lastly, the registration process on the
            platform and completely digital KYC verification system optimizes
            efficiency.
          </p>
        </>
      ),
    },
    {
      header: 'How does Efiko (Switzerland) choose properties?',
      content: (
        <>
          <p className={s.par}>
            We only select the real estate promoters with a proven track record.
            First, the selected properties proposed by a promoter are passed
            through a financial, technical and legal audit. Then, an independent
            evaluation is done by a real estate expert. Finally, the bank giving
            the mortgages does a third evaluation. All of these steps ensure you
            a high level of safety with your investment.
          </p>
        </>
      ),
    },
    {
      header: 'Will the real estate tokens be listed at exchanges?',
      content: (
        <>
          <p className={s.par}>
            The real estate tokens represent an asset-link security. Securities
            are traded at regulated and licensed exchanges, and many of them are
            working towards being regulated. For the time being, our real estate
            tokens are not listed.
          </p>
        </>
      ),
    },
    {
      header: 'What is the value of one token?',
      content: (
        <>
          <p className={s.par}>
            One token is equal to one coin in the currency of the offer. For
            example, one token equals one euro if the property is sold in euros.
          </p>
        </>
      ),
    },
    {
      header:
        'As Efiko (Switzerland) is a young company, is there a risk for my investments?',
      content: (
        <>
          <p className={s.par}>
            If Efiko (Switzerland) disappeared, this would have no incidence on
            the debt. You still own the debt contract, and thus your shares in
            the securitization company (société de titrisation). The property is
            registered on the name of the securitization company (société de
            titrisation), Efiko (Switzerland) only represents the marketplace.
          </p>
        </>
      ),
    },
    {
      header: 'Who manages the properties?',
      content: (
        <>
          <p className={s.par}>
            The daily management of the properties is done by the corporations
            affiliated to the real estate promoters.
          </p>
        </>
      ),
    },
    {
      header: 'What happens to my tokens when my investment comes to maturity?',
      content: (
        <>
          <p className={s.par}>
            Once the interest or principal amount has been fully paid back the
            tokens will be burnt and will only persist a log of historical
            information about the Tokens' functional history as well as flows in
            and out from each Ethereum Blockchain’s public address.
          </p>
        </>
      ),
    },
    {
      header: 'What Efiko (Switzerland) is not?',
      content: (
        <>
          <p className={s.par}>
            Efiko (Switzerland) is not a financial advisor, an asset manager, a
            securities dealer, an issuing house neither an exchange nor a
            trading platform. No simultaneous exchange of securities is possible
            on our Website.
          </p>
        </>
      ),
    },
  ],

  'About tokenization': [
    {
      header: 'What is a security token?',
      content: (
        <>
          <p className={s.par}>
            A token on our platform represents an investment in a physical
            asset. It is a digital unit of value.
          </p>
          <p className={s.par}>
            In terms of their economic function, they are synonymous with bonds
            or shares. When you buy a share of the property with your tokens,
            you benefit from earnings representing the share of your investment.
          </p>
          <p className={s.par}>
            Security tokens are subject to international and federal
            regulations, bringing you legal security. Security tokens follow
            Stock Exchange, Anti Money Laundering, Banking, Financial market
            Infrastructure, and Collective Investment Scheme Regulation. With
            security tokens, you therefore have the speed and ease of blockchain
            while not sacrificing strong legal protection.
          </p>
        </>
      ),
    },
    {
      header: 'Which blockchain is used?',
      content: (
        <>
          <p className={s.par}>
            Efiko (Switzerland) token smart contract is based on the Ethereum
            blockchain by using an improved version of the ERC-777 token,
            recently published by the OpenZeppelin framework.
          </p>
        </>
      ),
    },
    {
      header: 'Can I transfer my tokens?',
      content: (
        <>
          <p className={s.par}>
            Yes, once the recipient is whitelisted and got in contact with Wecan
            Tokenize (Switzerland), you may transfer the token to their public
            address on the Ethereum blockchain. To do so you will need your
            private key, their public address and a stock of ETH on your wallet
            to cover transaction costs (called “gas” in this case).
          </p>
          <p className={s.par}>
            If you prefer that we do this procedure for you, it is also
            possible. Please contact us in this case.
          </p>
        </>
      ),
    },
    {
      header: 'What happens if I lose the tokens?',
      content: (
        <>
          <p className={s.par}>
            If tokens are lost or stolen, you should contact us and follow a KYC
            procedure so that we make sure that you are the rightful owner. Once
            your identity will be confirmed, we will proceed with the
            destruction of the tokens you lost and distribute you the same
            number of tokens in your new e-wallet address.
          </p>
        </>
      ),
    },
    {
      header: 'What does “stored in a wallet” mean?',
      content: (
        <>
          <p className={s.par}>
            A wallet is a device or service that stores a public address to
            which the tokens will be allocated, and a private key required to
            transfer the tokens. You will need a wallet that is compatible with
            the Ethereum blockchain.
          </p>
        </>
      ),
    },
    {
      header: 'Can Efiko manage my tokens?',
      content: (
        <>
          <p className={s.par}>
            No, Efiko is not a registered custodian, therefore, you should
            manage your own tokens. Please check the section About e-wallet to
            learn how to create an e-wallet.
          </p>
        </>
      ),
    },
  ],

  'About e-wallet': [
    {
      header: 'What is an e-wallet?',
      content: (
        <>
          <p className={s.par}>
            A wallet is a device or service that stores a public address to
            which the tokens will be allocated, and a private key required to
            transfer the tokens. You will need a wallet that is compatible with
            the Ethereum blockchain. Please check How to create a wallet
            question to learn how to generate a wallet address.
          </p>
        </>
      ),
    },
    {
      header: 'How to create a wallet',
      content: (
        <>
          <p className={s.par}>
            If this is your first time creating a wallet, we can guide through
            the necessary steps - you will see its very simple!
          </p>
          <p className={s.par}>
            There are different possibilities to create a wallet. The method we
            recommend is MEWconnect Mobile application. It is a free wallet
            generating tool.
          </p>
          <p className={s.par}>
            We will first start by downloading the application, you can scan the
            following QR codes, using your phone camera:
          </p>
          <div className={s.row}>
            <p className={s.par}>
              App store: <img src={appstoreQrImg} alt="apple store QR" />
            </p>
            <p className={s.par}>
              Play store: <img src={gplayQrImg} alt="apple store QR" />
            </p>
          </div>
          <p className={s.par}>
            If the QR scan did not work, please use the following links:{' '}
            <a
              href="https://apps.apple.com/us/app/mewconnect/id1391097156"
              target="_blank"
              rel="noopener noreferrer"
            >
              IOS App store{' '}
            </a>
            or{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.myetherwallet.mewconnect"
              target="_blank"
              rel="noopener noreferrer"
            >
              Android Play store
            </a>{' '}
            or search for MEWconnect Application in the App store if you use an
            iPhone or Play Store if you use an Android phone.
          </p>
          <p>
            1. Once you downloaded, installed and opened the application, please
            click on ‘Create new wallet’.
          </p>
          <p>
            2. Pick a password (we highly recommend writing that password on
            paper and keeping it in a secure location as this information cannot
            be recovered).
          </p>
          <p>3. You will be asked to enter your password a second time.</p>
          <p>4. Your wallet has been created.</p>
          <p>
            5. You can now access your public address: it is in the form of a
            long string of numbers and letters starting with “0x“. Click on the
            public address to copy it. It is the e-wallet address that you
            should update in your profile on the Efiko platform to be able to
            receive the tokens.
          </p>
          <p>
            6. For safety and recovery in case of device loss, you should
            back-up your wallet: in the MEWconnect Application, click on the
            button “Back up”, enter your password and write the recovery phrase
            down. Please store it in a safe place.
          </p>
        </>
      ),
    },
  ],

  'Regulations check': [
    {
      header: 'Why do I need a Efiko (Switzerland) account?',
      content: (
        <>
          <p className={s.par}>
            Efiko (Switzerland) is a compliant platform for private securities
            addressing compliance end-to-end challenges.
          </p>
          <p className={s.par}>
            In order to adhere to securities and anti-money laundering
            legislation, all investors must pass applicable verification
            requirements, including Know Your Customer (KYC) policies,
            Anti-Money Laundering (AML) requirements, tax laws, and more.
          </p>
        </>
      ),
    },
    {
      header: 'Is the subscription free?',
      content: (
        <>
          <p className={s.par}>
            Yes, the subscription is free. There is no obligation or further
            costs with the subscription on Efiko.
          </p>
        </>
      ),
    },
    {
      header: 'What are the criteria to become a whitelisted investor?',
      content: (
        <>
          <p className={s.par}>
            To become whitelisted, you must first create an account (input your
            name, email, phone number and password). Next, on the project page
            you should follow our KYC process:
          </p>
          <p>1. Confirm the investor type.</p>
          <p>
            2. Provide picture of an officially recognized identity document.
          </p>
          <p>3. Provide a selfie.</p>
          <p>
            Company documents, listing of the board members and identification
            of ultimate beneficial owners of the company (if identifiable -
            physical person who owns more than 25% of the shares of the
            company).
          </p>
          <p className={s.par}>
            The verification process takes between 1 to 3 minutes for a private
            investor and up to 3 days for a corporate/institutional investor.
            You will receive a confirmation mail when your profile will be
            validated, or with a request to upload new documents if they were
            not accepted. After 3 attempts of uploading a not acceptable
            document, your profile will be banned.
          </p>
        </>
      ),
    },
    {
      header: 'Why am I being asked to verify my identity?',
      content: (
        <>
          <p className={s.par}>
            In order to adhere to securities and anti-money laundering laws, all
            investors must pass applicable verification requirements, including
            Know Your Customer (KYC) policies, Anti-Money Laundering (AML)
            requirements, tax laws, and more.
          </p>
        </>
      ),
    },
    {
      header: 'How do I take a valid photo of my ID?',
      content: (
        <>
          <p>• All corners of the document should be visible in the photo.</p>
          <p>
            • The text in the document should be easy to read. Make sure there
            are no highlights and stains on the photo. Low quality pictures
            won’t be accepted by the system.
          </p>
          <p>
            • If your document has two pages or two sides (for example, a US
            ID-card or a CIS passport), both sides / pages must be attached.
          </p>
          <p>
            • The document must be valid for at least a month. An expired
            document won’t be validated.
          </p>
          <p>
            • The system doesn’t accept documents edited in graphic editor
            software or programs.
          </p>
          <p>• We accept JPEG or PDF files no less than 500 KB.</p>
          <p>
            • In some cases, the document has to have Latin transliteration as
            the system doesn’t accept Arabic ligature.
          </p>
        </>
      ),
    },
    {
      header: 'What documents are valid to confirm my address?',
      content: (
        <>
          <p className={s.par}>List of Acceptable Documents:</p>
          <p>
            • Utility bills (i.e. gas, electric, water, fixed line telephone,
            etc.)
          </p>
          <p>• Bank statements</p>
          <p>• Tax returns</p>
          <p>• Council tax bills</p>
          <p>• Government-issued documents</p>

          <p className={s.par}>Please take note:</p>

          <p>
            • If you attach a utility bill or bank statement, they must have
            been issued within the last 3 months and printed on the official
            form.
          </p>
          <p>
            • We need to confirm your residential address; the system doesn’t
            accept addresses of legal entities or relatives.
          </p>
          <p>
            • Your document must contain the full name (as in the ID), the
            owner’s address and the date of issue.
          </p>
          <p>
            • The system doesn’t accept documents in ligature without Latin
            transliteration. If your document is fully in Arabic or another
            language with ligature, you have to make a notarized translation in
            English.
          </p>
          <p>
            • You must provide a photo of the whole document. None of the banks
            or regulators accept pieces of documents. And neither do we.
          </p>
          <p>
            • If your address is stated in your passport or driving license, you
            will not be able to confirm your identity and place of residence
            with the same document. In this case, use another document at the
            identity check stage — driving license, another type of the passport
            or ID-card.
          </p>
        </>
      ),
    },
    {
      header: 'Why do we collect and process your Personal Data?',
      content: (
        <>
          <p className={s.par}>
            The Personal Data collected and processed by the Efiko platform may
            be used to:
          </p>
          <p>• Contact you.</p>
          <p>• Identify you.</p>
          <p>
            • Perform our contractual duties regarding you or your
            organization/company.
          </p>
          <p>
            • Conduct administrative or operational processes within the Wecan
            Tokenize platform.
          </p>
          <p>
            • Process and respond to requests, enquiries or complaints received
            from you or someone within your organization/company.
          </p>
          <p>
            • Manage and administer your or your organization/company’s business
            relationship with the Efiko platform, including processing payments
            and billing.
          </p>
          <p>
            • Comply with any legal obligation where the Efiko platform is
            required to process the Personal Data, such as keeping records for
            legal purposes (anti-money laundering, tax, corporate).
          </p>
          <p>• Provide, monitor and improve Efiko platform’s services.</p>
          <p>• Send newsletters and marketing material.</p>
          <p>• Monitor the use of the Website.</p>
          <p>
            • Comply with any requests of or duties towards governmental
            authorities, law enforcement agencies, regulatory bodies or courts.
          </p>
          <p>
            • Establish, exercise or defend the legal rights of Efiko or for the
            purposes of legal proceedings.
          </p>
          <p>
            • For any other legitimate business purpose or overriding interest
            of Efiko.
          </p>
        </>
      ),
    },
  ],

  'Account help': [
    {
      header: 'How do I switch my account to a different investor type?',
      content: (
        <>
          <p className={s.par}>
            This action is possible only if you didn’t start the KYC/AML
            verification yet.
          </p>
          <p>1. Login to your account.</p>
          <p>
            2. Under the Account information field, click the button Switch to
            Private investor/Corporate investor to switch the status.{' '}
          </p>

          <p className={s.par}>
            If you did start the KYC/AML verification, then you will need to
            create a different account and re-start the KYC/AML verification as
            a different type of investor.
          </p>
        </>
      ),
    },
    {
      header: 'How do I change the account owner?',
      content: (
        <>
          <p className={s.par}>
            Each user may register for one account only, which is personal and
            not transferable. You can find more information in the
            <Link to={ROUTES.LEGALS_MENTIONS}> Terms and conditions</Link> page.
          </p>
          <p className={s.par}>
            In the event that you need to change the account owner for the Wecan
            Tokenize SA (Switzerland) platform, please reach out to{' '}
            <a href="mailto:contact@wecantokenize.com?subject=Change the account owner">
              contact@wecantokenize.com
            </a>{' '}
            for next steps.
          </p>
        </>
      ),
    },
    {
      header: 'How do I change the IBAN after I made an investment?',
      content: (
        <>
          <p className={s.par}>
            Please be aware that you may only change this IBAN if the ultimate
            beneficial owner remains the same person. If a change of IBAN is
            required, or for any questions in relation to this matter, please
            contact us at{' '}
            <a href="mailto:contact@wecantokenize.com?subject=Change the account owner">
              contact@wecantokenize.com
            </a>
          </p>
        </>
      ),
    },
    {
      header: 'Will I have access to necessary paperwork if I am audited?',
      content: (
        <>
          <p className={s.par}>
            All related paperwork will be electronically sent to you.
          </p>
        </>
      ),
    },
  ],
};
