import WecanTokenizeLogo from '~image-assets/logos/WecanTokenize_tagline.svg';
// import gmgLogo from '~image-assets/logos/GMG_Logo_Tagline.png';
import evooqLogo from '~image-assets/logos/evooq_logo.png';
import ochsnerLogo from '~image-assets/logos/Ochsner&Associes.png';
import ehpLogo from '~image-assets/logos/ehp-black.svg';
import pwcLogo from '~image-assets/logos/pwc.png';

import dumas from '~image-assets/team/Nelson_Dumas.png';
import baker from '~image-assets/team/Christine_Baker.jpg';
import cowling from '~image-assets/team/Didier_Cowling.jpg';
import tisseyre from '~image-assets/team/Olivier_Tisseyre.jpg';

/* eslint-disable */
export const PARTNERS = [
  {
    link: 'https://www.evooq.ch/',
    img: evooqLogo,
    name: 'Evooq',
    text:
      'to be done',
  },
//   {
//     link: 'https://wecantokenize.com/',
//     img: WecanTokenizeLogo,
//     name: 'WecanTokenize',
//     text:
//       ' has developped an unique end-to-end solution to digitize the investment process, and grant access to selected assets.',
//   },
//   {
//     link: 'http://gmgfinancial.com/',
//     img: gmgLogo,
//     name: 'The Geneva Management Group (GMG)',
//     text:
//       ' was founded in 2000 as a multi-family office. The journey to GMG’s current multi-solution business-to-business model was a result of bearing witness to an industry going through unprecedented cycles of change and ultimately, a diversified market & client demand.',
//   },
];

export const TEAM = [
  {
    name: 'Nelson Dumas',
    img: dumas,
    link: 'https://www.linkedin.com/in/nelson-dumas-3363ab175/',
    text: 'Driven by impact, I have been looking at a solution to de-risk investment in startups to enable faster growth. I am proud to offer my managerial and strategical skills to the startups I support. As a judge for Masschallenge and a certificated board member,  I have an excellent grasp on the hurdles new ventures face. As an investor, I understand the appeal of non-dilutive financing solutions and, as an entrepreneur, the frustration of slow market uptake. Finally, as a strong believer in the HaaS system, I’m convinced that Eltenemo is the way forward for many impact-driven projects in pre-commercial phase.'
  },
  {
    name: 'Christine Baker',
    img: baker,
    link: 'https://www.linkedin.com/in/christineebaker',
    text: 'I am committed to using finance as a force for good.  After spending the early part of my career advising large institutional investors on mainstream asset allocation strategies in global energy, I have dedicated the second part to helping purpose-led companies navigate a sustainable growth path.  As a co-founder, board member and advisor to a number of start-ups, I have first-hand knowledge of the challenges inherent in charting an investible go-to-market strategy.  Eltenemo is building an innovative and scalable funding model to support impactful technologies and I look forward to working with its team.'
  },
  {
    name: 'Olivier Tisseyre',
    img: tisseyre,
    link: 'https://www.linkedin.com/in/otisseyre',
    text: 'I have been busy developing, traveling, managing the technology and management consulting group I co-created. Just before the Covid pandemic, I left that life to shift paradigms: on a trip with an NGO to Syrian refugee camps, I felt strongly the urgency for people and planet. I started learning about sustainability: from Benefit Corporation to Sustainable Finance. My experience as an engineer and entrepreneur led me to join the launch of Eltenemo. Many very promising solutions are not adopted by customers due to funding issues. HaaS represents a real opportunity for early adopters who want to make a difference.'
  },
  
];

export const ADVISORS = [
  {
    name: 'Elvinger Hoss Prussen',
    img: ehpLogo,
    subtitle: 'Luxembourg Legal Advisor',
  },
  {
    name: 'PWC',
    img: pwcLogo,
    subtitle: 'Financial Audit and Technical Review',
  },
  {
    name: 'Ochsner & Associes',
    img: ochsnerLogo,
    subtitle: 'Switzerland Legal Advisor',
  },
];
