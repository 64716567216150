import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MinInvestmentModal from './MinInvestmentModal';

import {
  toggleMinInvestmentModal,
  toggleInvestorTypeModal,
} from '~redux/users/actions';
import { isMinInvestmentModalOpen } from '~redux/users/selectors';

import { sendApplicantRequest, updateUser } from '~redux/profile/actions';
import { profile } from '~redux/profile/selectors';

const selector = createStructuredSelector({
  isMinInvestmentModalOpen,
  profile,
});

const actions = {
  toggleMinInvestmentModal,
  toggleInvestorTypeModal,
  sendApplicantRequest,
  updateUser,
};

export default connect(
  selector,
  actions,
)(MinInvestmentModal);
