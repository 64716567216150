import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PasswordRecoveryForm from '../../components/PasswordRecoveryForm';
import ResetCodeForm from './components/ResetCodeForm';

export default class PasswordRecovery extends Component {
  static propTypes = {
    isRecoveryRequested: PropTypes.bool.isRequired,
  };

  render() {
    const { isRecoveryRequested, history } = this.props;

    return isRecoveryRequested ? (
      <ResetCodeForm history={history} />
    ) : (
      <PasswordRecoveryForm history={history} />
    );
  }
}
