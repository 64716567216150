import { reduxForm } from 'redux-form';

import AssetImages from './AssetImages';

const formConect = reduxForm({
  form: 'assetFiles',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default formConect(AssetImages);
