import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Form, Button, Row, Modal, Checkbox } from 'antd';
import classnames from 'classnames';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import { required } from '~redux/redux-form-validators';
import makeField from '../../WrappedFormElement';
import { programmeMemorandumUrl } from '~common/constants/assets';

import SubscriptionFormText from './components/SubscriptionFormText.js';

import styles from './Subscription.module.scss';
import formStyle from '../Form.module.scss';

const CheckboxField = makeField(Checkbox);

class Subscription extends Component {
  state = {
    hasAgreedToTerms: false,
    allChecked: false,
  };

  handleAgreeClick = () => {
    this.setState({
      hasAgreedToTerms: true,
    });
  };

  render() {
    const {
      onCancel,
      onSubmit,
      invalid,
      investmentFormValues: { amount },
      asset: {
        currency,
        valueDate,
        iban,
        files: { assetMemorandum },
        saleStartedAt,
      },
      email,
      username,
      checkboxCheckedStatus,
    } = this.props;
    const { hasAgreedToTerms } = this.state;

    const assetMemorandumUrl =
      (assetMemorandum[0] && assetMemorandum[0].url) || '';

    const checkboxes = [
      {
        name: 'memorandumsConfirm',
        label: (
          <label htmlFor="memorandumsConfirm">
            I confirm having read, understood and hereby accept with the terms
            of the{' '}
            <a
              href={programmeMemorandumUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Programme Memorandum
            </a>{' '}
            and of the{' '}
            <a
              href={assetMemorandumUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Series Memorandum
            </a>
            .
          </label>
        ),
      },
      {
        name: 'bankingCommit',
        label: (
          <label htmlFor="bankingCommit">
            I commit to instruct the appropriate amount on the banking account
            of WCT Luxembourg SA before the value date specified above.
          </label>
        ),
      },
    ];

    return (
      <Form onSubmit={onSubmit}>
        <Modal
          visible={!hasAgreedToTerms}
          centered
          closable={false}
          footer={null}
          className={styles.modal}
        >
          <div className={styles.mustAgree}>
            <SubscriptionFormText
              saleStartedAt={saleStartedAt}
              amount={amount}
              email={email}
              username={username}
              iban={iban}
              currency={currency}
            />
            <Row className={formStyle.actions}>
              <Button
                className={classnames(formStyle.next, styles.btn)}
                onClick={this.handleAgreeClick}
              >
                I accept the terms
              </Button>
            </Row>
          </div>
        </Modal>
        <div className={styles.agreement}>
          <div className={styles.row}>
            <p className={styles.label}>Value date:</p>
            <p className={styles.value}>
              {moment(valueDate).format('DD MMM YYYY')}
            </p>
          </div>
          <div className={styles.divider} />
          <div className={styles.row}>
            <p className={styles.label}>Contributed amount: </p>
            <p className={styles.value}>
              <NumberFormat
                value={amount}
                displayType="text"
                thousandSeparator={true}
                prefix={`${currency} `}
              />
            </p>
          </div>
          <div className={styles.divider} />

          <div className={styles.checkboxBlock}>
            <p className={styles.text}>
              All documents and information will be sent to you by mail once the
              investment is completed.
            </p>
            {checkboxes.map(({ name, label }) => (
              <Form.Item className={styles.input} key={name}>
                <Field
                  name={name}
                  id={name}
                  label={label}
                  component={CheckboxField}
                  checked={checkboxCheckedStatus[name]}
                  validate={[required]}
                />
              </Form.Item>
            ))}
          </div>
        </div>
        <Row className={styles.actions}>
          <Button
            onClick={onCancel}
            className={classnames(formStyle.back, styles.btn)}
          >
            go back
          </Button>
          <Button
            className={classnames(formStyle.submit, styles.btn)}
            htmlType="submit"
            disabled={!hasAgreedToTerms || invalid}
          >
            Continue
          </Button>
        </Row>
      </Form>
    );
  }
}

export default Subscription;
