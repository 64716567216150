import { connect } from 'react-redux';

import { getAsset } from '~redux/assets/actions';

import Item from './Item';

const actions = { getAsset };

const reduxConect = connect(
  null,
  actions,
);

export default reduxConect(Item);
