import React, { Component } from 'react';
import Slider from 'react-slick';
import cn from 'classnames';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import s from './ImageCarousel.module.scss';
import SlickNextArrow from '../../../Carousel/SlickNextArrow';
import SlickPrevArrow from '../../../Carousel/SlickPrevArrow';

export default class ImageCarousel extends Component {
  state = {
    nav1: null,
    nav2: null,
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.nav1 && this.slider1)
      return this.setState({
        nav1: this.slider1,
      });

    if (!prevState.nav2 && this.slider2)
      return this.setState({
        nav2: this.slider2,
      });

    if (
      prevProps.isFullScreenSliderOpen !== this.props.isFullScreenSliderOpen &&
      this.state.nav1
    )
      this.state.nav1.slickGoTo(this.props.activeSlide);
  }

  render() {
    const {
      asset: {
        files: { assetImages },
      },
      extraClass,
      toggleOpenFullScreenSlider,
      setActiveSlide,
      isFullScreenSliderOpen,
    } = this.props;

    const { nav1, nav2 } = this.state;

    return assetImages[0] ? (
      <div className={cn(s.container, s[extraClass])}>
        <Slider
          asNavFor={nav2}
          ref={slider => (this.slider1 = slider)}
          beforeChange={(current, next) => setActiveSlide(next)}
          speed={200}
          className={s.top}
          nextArrow={<SlickNextArrow />}
          prevArrow={<SlickPrevArrow />}
        >
          {assetImages.map(({ url }) => (
            <div
              key={url}
              onClick={() =>
                isFullScreenSliderOpen ? null : toggleOpenFullScreenSlider(true)
              }
            >
              <img className={s.image} src={url} alt="asset" />
            </div>
          ))}
        </Slider>
        {assetImages.length >= 2 && (
          <Slider
            asNavFor={nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={assetImages.length >= 3 ? 3 : assetImages.length}
            swipeToSlide={true}
            focusOnSelect={true}
            className={s.bottom}
            centerMode={true}
          >
            {assetImages.map(({ url }) => (
              <div key={url}>
                <img src={url} alt="asset" />
              </div>
            ))}
          </Slider>
        )}
      </div>
    ) : null;
  }
}
