export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CONFIRM_EMAIL: '/confirmEmail/:hash',
  PROFILE: '/profile',
  PROFILE_INFO: '/profile/info',
  PROFILE_TRANSACTIONS: '/profile/transactions',
  CONTACT_US: '/contact-us',
  RESET: '/reset-password',
  RESET_PASSWORD: '/change-password',
  PASSWORD_RECOVERY: '/password-recovery',
  PROFILE_CONTACT_FORM: '/profile/contact-us',
  ABOUT_US: '/about-us',
  FAQ: '/faq',
  LEGALS_MENTIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  DASHBOARD: '/dashboard',

  ADMIN_ROOT: '/admin',
  ADMIN_LOGIN: '/admin/login',
  ADMIN_SIGNUP: '/admin/signup',
  ADMIN_USERS: '/admin/users',
  ADMIN_ASSETS: '/admin/assets',
  SETUP_TWOFA: '/admin/setup-twofa',
  ASSETS_SETTINGS: '/admin/assets-settings',
  ADD_ASSET: '/admin/assets-settings/add',
  CONTRIBUTIONS: '/admin/contributions',

  ADD_INVESTOR: '/admin/investors/add',
  INVESTORS: '/admin/investors/',

  PROJECTS: '/projects',
  PROJECT: '/projects/:id',
  CLOSED_PROJECT: '/closed-projects/:id',
  ONBOARDING: '/onboarding',
  INVEST: '/invest/:id',
};

export const TYPEFORM = 'https://wecangroup.typeform.com/to/O5OGSI';

export const META = {
  [ROUTES.HOME]: {
    title: '',
    name: 'description',
    content:
      'Our white label blockchain solution provides a secure and accessible interface for your business to create and deliver tokens',
  },
  [ROUTES.LOGIN]: {
    title: 'Log in',
  },
  [ROUTES.SIGNUP]: {
    title: 'Create an account to start private investments projects',
    name: 'description',
    content:
      'Sign up for free and create your account to start investing in valuable institutional private investments with security tokens.',
  },
  [ROUTES.CONFIRM_EMAIL]: {
    title: 'Email confirmation',
  },
  [ROUTES.PROFILE]: {
    title: 'Profile page',
  },
  [ROUTES.CONTACT_US]: {
    title: 'Contact us',
  },
  [ROUTES.RESET]: {
    title: 'Password reset',
  },
  [ROUTES.RESET_PASSWORD]: {
    title: 'Change password',
  },
  [ROUTES.PASSWORD_RECOVERY]: {
    title: 'Password recovery',
  },
  [ROUTES.PROFILE_CONTACT_FORM]: {
    title: 'Contact us',
  },
  [ROUTES.ABOUT_US]: {
    title: 'About us: our partners, team and advisors',
    name: 'description',
    content:
      'Efiko is a swiss-based joint-venture founded in July 2019 in Geneva between these partners Wecan Group, Geneva Management Group and Capelli Foncier.',
  },
  [ROUTES.FAQ]: {
    title: 'Frequently asked questions about tokenization',
    name: 'description',
    content:
      'Learn more about asset tokenization in Efiko: tokenization, e-wallet, regulations check and account help.',
  },
  [ROUTES.LEGALS_MENTIONS]: {
    title: 'General terms and conditions',
    name: 'description',
    content:
      'Consult our terms and conditions for: activity, access and user account and more.',
  },
  [ROUTES.PRIVACY_POLICY]: {
    title: 'Privacy Policy',
    name: 'description',
    content:
      'Efiko respect your privacy and to comply with the provisions of the Swiss Data Protection Act (DPA) and the EU General Data Protection Regulation (GDPR).',
  },

  [ROUTES.ADMIN_ROOT]: {
    title: 'Admin panel',
  },
  [ROUTES.ADMIN_USERS]: {
    title: 'Manage users',
  },
  [ROUTES.ADMIN_ASSETS]: {
    title: 'Manage assets',
  },
  [ROUTES.SETUP_TWOFA]: {
    title: 'Setup two-factor authentication',
  },
  [ROUTES.ASSETS_SETTINGS]: {
    title: 'Manage asset settings',
  },
  [ROUTES.ADD_ASSET]: {
    title: 'Add a new asset',
  },
  [ROUTES.CONTRIBUTIONS]: {
    title: 'Manage contributions',
  },
  [ROUTES.ADD_INVESTOR]: {
    title: 'Add a new investor',
  },
  [ROUTES.INVESTORS]: {
    title: 'Manage investors',
  },

  [ROUTES.PROJECTS]: {
    title: 'Current investments in real estate projects',
    name: 'description',
    content:
      'Discover our current and soon ton come investments projects with secured by our tokens with a good interest rate Distributed by Capelli Group and GMG.',
  },
  // [ROUTES.PROJECT]: '/projects/:id',
  // [ROUTES.CLOSED_PROJECT]: '/closed-projects/:id',
  [ROUTES.ONBOARDING]: {
    title: 'KYC whitelisting',
  },
  [ROUTES.INVEST]: {
    title: 'Investment process',
  },
};
