import React, { Component } from 'react';
import { Modal, Button, Row } from 'antd';
import envelopeIcon from '~image-assets/contributions/icon_new-email.svg';

import styles from '../../Table/Table.module.scss';
import formStyle from '../../../../components/Assets/Form/Form.module.scss';

export default class ContributionConfirmationModal extends Component {
  render() {
    const { visible, handleCancel, handleSubmit, type } = this.props;

    return (
      <Modal
        className={styles.confirmationModal}
        visible={visible}
        onCancel={handleCancel}
        centered
        footer={null}
      >
        <img src={envelopeIcon} alt="Envelope icon" />
        <h2>Do you want to {type}?</h2>
        <p>The investor will be automatically notified.</p>
        <div className={styles.bottom}>
          <Row className={formStyle.actions}>
            <Button
              key="NO"
              onClick={handleCancel}
              className={formStyle.cancel}
            >
              NO
            </Button>
            <Button key="YES" onClick={handleSubmit} className={formStyle.next}>
              YES
            </Button>
          </Row>
        </div>
      </Modal>
    );
  }
}
