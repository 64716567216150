import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';

import { asset } from '~redux/assets/selectors';
import { profile } from '~redux/profile/selectors';
import { loading } from '~redux/users/selectors';
import {
  loading as contributionsLoading,
  contributionData,
  getInvestorData,
} from '~redux/contributions/selectors';

import { updateAssetFields } from '~redux/assets/actions';
import { addContribution } from '~redux/contributions/actions';
import { addInvestor } from '~redux/users/actions';

import { compose } from '~utils';

import Invest from './Invest';

const selectors = createStructuredSelector({
  loading,
  contributionsLoading,
  asset,
  profile,
  contributionData,
  investorData: getInvestorData,
});

const actions = {
  addContribution,
  addInvestor,
  updateAssetFields,
};

const reduxConnect = connect(
  selectors,
  actions,
);

const formConect = reduxForm({
  form: 'investmentForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default compose(
  reduxConnect,
  formConect,
)(Invest);
