import React, { Component } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { ROUTES } from '~constants/routes';
import { STATUSES_TITLES } from '~constants/assets';
import formStyle from '../../../components/Assets/Form/Form.module.scss';

import s from './Marketplace.module.scss';

import Asset from './Asset';
import { USER_STATUS } from '~constants/users';
import InvestorTypeModal from '../Asset/components/InvestorTypeModal';
import MinInvestmentModal from '../Asset/components/MinInvestmentModal';

const BUTTONS = {
  ACTIVE: {
    title: 'Are you interested?',
    btnText: 'CONTINUE',
  },
  KYC_PENDING: {
    title: 'Get Whitelisted',
    to: ROUTES.ONBOARDING,
    btnText: 'PENDING APPROVAL',
  },
};

export default class Marketplace extends Component {
  state = {
    isModalOpen: false,
  };

  componentWillMount() {
    this.props.allAssets(); // TODO add own endpoint
  }

  componentDidMount() {
    const { profile, loadContributions } = this.props;

    if (profile.id) return loadContributions(profile.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.profile.id && this.props.profile.id) {
      const {
        profile: { id },
        loadContributions,
      } = this.props;

      loadContributions(id);
    }
  }

  render() {
    const {
      assets,
      isAuthenticated,
      status,
      toggleInvestorTypeModal,
      history,
    } = this.props;

    return (
      <div className={s.container}>
        <InvestorTypeModal />

        <MinInvestmentModal history={history} />

        <div className={s.title}>Check current projects</div>
        <div className={s.content}>
          {assets &&
            Object.keys(assets).map(type => (
              <div className={s.block} key={type}>
                <div className={s.header}>{STATUSES_TITLES[type]}</div>
                <div className={s.assets}>
                  {assets[type].map(asset => (
                    <Asset key={asset.id} asset={asset} />
                  ))}
                </div>
              </div>
            ))}
        </div>
        {!isAuthenticated && (
          <div className={s.footer}>
            <span>Are you interested to start investing?</span>
            <a href={ROUTES.LOGIN}>
              <Button
                className={classnames(formStyle.submit, s.btn)}
                htmlType="button"
              >
                LOG IN
              </Button>
            </a>
          </div>
        )}
        {status === USER_STATUS.KYC_PENDING && (
          <div className={s.footer}>
            <span>{BUTTONS[status].title}</span>
            <Link to={BUTTONS[status].to}>
              <Button
                className={classnames(formStyle.submit, s.btn)}
                htmlType="button"
              >
                {BUTTONS[status].btnText}
              </Button>
            </Link>
          </div>
        )}
        {status === USER_STATUS.ACTIVE && (
          <div className={s.footer}>
            <span>{BUTTONS[status].title}</span>
            <Button
              className={classnames(formStyle.submit, s.btn)}
              htmlType="button"
              onClick={() => toggleInvestorTypeModal(true)}
            >
              {BUTTONS[status].btnText}
            </Button>
          </div>
        )}
      </div>
    );
  }
}
