import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { signup, loadProfile } from '~redux/profile/actions';
import { loading } from '~redux/profile/selectors';

import Signup from './Signup';

const selector = createStructuredSelector({
  loading,
});
const actions = { signup, loadProfile };

export default connect(
  selector,
  actions,
)(Signup);
