import { getAccessToken } from './actions';

const apiUrl =
  window.location.hostname === 'efiko.com' // TODO: replace with actual hostname, once chosen
    ? 'https://api.sumsub.com'
    : 'https://test-api.sumsub.com';

export const launchWebSdk = (
  accessToken,
  applicantEmail,
  applicantPhone,
  investorType,
  id,
) => {
  return new Promise((resolve, reject) => {
    // CORPORATE-flow or INDIVIDUAL-flow created through sumsub Integrations UI
    const flowName = `${investorType}-flow`;

    const snsWebSdkInstance = window.snsWebSdk
      .Builder(apiUrl, flowName)
      .withAccessToken(accessToken, async newAccessTokenCallback => {
        // if access token expired
        // get a new one and pass it to the callback to re-initiate the WebSDK
        const { data } = await getAccessToken({ id });
        const { accessToken } = data;

        newAccessTokenCallback(accessToken);
      })
      .withConf({
        lang: 'en',
        email: applicantEmail,
        phone: applicantPhone,
        onMessage: (type, payload) => {
          if (type === 'idCheck.onApplicantLoaded') {
            const applicantId = payload.applicantId;

            return resolve(applicantId);
          }
        },
        uiConf: {
          customCss: 'https://staging.wecantokenize.com/css/webSdk.css',
        },
        onError: error => {
          return reject(error);
        },
      })
      .build();

    // launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch('#sumsub-websdk-container');
  });
};
