export const prefix = 'dashboard';

export const GET_RATE_REQUEST = `${prefix}/GET_RATE_REQUEST`;
export const GET_RATE_SUCCESS = `${prefix}/GET_RATE_SUCCESS`;
export const GET_RATE_FAILURE = `${prefix}/GET_RATE_FAILURE`;

export const getRate = () => ({
  type: GET_RATE_REQUEST,
  //promise: api => api.dashboard.rate()
  payload: TICKER,
});

export const GET_HISTORY_REQUEST = `${prefix}/GET_HISTORY_REQUEST`;
export const GET_HISTORY_SUCCESS = `${prefix}/GET_HISTORY_SUCCESS`;
export const GET_HISTORY_FAILURE = `${prefix}/GET_HISTORY_FAILURE`;

export const getHistory = ({ timeframe, period }) => ({
  type: GET_HISTORY_REQUEST,
  //promise: api => api.dashboard.history({ timeframe, period }),
  payload: createMockHistory(timeframe, period),
});

// MOCK DATA
function createMockHistory(timeframe, period) {
  const TIMEFRAMES = {
    '1_MONTH': 35,
    '6_MONTH': 180,
    YTD: 356,
    '1_YEAR': 365,
  };
  const history = [];
  for (let i = 1; i <= TIMEFRAMES[timeframe]; i++) {
    history.push({
      value: Math.random() * (100 - 20) + 20,
      dateTime: new Date(2020, 0, i),
    });
  }
  return history;
}

const TICKER = {
  id: 'Daphne Technology',
  longName: 'Daphne Technology',
  price: 150,
  volume: 200,
  open: 145,
  name: 'Daphne Technology',
  netAssetValue: 12,
  netAssetValueDate: Date.now(),
  previousClosingPriceOneTradingDayAgo: 120,
  priceChange1Day: 1.58,
  percentChange1Day: 0.13,
  shareClass: 'Classic Capitalisation',
  totalAssets: 2,
  totalReturn3Month: 4,
  tradingDayClose: Date.now(),
  companyAddress: 'companyAddress',
  companyDescription: 'companyDescription',
  fundAssetClassFocus: '--',
  fundObjective: 'fundObjective',
  highPrice52Week: 102.992,
  lowPrice52Week: 99.876,
  lastUpdate: Date.now(),
};
