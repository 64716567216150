import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { closeAccount, logout } from '~redux/profile/actions';
import { email } from '~redux/profile/selectors';

import CloseAccount from './CloseAccount';

const selector = createStructuredSelector({ email });
const actions = {
  closeAccount,
  logout,
};

export default connect(
  selector,
  actions,
)(CloseAccount);
