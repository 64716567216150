import React from 'react';
import { Field } from 'redux-form';
import { Form, Button, Row, Select } from 'antd';
import { TextField } from 'redux-form-antd';
import FileUpload from '../FileUpload';
import styles from './Form.module.scss';
import {
  maxLength,
  minLength,
  required,
  number,
} from '~redux/redux-form-validators';
import { COUNTRIES } from '~constants/assets';
import PhoneInput from '../../PhoneInput';
import makeField from '../../WrappedFormElement';

const FileUploadElement = makeField(FileUpload);
const SelectElement = makeField(Select);

class InvestorFormCreation extends React.Component {
  state = {
    isCountrySelected: false,
  };

  componentDidMount() {
    this.props.allAssets();
  }

  selectCountry = () => {
    this.setState({
      isCountrySelected: true,
    });
  };

  showPlaceholder = value => {
    if (!value[0]) {
      this.setState({
        isCountrySelected: false,
      });
    }
  };

  render() {
    const { handleSubmit, valid, projects = [] } = this.props;
    const { isCountrySelected } = this.state;

    return (
      <div className={styles.container}>
        <h1 className={styles.title}>Here you can manually add new investor</h1>
        <Form onSubmit={handleSubmit} className={styles.form}>
          <Row className={styles.row}>
            <Form.Item className={styles.sInput}>
              <Field
                name="firstName"
                type="text"
                label="First name"
                placeholder="First name"
                component={TextField}
                validate={[required, minLength(3), maxLength(30)]}
              />
            </Form.Item>
            <Form.Item className={styles.sInput}>
              <Field
                name="email"
                type="text"
                label="Email"
                placeholder="Email"
                component={TextField}
                validate={[required, minLength(3), maxLength(30)]}
              />
            </Form.Item>
          </Row>
          <Row className={styles.row}>
            <Form.Item className={styles.sInput}>
              <Field
                name="lastName"
                type="text"
                label="Last name"
                placeholder="Last name"
                component={TextField}
                validate={[required, minLength(3), maxLength(30)]}
              />
            </Form.Item>
            <Form.Item className={styles.phoneInput}>
              <Field
                name="phoneNumber"
                type="text"
                label="Phone number"
                placeholder="+41"
                component={PhoneInput}
                validate={[required, minLength(3), maxLength(30)]}
              />
            </Form.Item>
          </Row>
          <Row className={styles.bottomAlignedRow}>
            <Form.Item className={styles.file}>
              <Field
                footer="Front side"
                label="ID documents"
                name="idFrontSide"
                component={FileUploadElement}
                validate={[required]}
                footerClassName={styles.footer}
              />
            </Form.Item>
            <Form.Item className={styles.file}>
              <Field
                footer="Back side"
                name="idBackSide"
                component={FileUploadElement}
                validate={[required]}
                footerClassName={styles.footer}
              />
            </Form.Item>
          </Row>

          <div className={styles.divider} />
          <div className={styles.subTitle}>
            <span>Mandatory information</span>
          </div>
          <Row className={styles.row}>
            <Form.Item className={styles.sInput}>
              <Field
                name="country"
                type="text"
                label="Country"
                placeholder={isCountrySelected ? '' : 'Switzerland'}
                component={SelectElement}
                validate={[required]}
                onFocus={this.selectCountry}
                onBlur={this.showPlaceholder}
                showSearch
              >
                {COUNTRIES.map(({ value, label }) => (
                  <Select.Option key={value} value={label}>
                    {label}
                  </Select.Option>
                ))}
              </Field>
            </Form.Item>
            <Form.Item className={styles.sInput}>
              <Field
                name="zip"
                type="text"
                label="Postal code"
                placeholder="1204"
                component={TextField}
                validate={[required, minLength(3), maxLength(30)]}
              />
            </Form.Item>
          </Row>
          <Row className={styles.row}>
            <Form.Item className={styles.sInput}>
              <Field
                name="city"
                type="text"
                label="City"
                placeholder="Geneva"
                component={TextField}
                validate={[required, minLength(3), maxLength(30)]}
              />
            </Form.Item>
            <Form.Item className={styles.sInput}>
              <Field
                name="address"
                type="text"
                label="Street address"
                placeholder="General-Dufour 20"
                component={TextField}
                validate={[required, minLength(3), maxLength(30)]}
              />
            </Form.Item>
          </Row>
          <Row className={styles.bottomAlignedRow}>
            <Form.Item className={styles.file}>
              <Field
                label="Proof of residency"
                name="proof"
                component={FileUploadElement}
                validate={[required]}
              />
            </Form.Item>
            <Form.Item className={styles.file}>
              <Field
                label="AML Checks"
                name="AMLChecks"
                component={FileUploadElement}
                validate={[required]}
              />
            </Form.Item>
          </Row>
          <div className={styles.divider} />
          <div className={styles.subTitle}>
            <span>Investment information</span>
          </div>

          <Row className={styles.leftAlignedRow}>
            <Form.Item className={styles.sInput}>
              <Field
                name="project"
                type="text"
                label="Select the project in the list"
                component={SelectElement}
                validate={[required]}
              >
                {projects.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Field>
            </Form.Item>
          </Row>
          <Row className={styles.row}>
            <Form.Item className={styles.sInput}>
              <Field
                name="participationAmount"
                type="text"
                label="Amount of participation"
                placeholder="35 000"
                component={TextField}
                validate={[required, maxLength(30), number]}
              />
            </Form.Item>
          </Row>

          <Row className={styles.actions}>
            <Button
              disabled={!valid}
              className={styles.submit}
              htmlType="submit"
            >
              add new investor
            </Button>
          </Row>
        </Form>
      </div>
    );
  }
}

export default InvestorFormCreation;
