import React from 'react';
import cn from 'classnames';
import { Dropdown, Icon, Menu } from 'antd';
import { Link } from 'react-router-dom';
import Notifications from '../Notifications';
import { ROUTES } from '~constants/routes';
import { isMobile as mobileDetector } from '~utils';

const isMobile = mobileDetector();

export default class LoggedInMenu extends React.Component {
  renderProfileMenu = () => {
    const { styles } = this.props;

    return (
      <Menu>
        <Menu.Item>
          <Link className={styles.profileLink} to={ROUTES.PROFILE_INFO}>
            <Icon type="user" />
            <span>Profile</span>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link className={styles.profileLink} to={ROUTES.DASHBOARD}>
            <Icon type="line-chart" />
            <span>Dashboard</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    const {
      styles,
      isAdmin,
      notifications,
      logout,
      onMenuClick,
      isSuperAdmin,
      username,
    } = this.props;

    return (
      <>
        <label className={styles.item} onClick={onMenuClick}>
          <Link className={styles.link} to={ROUTES.PROJECTS}>
            Projects
          </Link>
        </label>
        <label className={styles.item} onClick={onMenuClick}>
          <Link className={styles.link} to={ROUTES.ABOUT_US}>
            About us
          </Link>
        </label>
        <label className={styles.item} onClick={onMenuClick}>
          <Link className={styles.link} to={ROUTES.FAQ}>
            FAQ
          </Link>
        </label>
        {!isMobile && <li className={styles.divider} />}
        <label className={cn(styles.item, styles.icon)} onClick={onMenuClick}>
          <Dropdown
            overlayClassName={styles.notificationsOverlay}
            overlay={<Notifications />}
            trigger={['click']}
          >
            <div className={styles.link}>
              {!isMobile ? (
                <Icon
                  type="bell"
                  className={{ [styles.bell]: notifications.length }}
                />
              ) : (
                'Notifications'
              )}
            </div>
          </Dropdown>
        </label>
        <label className={styles.item} onClick={onMenuClick}>
          <Dropdown
            overlayClassName={styles.notificationsOverlay}
            overlay={this.renderProfileMenu()}
            trigger={['click']}
            placement="bottomLeft"
          >
            <div className={styles.link}>
              <span>{username}</span>
              <Icon type="down" />
            </div>
          </Dropdown>
        </label>
        {isAdmin && (
          <label className={cn(styles.item, styles.icon)} onClick={onMenuClick}>
            <Link
              className={styles.link}
              to={isSuperAdmin ? ROUTES.ADMIN_ROOT : ROUTES.ASSETS_SETTINGS}
            >
              {!isMobile ? <Icon type="home" /> : 'Dashboard'}
            </Link>
          </label>
        )}
        <label className={styles.item} onClick={onMenuClick}>
          <div className={styles.link} onClick={logout}>
            {!isMobile ? <Icon type="logout" /> : 'Log Out'}
          </div>
        </label>
      </>
    );
  }
}
