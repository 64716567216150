import React from 'react';
import PersonalInfo from '../PersonalInfo';
import InvestmentInfo from '../InvestmentInfo';
import { SIGNUP_STEPS, STEP_INFO } from '../../../../constants/users';

import styles from '../RightForm/RightForm.module.scss';

const steps = {
  [SIGNUP_STEPS.PERSONAL]: PersonalInfo,
  [SIGNUP_STEPS.INVESTMENT]: InvestmentInfo,
};

class StepperForm extends React.Component {
  render() {
    const { step, onNext, onPrev, onSubmit } = this.props;

    const Step = steps[step];

    const isLastStep = step === SIGNUP_STEPS.INVESTMENT;

    return (
      <div className={styles.container}>
        <h1 className={styles.title}>{Object.values(STEP_INFO)[step].title}</h1>
        <div className={styles.body}>
          <Step onSubmit={isLastStep ? onSubmit : onNext} onCancel={onPrev} />
        </div>
      </div>
    );
  }
}

export default StepperForm;
