import React from 'react';
import classnames from 'classnames';

import s from '../../AboutUs.module.scss';
import Linkedin from '~image-assets/logos/Linkedin.svg';

const TeamCard = ({ name, img, link, text }) => {
  return (
    <div className={s.cardContainer} key={name}>
      <div className={s.card}>
        <div className={s.side}>
          <img className={s.memberImg} src={img} alt={name} />
        </div>
        <div className={classnames(s.side, s.back)}>
          <p>
            <strong>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {name}
              </a>
            </strong>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <img
                className={s.icon}
                src={Linkedin}
                alt="LinkedIn"
              />
            </a>
          </p>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
