import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';


import { ROUTES } from '~constants/routes';

import styles from './Investing.module.scss';

const Investing = () => (
  <div className={styles.container}>
    <h3>Coming soon</h3>
    
    <Link to={ROUTES.CONTACT_US}>
        <Button className={styles.button}>
        Contact us
        </Button>
    </Link>
  </div>
);

export default Investing;
