import { put, select, takeEvery } from 'redux-saga/effects';
import {
  LOGIN_SUCCESS,
  ACTIVATE_TWOFA_SUCCESS,
  CHECK_TWOFA_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  PROFILE_SUCCESS,
  loadProfile,
} from './actions';
import { needSetupTwoFASelector } from './selectors';
import { location as locationSelector } from '../router/selectors';

import { ROUTES } from '~constants/routes';

const authActions = [
  LOGIN_SUCCESS,
  ACTIVATE_TWOFA_SUCCESS,
  CHECK_TWOFA_SUCCESS,
];

export function* loginSuccess(history, api) {
  yield takeEvery(authActions, function*(action) {
    api.client.setToken(action.data.jwt);
    yield put(loadProfile());
  });
}

export function* signupSuccess() {
  yield takeEvery(SIGNUP_SUCCESS, function*() {});
}

export function* logoutSuccess(history, api) {
  yield takeEvery(LOGOUT_SUCCESS, function() {
    api.client.removeToken();
    history.push(ROUTES.LOGIN);
  });
}

export function* profileSuccess(history) {
  yield takeEvery(PROFILE_SUCCESS, function*() {
    const location = yield select(locationSelector);
    const needSetupTwoFA = yield select(needSetupTwoFASelector);

    if (needSetupTwoFA)
      return history.push({
        pathname: ROUTES.SETUP_TWOFA,
        state: location.state,
      });
  });
}

export function* profileFailure() {}
