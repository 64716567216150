import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { profile, loading, isAdmin, twoFA } from '~redux/profile/selectors';
import { resetTwoFA, updateUser, getIbans } from '~redux/profile/actions';
import { list } from '~redux/contributions/selectors';
import { loadContributions } from '~redux/contributions/actions';

import ProfilePage from './ProfilePage';

const selector = createStructuredSelector({
  profile,
  loading,
  isAdmin,
  list,
  twoFA,
});

const actions = {
  loadContributions,
  resetTwoFA,
  updateUser,
  getIbans,
};

export { ProfilePage };

export default connect(
  selector,
  actions,
)(ProfilePage);
