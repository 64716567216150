import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import NavigationMenu from './NavigationMenu';

import { isAuthenticated } from '~redux/profile/selectors';

const selector = createStructuredSelector({
  isAuthenticated,
});

export default connect(
  selector,
  null,
)(NavigationMenu);
