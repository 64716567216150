import React from 'react';
import { Icon } from 'antd';
import cn from 'classnames';
import ss from './Stepper.module.scss';

const Stepper = ({ step, steps, extraClass }) => (
  <div className={cn(ss.container, ss[extraClass])}>
    {Object.values(steps).map(({ name }, index) => (
      <div
        key={name}
        className={cn(ss.step, {
          [ss.current]: step === index,
          [ss.passed]: step > index,
        })}
      >
        {name}
        <Icon type="check-circle" theme="filled" />
      </div>
    ))}
  </div>
);

export default Stepper;
