import config from '../config';
import ApiClient from './client';

import Auth from './auth';
import Users from './users';
import Assets from './assets';
import Contributions from './contributions';

const apiClient = new ApiClient(config.apiPrefix);

export default {
  auth: new Auth(apiClient),
  users: new Users(apiClient),
  assets: new Assets(apiClient),
  contributions: new Contributions(apiClient),
  client: apiClient,
};
