import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getAsset } from '~redux/assets/actions';
import { asset, remainingSaleDays } from '~redux/assets/selectors';
import { isAuthenticated, status } from '~redux/profile/selectors';

import AssetContent from './AssetContent';

const selectors = createStructuredSelector({
  asset,
  isAuthenticated,
  profileStatus: status,
  remainingSaleDays,
});

const actions = { getAsset };

const reduxConnext = connect(
  selectors,
  actions,
);

export default reduxConnext(AssetContent);
