import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { USER_ROLES } from './constants/users';

import Layout from './pages/Investor/Layout';
import Home from './pages/Investor/Home';
import Login from './pages/Investor/Login';
import Signup from './pages/Investor/Signup';
import Reset from './pages/PasswordRecovery';
import ConfirmEmail from './pages/Investor/ConfirmEmail';

import Marketplace from './pages/Investor/Marketplace';
import Asset from './pages/Investor/Asset';

import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import ResetPasswordPage from './pages/Investor/Reset';
import AdminUsers from './pages/AdminUsers';
import AdminAssets from './pages/AdminAssets';
import AdminContainer from './components/AdminContainer';
import MainLayout from './pages/MainLayout';
import SetupTwoFA from './pages/SetupTwoFA';
import AuthContainer from './components/AuthContainer';
import PrivateRoute from './components/PrivateRoute';
import GuardRoute from './components/GuardRoute';
import GuestRoute from './components/GuestRoute';
import ProfilePage from './pages/ProfilePage';
import AssetsSettings from './pages/AssetsSettings';
import AddAsset from './pages/AddAsset';
import AddInvestor from './pages/AddInvestor';
import Investors from './pages/Investors';
import ClosedAsset from './pages/Investor/ClosedAsset';
import ContactUs from './pages/ContactUs';
import Contributions from './pages/Contributions';
import LegalsMentions from './pages/LegalsMentions';
import AboutUs from './pages/AboutUs';
import Onboarding from './pages/Onboarding';
import FAQ from './pages/FAQ';
import ProfileContactForm from './pages/ProfilePage/ContactForm';
import Invest from './pages/Invest';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Dashboard from './pages/Dashboard';

import { ROUTES } from './constants/routes';

const { COMPANY_ADMIN, SUPER_ADMIN } = USER_ROLES;

const AdminRoutes = () => (
  <MainLayout>
    <Switch>
      <GuardRoute
        path={ROUTES.ADMIN_ROOT}
        component={AdminContainer}
        roles={[SUPER_ADMIN]}
        exact
      />
      <GuardRoute
        path={ROUTES.ADMIN_USERS}
        component={AdminUsers}
        roles={[SUPER_ADMIN]}
        exact
      />
      <GuardRoute
        path={ROUTES.ADMIN_ASSETS}
        component={AdminAssets}
        roles={[SUPER_ADMIN]}
        exact
      />
      <GuestRoute path={ROUTES.ADMIN_LOGIN} component={LoginPage} exact />
      <GuestRoute path={ROUTES.ADMIN_SIGNUP} component={SignupPage} exact />
      <GuestRoute path={ROUTES.SETUP_TWOFA} component={SetupTwoFA} exact />
      <GuardRoute
        path={ROUTES.ASSETS_SETTINGS}
        component={AssetsSettings}
        roles={[COMPANY_ADMIN, SUPER_ADMIN]}
        exact
      />
      <GuardRoute
        path={ROUTES.ADD_ASSET}
        component={AddAsset}
        roles={[COMPANY_ADMIN, SUPER_ADMIN]}
        exact
      />
      <GuardRoute
        path={ROUTES.CONTRIBUTIONS}
        component={Contributions}
        roles={[COMPANY_ADMIN, SUPER_ADMIN]}
        exact
      />
      <GuardRoute
        path={ROUTES.INVESTORS}
        component={Investors}
        roles={[COMPANY_ADMIN, SUPER_ADMIN]}
        exact
      />
      <GuardRoute
        path={ROUTES.ADD_INVESTOR}
        component={AddInvestor}
        roles={[COMPANY_ADMIN, SUPER_ADMIN]}
        exact
      />
      <AuthContainer />
    </Switch>
  </MainLayout>
);

const UserRoutes = () => (
  <Layout>
    <Switch>
      <Route path={ROUTES.HOME} component={Home} exact />
      <GuestRoute path={ROUTES.LOGIN} component={Login} exact />
      {/* TODO: refactor to GuestRoute and remove logic from <Signup /> component */}
      <Route path={ROUTES.SIGNUP} component={Signup} exact />
      <GuestRoute path={ROUTES.RESET} component={Reset} exact />
      <GuestRoute
        path={ROUTES.RESET_PASSWORD}
        component={ResetPasswordPage}
        exact
      />
      <GuestRoute path={ROUTES.CONFIRM_EMAIL} component={ConfirmEmail} exact />

      <Route path={ROUTES.PROJECTS} component={Marketplace} exact />
      <PrivateRoute path={ROUTES.PROJECT} component={Asset} exact />
      <PrivateRoute
        path={ROUTES.CLOSED_PROJECT}
        component={ClosedAsset}
        exact
      />
      <Route path={ROUTES.CONTACT_US} component={ContactUs} exact />
      <Route path={ROUTES.FAQ} component={FAQ} exact />
      <PrivateRoute path={ROUTES.PROFILE} component={ProfilePage} />
      <PrivateRoute path={ROUTES.DASHBOARD} component={Dashboard} exact />
      <PrivateRoute
        path={ROUTES.PROFILE_CONTACT_FORM}
        component={ProfileContactForm}
        exact
      />

      <Route path={ROUTES.LEGALS_MENTIONS} component={LegalsMentions} exact />
      <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} exact />
      <Route path={ROUTES.ABOUT_US} component={AboutUs} exact />
      <PrivateRoute path={ROUTES.ONBOARDING} component={Onboarding} exact />
      <PrivateRoute path={ROUTES.INVEST} component={Invest} exact />
    </Switch>
  </Layout>
);

const Routes = () => (
  <Switch>
    <Route path={ROUTES.ADMIN_ROOT} component={AdminRoutes} />
    <Route component={UserRoutes} />
  </Switch>
);

export default Routes;
