import React, { Component } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Link } from 'react-router-dom';
import { ROUTES } from '~constants/routes';
import { Menu, Icon } from 'antd';
import classnames from './LeftMenu.module.scss';

const menuOptions = [
  { route: ROUTES.ASSETS_SETTINGS, label: 'My assets' },
  { route: ROUTES.CONTRIBUTIONS, label: 'Contributions' },
  { route: ROUTES.INVESTORS, label: 'Investors' },
];

export default class LeftMenu extends Component {
  static propTypes = exact({
    pathname: PropTypes.string.isRequired,
  });

  render() {
    const { pathname } = this.props;

    const selected = menuOptions
      .map(o => o.route)
      .find(r => pathname.indexOf(r) === 0);

    return (
      <div className={classnames.menu}>
        <Menu theme="dark" mode="inline" selectedKeys={[selected]}>
          {menuOptions.map(({ route, label }) => (
            <Menu.Item key={route}>
              <Link to={route}>
                {label}
                <Icon type="right" />
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    );
  }
}
