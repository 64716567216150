import { prefix } from './actions';

export const loading = state => state[prefix].loading;

export const list = state => state[prefix].list;

export const selected = state => state[prefix].selected;

export const investors = state => state[prefix].investors;

export const isInvestorTypeModalOpen = state =>
  state[prefix].isInvestorTypeModalOpen;

export const isMinInvestmentModalOpen = state =>
  state[prefix].isMinInvestmentModalOpen;

export const formValues = state => state.form.contactUsForm?.values;

export const footerFormValues = state => state.form.footerContact?.values || {};

export const assetFormValues = state => state.form.assetContact?.values || {};

export const investmentFormValues = state =>
  state.form.investmentForm?.values || {};

export const subscriptionFormValues = state =>
  state.form.subscriptionForm?.values || {};

export const loginInvestorFormValues = state =>
  state.form.loginInvestorForm?.values || {};

export const signupInvestorFormValues = state =>
  state.form.signupInvestorForm?.values || {};

export const changedPhoneNumber = state =>
  state.form.changePhoneNumber?.values?.phone || '';
