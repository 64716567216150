import React, { Component } from 'react';
import { Layout } from 'antd';
import Group from '../../components/Assets/Group';
import EditModal from '../../components/Assets/EditModal';
import styles from './AssetsSettings.module.scss';

import { STATUSES_TITLES } from '~constants/assets';

const { Content } = Layout;

export default class AssetsSettings extends Component {
  state = {
    visible: false,
  };

  componentDidMount() {
    this.props.loadAssets();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.asset.id && prevProps.asset.id !== this.props.asset.id) {
      this.setState({ visible: true });
    }

    if (prevProps.asset.id && !this.props.asset.id)
      this.setState({ visible: false });
  }

  render() {
    const { list, history } = this.props;
    const { visible } = this.state;

    return (
      <Layout>
        <Content className={styles.content}>
          <EditModal visible={visible} history={history} />
          <h1 className={styles.title}>Here, you can manage all your assets</h1>
          {Object.entries(STATUSES_TITLES).map(([status, label]) => (
            <Group key={status} list={list} status={status} label={label} />
          ))}
        </Content>
      </Layout>
    );
  }
}
