import React, { Component } from 'react';
import { Form, Button, Row, Col, Radio, Select, Tooltip, Icon } from 'antd';
import { TextField, DatePickerField } from 'redux-form-antd';
import { Field } from 'redux-form';
import styles from './AssetSetting.module.scss';
import formStyle from '../Form.module.scss';
import makeField from '../../../WrappedFormElement';

import {
  COUNTRIES,
  ASSET_TYPE_LABELS_RATE,
  ASSET_TYPE_LABELS_PERIOD,
} from '~constants/assets';
import {
  required,
  minLength,
  maxLength,
  notNegativeNumber,
  positiveNumber,
} from '~redux/redux-form-validators';

const RadioGroupElement = makeField(Radio.Group);
const SelectElement = makeField(Select);

const options = [
  { value: 'EUR', label: 'Euro' },
  { value: 'USD', label: 'Dollar' },
  { value: 'CHF', label: 'CHF' },
];

class AssetSetting extends Component {
  state = {
    isCountrySelected: false,
  };

  componentDidMount() {
    const {
      formValues: { country },
    } = this.props;

    if (country) this.setState({ isCountrySelected: true });
  }

  selectCountry = () => {
    this.setState({
      isCountrySelected: true,
    });
  };

  showPlaceholder = value => {
    if (!value[0]) {
      this.setState({
        isCountrySelected: false,
      });
    }
  };

  render() {
    const {
      onCancel,
      handleSubmit,
      valid,
      formValues: { type },
    } = this.props;
    const { isCountrySelected } = this.state;

    return (
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Form.Item className={styles.mInput}>
          <Field
            name="name"
            type="text"
            label="Asset name"
            placeholder="Building name ..."
            component={TextField}
            validate={[required, minLength(3), maxLength(30)]}
          />
        </Form.Item>
        <Form.Item className={styles.mInput}>
          <Field
            name="country"
            type="text"
            label="Country"
            placeholder={isCountrySelected ? '' : 'Select country'}
            component={SelectElement}
            validate={[required]}
            onFocus={this.selectCountry}
            onBlur={this.showPlaceholder}
            showSearch
          >
            {COUNTRIES.map(({ value, label }) => (
              <Select.Option key={value} value={label}>
                {label}
              </Select.Option>
            ))}
          </Field>
        </Form.Item>
        <Form.Item className={styles.mInput}>
          <Field
            name="city"
            type="text"
            label="Location"
            placeholder="City"
            component={TextField}
            validate={[required, minLength(3), maxLength(30)]}
          />
        </Form.Item>
        <Row className={styles.row}>
          <Col className={styles.col}>
            <Form.Item className={styles.mInput}>
              <Field
                name="vestingValue"
                type="text"
                placeholder="100 000"
                label="Total to be raised"
                component={TextField}
                validate={[notNegativeNumber]}
              />
            </Form.Item>

            <Form.Item className={styles.mInput}>
              <Field
                name="minInvestment"
                type="text"
                placeholder="10 000"
                label="Minimum investment"
                component={TextField}
                validate={[required, positiveNumber]}
              />
            </Form.Item>
          </Col>
          <Form.Item className={styles.sInput}>
            <Field
              name="currency"
              label="Currency"
              component={RadioGroupElement}
              validate={[required]}
            >
              {options.map(({ value, label }) => (
                <Radio key={value} className={styles.radio} value={value}>
                  {label}
                </Radio>
              ))}
            </Field>
          </Form.Item>
        </Row>

        <Row className={styles.row}>
          <Form.Item className={styles.sInput}>
            <Field
              name="grossYield"
              type="text"
              placeholder="5"
              label={`${ASSET_TYPE_LABELS_RATE[type]} (%)`}
              component={TextField}
              validate={[required, positiveNumber]}
            />
          </Form.Item>
          <Form.Item className={styles.sInput}>
            <Field
              name="holdingPeriod"
              type="text"
              placeholder="10 months"
              label={ASSET_TYPE_LABELS_PERIOD[type]}
              component={TextField}
              validate={[required, positiveNumber]}
            />
          </Form.Item>
        </Row>
        <Row className={styles.row}>
          <Form.Item
            className={styles.sInput}
            label={
              <Tooltip title="How long the project will be “Opened“">
                Raising period <Icon type="question-circle-o" />
              </Tooltip>
            }
          >
            <Field
              name="duration"
              type="text"
              placeholder="100 days"
              component={TextField}
              validate={[required, positiveNumber]}
            />
          </Form.Item>
          <Form.Item
            className={styles.sInput}
            label={
              <Tooltip title="The date that the legal contractual clauses will be activated">
                Value date <Icon type="question-circle-o" />
              </Tooltip>
            }
          >
            <Field
              name="valueDate"
              type="date"
              placeholder="2021-12-31"
              component={DatePickerField}
              validate={[required]}
            />
          </Form.Item>
        </Row>

        <Row className={formStyle.actions}>
          <Button onClick={onCancel} className={formStyle.back}>
            go back
          </Button>
          <Button
            className={formStyle.next}
            htmlType="submit"
            disabled={!valid}
          >
            continue
          </Button>
        </Row>
      </Form>
    );
  }
}

export default AssetSetting;
