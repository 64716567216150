import React, { Component } from 'react';
import { Tabs } from 'antd';

import { faqData } from './Sections/Sections';

import s from './FAQ.module.scss';

const { TabPane } = Tabs;

// TODO: perhaps the whole FAQ directory should be removed, since it's not on the designs

export default class FAQ extends Component {
  state = {
    width: window.innerWidth,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => this.setState({ width: window.innerWidth });

  render() {
    const { width } = this.state;

    return (
      <div className={s.container}>
        <div className={s.title}>FAQ</div>
        <div className={s.content}>
          <div className={s.divider} />
          <div className={s.subtitle}>
            You will certainly find below the answers to the questions that you
            ask yourself. If this is not the case, do not hesitate to contact us
            directly.
          </div>

          <div className={s.tabs}>
            <Tabs
              defaultActiveKey="1"
              tabPosition={width > 600 ? 'left' : 'top'}
            >
              {faqData.map(({ title, content }) => (
                <TabPane tab={title} key={title}>
                  {content}
                </TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}
