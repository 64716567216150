import { reduxForm } from 'redux-form';

import BankInfo from './BankInfo';

const formConect = reduxForm({
  form: 'assetsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default formConect(BankInfo);
