import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Tabs } from 'antd';
import moment from 'moment';
import {
  TIMEFRAMES,
  PERIODS,
  TIMEFRAMES_SHORTNAME,
} from '../../../constants/chart';

import ss from './Chart.module.scss';

const { TabPane } = Tabs;

const Chart = ({ data, getHistory }) => {
  const [timeframe, setTimeframe] = useState(TIMEFRAMES.MONTH_1);

  useEffect(() => {
    getHistory({ timeframe, period: PERIODS[timeframe] });
  }, [timeframe, getHistory]);

  return (
    <div className={ss.container}>
      <div className={ss.controls}>
        <div className={ss.header}>Price Charts</div>
        <Tabs
          className={ss.tabs}
          defaultActiveKey={timeframe}
          onChange={timeframe => setTimeframe(timeframe)}
        >
          {Object.values(TIMEFRAMES).map(timeframe => (
            <TabPane
              className={ss.tab}
              tab={TIMEFRAMES_SHORTNAME[timeframe]}
              key={timeframe}
            />
          ))}
        </Tabs>
      </div>
      <Line
        data={{
          labels: data.map(({ dateTime }) => moment(dateTime).format('MMM DD')),
          datasets: [
            {
              fill: true,
              lineTension: 0,
              borderJoinStyle: 'miter',
              backgroundColor: 'rgba(0,0,0,0.1)',
              borderColor: '#65686B',
              borderWidth: 2,
              data: data.map(({ value }) => value),
              cubicInterpolationMode: 'monotone',

              pointBorderColor: 'rgba(0,0,0,0)',
              pointBackgroundColor: 'rgba(0,0,0,0)',
              pointBorderWidth: 1,
              pointHoverRadius: 3,
              pointHoverBackgroundColor: 'rgba(0,0,0,1)',
              pointHoverBorderColor: 'rgba(0,0,0,1)',
              pointHoverBorderWidth: 1,
              pointRadius: 3,
              label: 'Daphne Technology',
            },
          ],
        }}
        width={840}
        height={220}
        options={{
          scales: {
            xAxes: [
              {
                offset: true,
                gridLines: {
                  drawOnChartArea: true,
                },
              },
            ],
            yAxes: [
              {
                position: 'right',
                offset: true,
                gridLines: {
                  drawOnChartArea: true,
                },
              },
            ],
          },
          animation: true,
        }}
      />
    </div>
  );
};

export default Chart;
