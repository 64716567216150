import React, { Component } from 'react';
import { Button, Row } from 'antd';

import styles from './ThankYouPage.module.scss';
import formStyle from '../../Assets/Form/Form.module.scss';

class ThankYouPage extends Component {
  render() {
    const { onSubmit } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.body}>
          <p>We sent you a copy of the documents you just agreed to.</p>
          <p>
            Don’t hesitate to get in touch with us if you have any questions.
          </p>
        </div>
        <Row className={styles.actions}>
          <Button className={formStyle.submit} onClick={onSubmit}>
            FINISH INVESTMENT
          </Button>
        </Row>
      </div>
    );
  }
}

export default ThankYouPage;
