import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { compose } from '~utils';
import { createStructuredSelector } from 'reselect';

import {
  profile,
  ibansUsed,
  uniqueIbans,
  financialInfoFormValues,
} from '~redux/profile/selectors';
import { updateUser } from '~redux/profile/actions';

import FinancialInfo from './FinancialInfo';

const actions = { onFormReset: reset, updateUser };

const selectors = createStructuredSelector({
  profile,
  ibansUsed,
  uniqueIbans,
  financialInfoFormValues,
});

const formConect = reduxForm({
  form: 'changeFinancialInfo',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
});

const reduxConect = connect(
  selectors,
  actions,
);

export default compose(
  reduxConect,
  formConect,
)(FinancialInfo);
