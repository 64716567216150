import React, { Component } from 'react';
import { Spin } from 'antd';
import FileUpload from '../../../FileUpload';
import styles from './AssetFileUpload.module.scss';
import AssetImages from './components/AssetImages';
import AssetDocuments from './components/AssetDocuments';
import { MAX_FILES_UPLOAD_NUMBER } from '~constants/assets';

export default class AssetFileUpload extends Component {
  state = {
    num: 1,
    uid: null,
    inputs: {},
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.uploadedFileUid !== this.props.uploadedFileUid) {
      this.setState({ uid: this.props.uploadedFileUid });
    }

    if (
      prevProps.isFileRemoved !== this.props.isFileRemoved &&
      this.props.isFileRemoved
    ) {
      this.setState(prevState => ({
        num: prevState.num - 1 || 1, // remove upload element until the las one left
        uid: null,
      }));
    }
  }

  handleClick = () => {
    const { num } = this.state;

    if (num < MAX_FILES_UPLOAD_NUMBER) {
      this.setState(prevState => ({
        num: prevState.num + 1, // add upload element
        uid: null,
      }));
    }
  };

  onInputChange = ({ target }) => {
    if (!target.name) return;

    const inputs = {};

    inputs[target.name] = target.value;
    this.setState(prevState => ({
      ...prevState,
      inputs: { ...prevState.inputs, ...inputs },
    }));
  };

  render() {
    const { loading } = this.props;

    const { num, uid } = this.state;

    return (
      <Spin spinning={loading}>
        <div className={styles.form}>
          <h4 className={styles.label}>Upload a company logo</h4>
          <p className={styles.subtitle}>.jpg, .jpeg, .png / max-size 2MB</p>
          <FileUpload name="logoImage" />

          <h4 className={styles.label}>Upload a cover picture</h4>
          <p className={styles.subtitle}>.jpg, .jpeg, .png / max-size 2MB</p>
          <FileUpload name="coverImage" />

          <h4 className={styles.label}>
            Upload pictures of the asset (up to {MAX_FILES_UPLOAD_NUMBER}{' '}
            pictures)
          </h4>
          <p className={styles.subtitle}>.jpg, .jpeg, .png / max size 2MB</p>
          <AssetImages num={num} uid={uid} onInputChange={this.onInputChange} />

          <h4 className={styles.label}>
            Upload asset documents (up to {MAX_FILES_UPLOAD_NUMBER} files)
          </h4>
          <p className={styles.subtitle}>.pdf / max size 20MB</p>
          <AssetDocuments
            num={num}
            uid={uid}
            onInputChange={this.onInputChange}
          />

          <h4 className={styles.label}>
            Upload short video presentation of the asset
          </h4>
          <p className={styles.subtitle}>.webm, .mp4 / max-size 96MB</p>
          <FileUpload name="assetVideo" />

          <h4 className={styles.label}>Upload Series Memorandum</h4>
          <p className={styles.subtitle}>.pdf / max-size 20MB</p>
          <FileUpload name="assetMemorandum" />

          <h4 className={styles.label}>Upload Subscription Form</h4>
          <p className={styles.subtitle}>.pdf / max-size 20MB</p>
          <FileUpload name="assetSubscription" />
        </div>
      </Spin>
    );
  }
}
