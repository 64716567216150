import { createSelector } from 'reselect';
import { prefix } from './actions';

import { investmentFormValues } from '../users/selectors';

import { asset, assetContributionData } from '../assets/selectors';
import { userContributionData, investorData } from '../profile/selectors';

export const loading = state => state[prefix].loading;
export const list = state => state[prefix].list || [];

export const formValues = state => {
  const form = state.form.contributionCreationForm;

  if (form) return state.form.contributionCreationForm.values;
};

export const userInvestedAssets = createSelector(
  list,
  list => [...new Set(list.map(contribution => contribution.AssetId))],
);

export const contributionData = createSelector(
  investmentFormValues,
  asset,
  assetContributionData,
  userContributionData,
  (formValues, asset, assetContribution, userContribution) => {
    const { amount = 0, iban } = formValues;
    const { currency, tokenName } = asset;

    return {
      ...assetContribution,
      ...userContribution,
      amount,
      price: `${currency} ${amount.toLocaleString()}`,
      iban,
      // TODO: company name instead of token name?
      tokenName,
    };
  },
);

export const getInvestorData = createSelector(
  investorData,
  asset,
  (investorData, asset) => {
    const { id: AssetId } = asset;

    return {
      ...investorData,
      AssetId,
    };
  },
);
