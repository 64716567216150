import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { compose } from '~utils';

import { updateAssetFields, removeSelectedAsset } from '~redux/assets/actions';
import { formValues, asset } from '~redux/assets/selectors';

import AssetInformation from './AssetInformation';

const selector = createStructuredSelector({
  formValues,
  asset,
});

const actions = {
  updateAssetFields,
  removeSelectedAsset,
};

const reduxConect = connect(
  selector,
  actions,
);

const formConect = reduxForm({
  form: 'assetsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});
export default compose(
  reduxConect,
  formConect,
)(AssetInformation);
