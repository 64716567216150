import React from 'react';
import Helmet from 'react-helmet';

const HelmetWrapper = ({ title, meta: { name, content } = {} }) => {
  return (
    <>
      <Helmet titleTemplate="%s - Eltenemo" defaultTitle="Eltenemo">
        <title>{title}</title>
        {name && <meta name={name} content={content} />}
      </Helmet>
    </>
  );
};

export default HelmetWrapper;
