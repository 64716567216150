import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Marketplace from './Marketplace';

import { allAssets } from '~redux/assets/actions';
import { updateUser, sendApplicantRequest } from '~redux/profile/actions';
import { loadContributions } from '~redux/contributions/actions';
import { toggleInvestorTypeModal } from '~redux/users/actions';

import { assetsByTypeSelector } from '~redux/assets/selectors';
import {
  isAuthenticated,
  status,
  profile,
  loading,
} from '~redux/profile/selectors';

import { userInvestedAssets } from '~redux/contributions/selectors';

const selector = createStructuredSelector({
  assets: assetsByTypeSelector,
  isAuthenticated,
  status,
  profile,
  userInvestedAssets,
  loading,
});

const actions = {
  allAssets,
  updateUser,
  sendApplicantRequest,
  loadContributions,
  toggleInvestorTypeModal,
};

export default connect(
  selector,
  actions,
)(Marketplace);
