import React from 'react';

import { Link } from 'react-router-dom';

import { ROUTES } from '~constants/routes';

import Stepper from '../../../../components/Stepper';
import { SIGNUP_STEPS, STEP_INFO } from '../../../../constants/users';

import styles from './RightForm.module.scss';
import StepperForm from '../StepperForm';

class SignupForm extends React.Component {
  state = { step: 0 };

  handleNextClick = () => {
    const { step } = this.state;

    if (step < SIGNUP_STEPS.INVESTMENT) this.setState({ step: step + 1 });
  };

  handlePrevClick = () => {
    const { step } = this.state;

    if (step > SIGNUP_STEPS.PERSONAL) this.setState({ step: step - 1 });
  };

  handleAddClick = async () => {
    const { handleSubmit, showModal } = this.props;

    const { error } = await handleSubmit();

    if (!error) showModal();
  };

  render() {
    const { step } = this.state;

    return (
      <>
        <h4 className={styles.header}>SIGN UP FOR FREE</h4>
        <div className={styles.underline} />

        <Stepper steps={STEP_INFO} step={step} extraClass="signup" />
        <StepperForm
          onSubmit={this.handleAddClick}
          className={styles.form}
          step={step}
          onNext={this.handleNextClick}
          onPrev={this.handlePrevClick}
        />

        <div className={styles.footer}>
          <div className={styles.divider} />
          <div>Already have an account?</div>
          <Link to={ROUTES.LOGIN}>Login</Link>
        </div>
      </>
    );
  }
}

export default SignupForm;
