import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import ResetPasswordPage from './ResetPasswordPage';

import { resetPassword, resetSuccessState } from '~redux/profile/actions';
import { isPasswordChanged, code, loading } from '~redux/profile/selectors';

const selector = createStructuredSelector({
  code,
  loading,
  isPasswordChanged,
});

const actions = {
  onFormReset: reset,
  onSubmit: resetPassword,
  onStateReset: resetSuccessState,
};

export default connect(
  selector,
  actions,
)(ResetPasswordPage);
