import React from 'react';
import { Redirect } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';

class GuardRoute extends React.PureComponent {
  render() {
    const {
      role,
      roles = [],
      redirectLoggedInUserUrl,
      component: Component,
      ...rest
    } = this.props;

    const GuardComponent = props => {
      if (roles.includes(role)) return <Component {...props} />;
      return (
        <Redirect
          to={{
            pathname: redirectLoggedInUserUrl,
            state: { from: props.location },
          }}
        />
      );
    };

    return <PrivateRoute component={GuardComponent} {...rest} />;
  }
}

export default GuardRoute;
