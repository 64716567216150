import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import InvestorTypeModal from './InvestorTypeModal';

import {
  toggleInvestorTypeModal,
  toggleMinInvestmentModal,
} from '~redux/users/actions';
import { updateUser } from '~redux/profile/actions';
import { isInvestorTypeModalOpen } from '~redux/users/selectors';
import { profile } from '~redux/profile/selectors';

const selector = createStructuredSelector({
  isInvestorTypeModalOpen,
  profile,
});

const actions = {
  toggleInvestorTypeModal,
  toggleMinInvestmentModal,
  updateUser,
};

export default connect(
  selector,
  actions,
)(InvestorTypeModal);
