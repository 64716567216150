import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      default:
        return state;
    }
  },
  list(state = [], action) {
    switch (action.type) {
      case actions.PUT_NOTIFICATION: {
        return [...state, action.payload.notification];
      }

      case actions.REMOVE_NOTIFICATION: {
        const { id } = action.payload;

        return state.filter(i => i._id !== id);
      }

      default:
        return state;
    }
  },
});
