import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { ROUTES } from '~constants/routes';

export default class AdminContainer extends Component {
  render() {
    return (
      <div>
        <Link to={ROUTES.ADMIN_USERS}>
          <Button>Users</Button>
        </Link>
        <Link to={ROUTES.ADMIN_ASSETS}>
          <Button>Assets</Button>
        </Link>
      </div>
    );
  }
}
