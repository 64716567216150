export const prefix = 'profile';

export const PROFILE_REQUEST = `${prefix}/PROFILE_REQUEST`;
export const PROFILE_SUCCESS = `${prefix}/PROFILE_SUCCESS`;
export const PROFILE_FAILURE = `${prefix}/PROFILE_FAILURE`;

export const loadProfile = () => ({
  types: [PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_FAILURE],
  promise: api => api.auth.profile(),
});

export const SETUP_TWOFA_REQUEST = `${prefix}/SETUP_TWOFA_REQUEST`;
export const SETUP_TWOFA_SUCCESS = `${prefix}/SETUP_TWOFA_SUCCESS`;
export const SETUP_TWOFA_FAILURE = `${prefix}/SETUP_TWOFA_FAILURE`;

export const setupTwoFA = () => ({
  types: [SETUP_TWOFA_REQUEST, SETUP_TWOFA_SUCCESS, SETUP_TWOFA_FAILURE],
  promise: api => api.auth.setupTwoFA(),
});

export const ACTIVATE_TWOFA_REQUEST = `${prefix}/ACTIVATE_TWOFA_REQUEST`;
export const ACTIVATE_TWOFA_SUCCESS = `${prefix}/ACTIVATE_TWOFA_SUCCESS`;
export const ACTIVATE_TWOFA_FAILURE = `${prefix}/ACTIVATE_TWOFA_FAILURE`;

export const activateTwoFA = ({ code, shouldKeepSignedIn }) => ({
  types: [
    ACTIVATE_TWOFA_REQUEST,
    ACTIVATE_TWOFA_SUCCESS,
    ACTIVATE_TWOFA_FAILURE,
  ],
  promise: api => api.auth.activateTwoFA(code, shouldKeepSignedIn),
});

export const CHECK_TWOFA_REQUEST = `${prefix}/CHECK_TWOFA_REQUEST`;
export const CHECK_TWOFA_SUCCESS = `${prefix}/CHECK_TWOFA_SUCCESS`;
export const CHECK_TWOFA_FAILURE = `${prefix}/CHECK_TWOFA_FAILURE`;

export const checkTwoFA = ({ code, shouldKeepSignedIn }) => ({
  types: [CHECK_TWOFA_REQUEST, CHECK_TWOFA_SUCCESS, CHECK_TWOFA_FAILURE],
  promise: api => api.auth.checkTwoFA(code, shouldKeepSignedIn),
});

export const RESET_TWOFA_REQUEST = `${prefix}/RESET_TWOFA_REQUEST`;
export const RESET_TWOFA_SUCCESS = `${prefix}/RESET_TWOFA_SUCCESS`;
export const RESET_TWOFA_FAILURE = `${prefix}/RESET_TWOFA_FAILURE`;

export const resetTwoFA = () => ({
  types: [RESET_TWOFA_REQUEST, RESET_TWOFA_SUCCESS, RESET_TWOFA_FAILURE],
  promise: api => api.auth.resetTwoFA(),
});

export const LOGIN_REQUEST = `${prefix}/LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${prefix}/LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${prefix}/LOGIN_FAILURE`;

export const login = ({ email, password, shouldKeepSignedIn }) => ({
  types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
  promise: api => api.auth.login(email, password, shouldKeepSignedIn),
});

export const SIGNUP_REQUEST = `${prefix}/SIGNUP_REQUEST`;
export const SIGNUP_SUCCESS = `${prefix}/SIGNUP_SUCCESS`;
export const SIGNUP_FAILURE = `${prefix}/SIGNUP_FAILURE`;
export const RESET_SUCCESS_STATE = `${prefix}/RESET_SUCCESS_STATE`;

export const signup = data => ({
  types: [SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE],
  promise: api => api.auth.signup(data),
});

export const resetSuccessState = () => ({
  type: RESET_SUCCESS_STATE,
});

export const LOGOUT_REQUEST = `${prefix}/LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${prefix}/LOGOUT_SUCCESS`;
export const LOGOUT_FAILURE = `${prefix}/LOGOUT_FAILURE`;

export const logout = () => ({
  types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
  promise: api => api.auth.logout(),
});

export const CONFIRM_REQUEST = `${prefix}/CONFIRM_REQUEST`;
export const CONFIRM_SUCCESS = `${prefix}/CONFIRM_SUCCESS`;
export const CONFIRM_FAILURE = `${prefix}/CONFIRM_FAILURE`;

export const confirm = hash => ({
  types: [CONFIRM_REQUEST, CONFIRM_SUCCESS, CONFIRM_FAILURE],
  promise: api => api.auth.confirm(hash),
});

export const PASSWORD_RECOVERY_REQUEST = `${prefix}/PASSWORD_RECOVERY_REQUEST`;
export const PASSWORD_RECOVERY_SUCCESS = `${prefix}/PASSWORD_RECOVERY_SUCCESS`;
export const PASSWORD_RECOVERY_FAILURE = `${prefix}/PASSWORD_RECOVERY_FAILURE`;

export const passwordRecovery = ({ email }) => ({
  types: [
    PASSWORD_RECOVERY_REQUEST,
    PASSWORD_RECOVERY_SUCCESS,
    PASSWORD_RECOVERY_FAILURE,
  ],
  promise: api => api.auth.resetPassword(email),
});

export const RESET_PASSWORD_REQUEST = `${prefix}/RESET_PASSWORD_REQUEST`;
export const RESET_PASSWORD_SUCCESS = `${prefix}/RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_FAILURE = `${prefix}/RESET_PASSWORD_FAILURE`;

export const resetPassword = ({ code, password, confirmation_password }) => ({
  types: [
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
  ],
  promise: api => api.auth.setPassword(code, password, confirmation_password),
});

export const resetPasswordByEmail = ({
  email,
  password,
  confirmation_password,
}) => ({
  types: [
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
  ],
  promise: api =>
    api.auth.setPasswordByEmail(email, password, confirmation_password),
});

export const CHANGE_PHONE_REQUEST = `${prefix}/CHANGE_PHONE_REQUEST`;
export const CHANGE_PHONE_SUCCESS = `${prefix}/CHANGE_PHONE_SUCCESS`;
export const CHANGE_PHONE_FAILURE = `${prefix}/CHANGE_PHONE_FAILURE`;

export const changePhone = ({ phone, email }) => ({
  types: [CHANGE_PHONE_REQUEST, CHANGE_PHONE_SUCCESS, CHANGE_PHONE_FAILURE],
  promise: api => api.auth.changePhoneNumber(email, phone),
});

export const CLOSE_ACCOUNT_REQUEST = `${prefix}/CLOSE_ACCOUNT_REQUEST`;
export const CLOSE_ACCOUNT_SUCCESS = `${prefix}/CLOSE_ACCOUNT_SUCCESS`;
export const CLOSE_ACCOUNT_FAILURE = `${prefix}/CLOSE_ACCOUNT_FAILURE`;

export const closeAccount = email => ({
  types: [CLOSE_ACCOUNT_REQUEST, CLOSE_ACCOUNT_SUCCESS, CLOSE_ACCOUNT_FAILURE],
  promise: api => api.auth.closeAccount(email),
});

export const SUMSUB_APPLICANT_REQUEST = `${prefix}/SUMSUB_APPLICANT_REQUEST`;
export const SUMSUB_APPLICANT_SUCCESS = `${prefix}/SUMSUB_APPLICANT_SUCCESS`;
export const SUMSUB_APPLICANT_FAILURE = `${prefix}/SUMSUB_APPLICANT_FAILURE`;

export const sendApplicantRequest = data => ({
  types: [
    SUMSUB_APPLICANT_REQUEST,
    SUMSUB_APPLICANT_SUCCESS,
    SUMSUB_APPLICANT_FAILURE,
  ],
  promise: api => api.users.sendApplicantRequest(data),
});

export const SUMSUB_ACCESS_TOKEN_REQUEST = `${prefix}/SUMSUB_ACCESS_TOKEN_REQUEST`;
export const SUMSUB_ACCESS_TOKEN_SUCCESS = `${prefix}/SUMSUB_ACCESS_TOKEN_SUCCESS`;
export const SUMSUB_ACCESS_TOKEN_FAILURE = `${prefix}/SUMSUB_ACCESS_TOKEN_FAILURE`;

export const getAccessToken = data => ({
  types: [
    SUMSUB_ACCESS_TOKEN_REQUEST,
    SUMSUB_ACCESS_TOKEN_SUCCESS,
    SUMSUB_ACCESS_TOKEN_FAILURE,
  ],
  promise: api => api.users.getAccessToken(data),
});

export const UPDATE_USER_REQUEST = `${prefix}/UPDATE_USER_REQUEST`;
export const UPDATE_USER_SUCCESS = `${prefix}/UPDATE_USER_SUCCESS`;
export const UPDATE_USER_FAILURE = `${prefix}/UPDATE_USER_FAILURE`;

export const updateUser = data => ({
  types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
  promise: api => api.users.updateUser(data),
});

export const MARK_READ_REQUEST = `${prefix}/MARK_READ_REQUEST`;
export const MARK_READ_SUCCESS = `${prefix}/MARK_READ_SUCCESS`;
export const MARK_READ_FAILURE = `${prefix}/MARK_READ_FAILURE`;

export const markAsRead = id => ({
  types: [MARK_READ_REQUEST, MARK_READ_SUCCESS, MARK_READ_FAILURE],
  promise: api => api.users.markAsRead(id),
});

export const GET_NOTIFICATIONS_REQUEST = `${prefix}/GET_NOTIFICATIONS_REQUEST`;
export const GET_NOTIFICATIONS_SUCCESS = `${prefix}/GET_NOTIFICATIONS_SUCCESS`;
export const GET_NOTIFICATIONS_FAILURE = `${prefix}/GET_NOTIFICATIONS_FAILURE`;

export const getNotifications = id => ({
  types: [
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILURE,
  ],
  promise: api => api.users.getNotifications(id),
});

export const CAHNGE_INVESTOR_TYPE_REQUEST = `${prefix}/CAHNGE_INVESTOR_TYPE_REQUEST`;
export const CAHNGE_INVESTOR_TYPE_SUCCESS = `${prefix}/CAHNGE_INVESTOR_TYPE_SUCCESS`;
export const CAHNGE_INVESTOR_TYPE_FAILURE = `${prefix}/CAHNGE_INVESTOR_TYPE_FAILURE`;

export const changeInvestorType = id => ({
  types: [
    CAHNGE_INVESTOR_TYPE_REQUEST,
    CAHNGE_INVESTOR_TYPE_SUCCESS,
    CAHNGE_INVESTOR_TYPE_FAILURE,
  ],
  promise: api => api.users.changeInvestorType(id),
});

export const IBANS_CONTRIBUTIONS_REQUEST = `${prefix}/IBANS_CONTRIBUTIONS_REQUEST`;
export const IBANS_CONTRIBUTIONS_SUCCESS = `${prefix}/IBANS_CONTRIBUTIONS_SUCCESS`;
export const IBANS_CONTRIBUTIONS_FAILURE = `${prefix}/IBANS_CONTRIBUTIONS_FAILURE`;

export const getIbans = () => ({
  types: [
    IBANS_CONTRIBUTIONS_REQUEST,
    IBANS_CONTRIBUTIONS_SUCCESS,
    IBANS_CONTRIBUTIONS_FAILURE,
  ],
  promise: api => api.users.getIbans(),
});

export const SUBMIT_REFERRAL_REQUEST = `${prefix}/SUBMIT_REFERRAL_REQUEST`;
export const SUBMIT_REFERRAL_SUCCESS = `${prefix}/SUBMIT_REFERRAL_SUCCESS`;
export const SUBMIT_REFERRAL_FAILURE = `${prefix}/SUBMIT_REFERRAL_FAILURE`;
