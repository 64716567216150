import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import { reduxForm } from 'redux-form';

import { investmentFormValues } from '~redux/users/selectors';
import { asset } from '~redux/assets/selectors';
import { uniqueIbans } from '~redux/profile/selectors';
import { updateUser } from '~redux/profile/actions';

import { compose } from '~utils';

import IBAN from './IBAN';

const selectors = createStructuredSelector({
  asset,
  ibanFromForm: createSelector(
    investmentFormValues,
    values => values.iban,
  ),
  usedIbans: uniqueIbans,
});

const actions = { updateUser };

const reduxConnect = connect(
  selectors,
  actions,
);

const formConect = reduxForm({
  form: 'investmentForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default compose(
  reduxConnect,
  formConect,
)(IBAN);
