import React, { Component } from 'react';
import { Layout, Spin } from 'antd';

import Stepper from '../../components/Stepper';
import Form from '../../components/InvestmentFlow';
import { STEPS, STEP_INFO } from '~constants/invest';
import { ROUTES } from '~constants/routes';
import { USER_STATUS } from '~constants/users';
import { STATUSES } from '~constants/assets';

import styles from './Invest.module.scss';

const { Content } = Layout;

class Invest extends Component {
  state = { step: 0 };

  componentDidMount() {
    const {
      profile: { status },
      history,
    } = this.props;

    if (status !== USER_STATUS.KYC_APPROVED) {
      return history.push(ROUTES.PROJECTS);
    }
  }

  handleNextClick = e => {
    e.preventDefault();
    const { step } = this.state;

    if (step < STEPS.FINISH) this.setState({ step: step + 1 });
  };

  handlePrevClick = () => {
    const { step } = this.state;

    if (step > STEPS.AMOUNT) this.setState({ step: step - 1 });
  };

  handleAddClick = async e => {
    const { step } = this.state;
    const { history } = this.props;

    if (step === STEPS.FINISH) return history.push(ROUTES.PROJECTS);

    const {
      addContribution,
      addInvestor,
      updateAssetFields,
      asset: { id, status, vestingValue, investedAmount },
      contributionData,
      investorData,
    } = this.props;

    const { amount } = contributionData;

    const { success } = await addContribution(contributionData);
    if (!success) return null;

    await addInvestor(investorData);

    if (
      investedAmount + amount >= vestingValue &&
      status !== STATUSES.WAITING_LIST
    ) {
      updateAssetFields({
        id,
        status: STATUSES.WAITING_LIST,
      });
    }

    this.handleNextClick(e);
  };

  render() {
    const { step } = this.state;
    const { loading, contributionsLoading, history } = this.props;

    return (
      <Spin spinning={loading || contributionsLoading} size="large">
        <Layout>
          <Content className={styles.content}>
            <div className={styles.header}>
              <Stepper steps={STEP_INFO} step={step} />
            </div>
            <div className={styles.formWapper}>
              <Form
                step={step}
                onNext={this.handleNextClick}
                onPrev={this.handlePrevClick}
                onSubmit={this.handleAddClick}
                history={history}
              />
            </div>
          </Content>
        </Layout>
      </Spin>
    );
  }
}

export default Invest;
