import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';

import EmailForm from '../AsideForm';
import ss from './Aside.module.scss';
import Contact from '../../../Contact/';
import { ASSET_TYPES_NAMES, ASSET_TYPE_LABELS_RATE } from '~constants/assets';

const Aside = ({ logo, asset, isClosed, rootClass, className }) => {
  const {
    name,
    city,
    country,
    vestingValue,
    currency,
    holdingPeriod,
    minInvestment,
    grossYield,
    type,
    beneficiary,
  } = asset;

  const footerContent = isClosed ? (
    <div className={ss.footer}>
      <p>Be the first to know when a new asset will be for sale.</p>
      <EmailForm source="Asset Page" rootClassName={ss.asideEmailForm} />
    </div>
  ) : (
    <>
      <Contact />
    </>
  );

  return (
    <div className={cn(ss.aside, ss[rootClass], className)}>
      <div className={ss.logo}>
        <img src={logo} alt={name} />
      </div>
      <p className={ss.distributed}>Distributed by {beneficiary}</p>
      <h2>{ASSET_TYPES_NAMES[type]}</h2>
      <div className={ss.main}>
        <div className={ss.address}>
          <div>
            <FontAwesomeIcon className={ss.icon} icon={faMapMarkerAlt} />
            {`${city}, ${country}`}
          </div>
        </div>
        <div className={ss.divider} />
      </div>
      {!isClosed && (
        <div className={ss.body}>
          <div className={ss.item}>
            <span className={ss.vestingValue}>
              <NumberFormat
                value={vestingValue}
                displayType="text"
                thousandSeparator={true}
                prefix={`${currency} `}
              />
            </span>
          </div>
          <div className={ss.item}>
            <span>Minimum investment</span>
            <span className={ss.value}>
              <NumberFormat
                value={minInvestment}
                displayType="text"
                thousandSeparator={true}
                prefix={`${currency} `}
              />
            </span>
          </div>

          <div className={ss.item}>
            <span>{ASSET_TYPE_LABELS_RATE[type]}</span>
            <span className={ss.value}>{grossYield}%</span>
          </div>
          <div className={ss.item}>
            <span>Duration</span>
            <span className={ss.value}>{holdingPeriod} months</span>
          </div>
          <div className={ss.divider} />
        </div>
      )}
      {footerContent}
    </div>
  );
};

export default Aside;
