import React from 'react';
import classes from './Banner.module.scss';

const Banner = ({ img, name }) =>
  img ? (
    <img className={classes.img} src={img} alt={name} />
  ) : (
    <div className={classes.banner} />
  );

export default Banner;
