import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  profile,
  ibansUsed,
  uniqueIbans,
  financialInfoFormValues,
} from '~redux/profile/selectors';
import { updateContribution } from '~redux/contributions/actions';
import { loadProfile } from '~redux/profile/actions';

import Investments from './Investments';

const actions = { updateContribution, loadProfile };

const selectors = createStructuredSelector({
  profile,
  ibansUsed,
  uniqueIbans,
  financialInfoFormValues,
});
export default connect(
  selectors,
  actions,
)(Investments);
