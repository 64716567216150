import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { Carousel, Button } from 'antd';
import cn from 'classnames';

import { ROUTES } from '~constants/routes';
import {
  ASSET_TYPES_NAMES,
  STATUSES,
  STATUSES_TITLES,
  ASSET_TYPE_LABELS_RATE,
  ASSET_TYPE_LABELS_PERIOD,
} from '~constants/assets';

import formStyle from '../../Assets/Form/Form.module.scss';
import ss from './Carousel.module.scss';

import SlickNextArrow from './SlickNextArrow';
import SlickPrevArrow from './SlickPrevArrow';

import bgImg from '~image-assets/home/bg.jpg';
import mainImg from '~image-assets/marketplace/asset-cover.jpg';

export default class Home extends Component {
  state = {
    dots: {
      top: 0,
      left: 0,
    },
    navAreasWidth: 0,
    slickPrev: null,
    slickNext: null,
  };

  componentDidMount() {
    this.dimensionsHandler();

    setTimeout(() => {
      this.getCenterSlidePosition();
    }, 500);

    window.addEventListener('resize', this.dimensionsHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.dimensionsHandler);
  }

  getCenterSlidePosition = () => {
    const { left } = document.querySelector('.slick-slide.slick-center')
      ? document
          .querySelector('.slick-slide.slick-center')
          .getBoundingClientRect()
      : { left: 0 };

    this.setState({
      navAreasWidth: left,
    });
  };

  dimensionsHandler = () => {
    const width = window.innerWidth,
      center = width / 2;

    this.setState({
      width,
    });

    this.getCenterSlidePosition();

    setTimeout(() => {
      const offsetTop = document.querySelector('.slick-dots')
        ? document.querySelector('.slick-dots').offsetTop
        : 0;

      this.setState({
        dots: {
          top: offsetTop,
          left: center,
        },
      });
    }, 500);
  };

  handleClickableArea = event => () => {
    return event === 'dec'
      ? this.slider.slick.slickPrev()
      : this.slider.slick.slickNext();
  };

  render() {
    const { list } = this.props;
    const { dots, width, navAreasWidth } = this.state,
      paddingOffeset = width * 0.15,
      settings = {
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: paddingOffeset + 'px',
        slidesToShow: 1,
        autoplay: false,
        draggable: true,
        autoplaySpeed: 4000,
        arrows: true,
        speed: 550,
        pauseOnHover: false,
        nextArrow: <SlickNextArrow dots={dots} />,
        prevArrow: <SlickPrevArrow dots={dots} />,
        responsive: [
          {
            breakpoint: 590,
            settings: {
              centerPadding: '20px',
            },
          },
          {
            breakpoint: 480,
            settings: {
              centerMode: false,
              centerPadding: 0,
            },
          },
        ],
      };

    return (
      <div style={{ width }} className={ss.sliderWrap}>
        <div
          style={{ width: navAreasWidth, left: 0 }}
          className={ss.clickableArea}
          onClick={this.handleClickableArea('dec')}
        />
        <Carousel
          ref={sliderWrapper => (this.slider = sliderWrapper)}
          {...settings}
          className={ss.carousel}
        >
          <div className={ss.container}>
            <div className={ss.imageWrapper}>
              <div
                className={ss.image}
                style={{
                  backgroundImage: 'url("' + bgImg + '")',
                }}
              />
              <div className={ss.content}>
                <h3 className={ss.primaryTitle}>Making Impact Happen</h3>
                <div className={ss.underline} />
                <span className={ss.text}>
                  We develop creative financial solutions to empower sustainable
                  technologies
                </span>
                <Link
                  to={ROUTES.CONTACT_US}
                  className={cn(formStyle.submit, ss.button)}
                >
                  GET IN TOUCH
                </Link>
              </div>
            </div>
          </div>

          {list.map(asset => {
            const {
              id,
              name,
              type,
              city,
              vestingValue,
              currency,
              grossYield,
              beneficiary,
              holdingPeriod,
              files: { coverImage = [], assetImages = [] } = {},
              status,
            } = asset;

            const coverImageUrl = coverImage[0]
              ? coverImage[0].url
              : assetImages[0]
              ? assetImages[0].url
              : mainImg;

            const isOpen =
              status === STATUSES.ON_SALE || status === STATUSES.WAITING_LIST;

            const isComingSoon = status === STATUSES.COMING_SOON;

            const isClosed = status === STATUSES.ENDED;

            return (
              <div key={id} className={ss.container}>
                <div className={ss.imageWrapper}>
                  <div
                    className={ss.image}
                    style={{
                      backgroundImage: 'url("' + coverImageUrl + '")',
                    }}
                  />
                  {isComingSoon ? (
                    <span className={ss.cornerButton}>
                      {STATUSES_TITLES[status]}
                    </span>
                  ) : (
                    <div className={ss.cornerButton}>
                      {STATUSES_TITLES[status]}
                    </div>
                  )}
                  <div className={ss.right}>
                    <p className={ss.header}>Distributed by {beneficiary}</p>
                    <div className={ss.content}>
                      <h3 className={ss.secondaryTitle}>
                        {ASSET_TYPES_NAMES[type]}
                      </h3>
                      <h3 className={ss.secondaryTitle}>{name}</h3>
                      <div className={ss.divider} />
                      <p className={ss.address}>
                        <i className="location" />
                        <span>{city}</span>
                      </p>
                      <div className={ss.divider} />
                      <p className={ss.value}>
                        <NumberFormat
                          value={vestingValue}
                          displayType="text"
                          thousandSeparator={true}
                          prefix={`${currency} `}
                        />
                      </p>
                      <div className={ss.divider} />
                      <div className={ss.info}>
                        <div className={ss.expected}>
                          <div>
                            <p>{ASSET_TYPE_LABELS_RATE[type]}</p>{' '}
                            <span>{grossYield}%</span>
                          </div>
                          <div>
                            <p>{ASSET_TYPE_LABELS_PERIOD[type]}</p>{' '}
                            <span>{holdingPeriod} months</span>
                          </div>
                        </div>
                      </div>

                      {isOpen && (
                        <Link
                          className={cn(ss.button, formStyle.next)}
                          to={ROUTES.PROJECT.replace(':id', id)}
                        >
                          GET MORE DETAILS
                        </Link>
                      )}

                      {isComingSoon && (
                        <Button disabled className={ss.button}>
                          COMING SOON
                        </Button>
                      )}

                      {isClosed && (
                        <Button disabled className={ss.button}>
                          CLOSED
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
        <div
          style={{ width: navAreasWidth, right: 0 }}
          className={ss.clickableArea}
          onClick={this.handleClickableArea('inc')}
        />
      </div>
    );
  }
}
