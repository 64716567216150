import React, { useState } from 'react';

import styles from './Brief.module.scss';

import playBTN from '~image-assets/icons/play-button.png';
import pauseBTN from '~image-assets/icons/pause-button.png';

const Brief = ({ title, subtitle, text, hasVideo }) => {
  const [isPaused, setPause] = useState(true);

  const [isBtnVisible, setVisible] = useState(true);

  const handleClick = e => {
    e.preventDefault();

    if (e.target.tagName.toLowerCase() === 'button') e.stopPropagation();

    setPause(!isPaused);

    const video = document.getElementById('video');

    isPaused ? video.play() : video.pause();
  };

  return (
    <div className={styles.brief}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.subtitle}>{text}</div>
      {hasVideo && (
        <div className={styles.video}>
          <video
            id="video"
            onClick={handleClick}
            onMouseEnter={() => setVisible(true)}
            onMouseOut={() => !isPaused && setVisible(false)}
            controls
          >
            <source
              src="http://wecantokenize-test.s3.amazonaws.com/Tokenize_Explainer_Subtitles.mp4"
              type="video/mp4"
            />
          </video>
          <button
            onClick={handleClick}
            className={isBtnVisible ? styles.visible : styles.hidden}
            onMouseEnter={() => setVisible(true)}
          >
            <img src={isPaused ? playBTN : pauseBTN} alt="player button" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Brief;
