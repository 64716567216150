import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Asset from './Asset';
import {
  getAsset,
  toggleOpenFullScreenSlider,
  removeSelectedAsset,
} from '~redux/assets/actions';
import { updateUser, sendApplicantRequest } from '~redux/profile/actions';

import { asset, error, isFullScreenSliderOpen } from '~redux/assets/selectors';
import { isAuthenticated, profile, loading } from '~redux/profile/selectors';

import { isInvestorTypeModalOpen } from '~redux/users/selectors';
import { toggleInvestorTypeModal } from '~redux/users/actions';

import logo from '~image-assets/logos/default-logo.jpg';

const media = () => ({ logo });

const selector = createStructuredSelector({
  asset,
  media,
  isAuthenticated,
  profile,
  loading,
  isInvestorTypeModalOpen,
  error,
  isFullScreenSliderOpen,
});

const actions = {
  getAsset,
  updateUser,
  sendApplicantRequest,
  toggleInvestorTypeModal,
  toggleOpenFullScreenSlider,
  removeSelectedAsset,
};

export default connect(
  selector,
  actions,
)(Asset);
