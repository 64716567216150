import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { compose } from '~utils';

import SetupTwoFA from './SetupTwoFA';

import { setupTwoFA, checkTwoFA, activateTwoFA } from '~redux/profile/actions';

import { twoFA, twoFASetuped, email } from '~redux/profile/selectors';

import { loginInvestorFormValues } from '~redux/users/selectors';

const selector = createStructuredSelector({
  twoFA,
  twoFASetuped,
  email,
  loginInvestorFormValues,
});

const actions = {
  setupTwoFA,
  checkTwoFA,
  onSubmit: (code, _, props) =>
    props.twoFASetuped
      ? checkTwoFA({
          ...code,
          shouldKeepSignedIn: props.loginInvestorFormValues.shouldKeepSignedIn,
        })
      : activateTwoFA({
          ...code,
          shouldKeepSignedIn: props.loginInvestorFormValues.shouldKeepSignedIn,
        }),
};

const reduxConect = connect(
  selector,
  actions,
);

const formConect = reduxForm({
  form: 'setupTwoFA',
});

export default compose(
  reduxConect,
  formConect,
)(SetupTwoFA);
