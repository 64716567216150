import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { sendContactUs } from '~redux/users/actions';
import { formValues } from '~redux/users/selectors';
import { compose } from '~utils';

import ContactUs from './ContactUs';

const selector = createStructuredSelector({
  formValues,
});

const actions = { sendContactUs };

const reduxConnect = connect(
  selector,
  actions,
);

const formConect = reduxForm({
  form: 'contactUsForm',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
});

export default compose(
  reduxConnect,
  formConect,
)(ContactUs);
