import React from 'react';
import { Icon } from 'antd';
import classnames from 'classnames';
import styles from '../../Table/Table.module.scss';

const STATUS_ICON = {
  Pending: 'ellipsis',
  Approved: 'check',
  Declined: 'close',
};

const StatusText = ({ status, number, toggleShowMore }) => {
  const statusTextClass = classnames({
    [styles.number]: true,
    [styles[status]]: true,
  });
  return (
    <div>
      <Icon className={styles.statusIcon} type={STATUS_ICON[status]} />
      <span>{status}</span>
      <span className={statusTextClass} onClick={toggleShowMore}>
        {number}
      </span>
    </div>
  );
};

export default StatusText;
