import React, { Component } from 'react';
import { Form, Button } from 'antd';
import { Field } from 'redux-form';
import PhoneInput from '../../../components/PhoneInput';
import { minLength, required } from '~redux/redux-form-validators';

import formStyle from '../../../components/Assets/Form/Form.module.scss';
import ss from './ChangePhoneNumber.module.scss';

export default class ChangePhoneNumber extends Component {
  componentDidUpdate(prevProps) {
    const { phone, onFormReset } = this.props;

    if (prevProps.phone !== phone && !!phone) {
      onFormReset('changePhoneNumber');
    }
  }

  onSubmit = () => {
    const {
      changePhone,
      user: { email },
      updatedPhone,
    } = this.props;

    changePhone({ phone: updatedPhone, email });
  };

  render() {
    const { handleSubmit, valid, phone } = this.props;

    return (
      <div className={ss.content}>
        <h3>Change phone number</h3>

        <div className={ss.block}>
          <p className={ss.title}>Current phone number</p>
          <p className={ss.phone}>{phone || 'Phone number not provided'}</p>
        </div>

        <Form onSubmit={handleSubmit(this.onSubmit)} className={ss.form}>
          <Form.Item className={ss.input}>
            <Field
              name="phone"
              type="phone"
              label="New phone number"
              placeholder="+412220001133"
              component={PhoneInput}
              validate={[minLength(8), required]}
            />
          </Form.Item>
          <div className={ss.bottom}>
            <Button
              disabled={!valid}
              htmlType="submit"
              className={formStyle.submit}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
