import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { compose } from '~utils';

import {
  loadByOwner,
  addContribution,
  declineContribution,
} from '~redux/contributions/actions';
import { allAssets } from '~redux/assets/actions';
import { listUsers } from '~redux/users/actions';

import { list as projects } from '~redux/assets/selectors';
import { list as users } from '~redux/users/selectors';
import { profile } from '~redux/profile/selectors';
import { list, loading } from '~redux/contributions/selectors';

import Contributions from './Contributions';

const selector = createStructuredSelector({
  list,
  loading,
  projects,
  users,
  profile,
});

const actions = {
  loadByOwner,
  allAssets,
  listUsers,
  addContribution,
  declineContribution,
};

const reduxConect = connect(
  selector,
  actions,
);

const formConect = reduxForm({
  form: 'contributionCreationForm',
});

export default compose(
  reduxConect,
  formConect,
)(Contributions);
