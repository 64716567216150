import React, { Component } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Link } from 'react-router-dom';
import { ROUTES } from '~constants/routes';
import { Layout, Menu, Icon } from 'antd';
import styles from './MainHeader.module.scss';

import logo from '~image-assets/logos/EFIKO_Logo_FINAL.png';

const { Header } = Layout;

export default class MainHeader extends Component {
  static propTypes = exact({
    pathname: PropTypes.string.isRequired,
    needSetupTwoFA: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isSuperAdmin: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
  });

  renderFreeMenu = isLoggedWithoutTwoFA =>
    isLoggedWithoutTwoFA
      ? [
          <Menu.Item key="logout" onClick={this.props.logout}>
            <Icon type="logout" />
          </Menu.Item>,
        ]
      : [
          <Menu.Item key={ROUTES.ADMIN_LOGIN}>
            <Link to={ROUTES.ADMIN_LOGIN}>Login</Link>
          </Menu.Item>,
          <Menu.Item key={ROUTES.ADMIN_SIGNUP}>
            <Link to={ROUTES.ADMIN_SIGNUP}>Sign Up</Link>
          </Menu.Item>,
        ];

  renderLoggedMenu = isSuperAdmin => [
    <Menu.Item key="bell">
      <Icon type="bell" />
    </Menu.Item>,
    <Menu.Item key={ROUTES.ADMIN_ROOT}>
      <Link to={isSuperAdmin ? ROUTES.ADMIN_ROOT : ROUTES.ASSETS_SETTINGS}>
        <Icon type="home" />
      </Link>
    </Menu.Item>,
    <Menu.Item key={ROUTES.PROFILE_INFO}>
      <Link to={ROUTES.PROFILE_INFO}>
        <Icon type="user" />
      </Link>
    </Menu.Item>,
    isSuperAdmin && [
      <Menu.Item key={ROUTES.ADMIN_ASSETS}>
        <Link to={ROUTES.ADMIN_ASSETS}>
          <Icon type="project" />
        </Link>
      </Menu.Item>,
      <Menu.Item key={ROUTES.ADMIN_USERS}>
        <Link to={ROUTES.ADMIN_USERS}>
          <Icon type="usergroup-add" />
        </Link>
      </Menu.Item>,
    ],
    <Menu.Item key="logout" onClick={this.props.logout}>
      <Icon type="logout" />
    </Menu.Item>,
  ];

  render() {
    const {
      pathname,
      needSetupTwoFA,
      isAuthenticated,
      isSuperAdmin,
    } = this.props;
    return (
      <Header className={styles.header}>
        <Link className={styles.logo} to={ROUTES.HOME}>
          <img src={logo} alt="Logo" />
        </Link>

        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[pathname]}
          className={styles.menu}
        >
          {isAuthenticated && !needSetupTwoFA
            ? this.renderLoggedMenu(isSuperAdmin)
            : this.renderFreeMenu(needSetupTwoFA)}
        </Menu>
      </Header>
    );
  }
}
