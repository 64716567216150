import React, { Component } from 'react';
import { Button, Modal } from 'antd';

import ss from '../../Contact.module.scss';
import cn from 'classnames';
import formStyle from '../../../../Assets/Form/Form.module.scss';

export default class Active extends Component {
  state = {
    isModalOpen: false,
  };

  toggleModdal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  render() {
    const { toggleInvestorTypeModal } = this.props;
    const { isModalOpen } = this.state;

    return (
      <>
        <Modal
          footer={null}
          visible={isModalOpen}
          onCancel={this.toggleModdal}
          className={ss.modal}
        >
          <div className={ss.modalContainer}>
            <h3 className={ss.title}>Qualified investors</h3>

            <p className={ss.text}>
              Prospective purchasers of Tokenized Notes should have sufficient
              knowledge and experience in financial and business matters, and
              access to, and knowledge of, appropriate analytical resources, to
              evaluate the information contained in this Series Memorandum and
              the merits and risks of investing in the Tokenized Notes in the
              context of their financial position and circumstances.
            </p>
          </div>

          <div className={ss.footer}>
            <Button className={formStyle.next} onClick={this.toggleModdal}>
              OK
            </Button>
          </div>
        </Modal>

        <h1 className={ss.title}>
          <span>Are you a qualified investor? </span>
          <span onClick={this.toggleModdal} className={ss.icon}>
            i
          </span>
        </h1>
        <Button
          className={cn(ss.button, formStyle.submit)}
          htmlType="button"
          onClick={() => toggleInvestorTypeModal(true)}
        >
          Get Whitelisted
        </Button>
      </>
    );
  }
}
