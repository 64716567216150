export const prefix = 'contributions';

export const LOAD_REQUEST = `${prefix}/LOAD_REQUEST`;
export const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`;
export const LOAD_FAILURE = `${prefix}/LOAD_FAILURE`;

export const loadContributions = id => ({
  types: [LOAD_REQUEST, LOAD_SUCCESS, LOAD_FAILURE],
  promise: api => api.contributions.load(id),
});

export const LOAD_BY_OWNER_REQUEST = `${prefix}/LOAD_BY_OWNER_REQUEST`;
export const LOAD_BY_OWNER_SUCCESS = `${prefix}/LOAD_BY_OWNER_SUCCESS`;
export const LOAD_BY_OWNER_FAILURE = `${prefix}/LOAD_BY_OWNER_FAILURE`;

export const loadByOwner = id => ({
  types: [LOAD_BY_OWNER_REQUEST, LOAD_BY_OWNER_SUCCESS, LOAD_BY_OWNER_FAILURE],
  promise: api => api.contributions.loadByOwner(id),
});

export const ADD_REQUEST = `${prefix}/ADD_REQUEST`;
export const ADD_SUCCESS = `${prefix}/ADD_SUCCESS`;
export const ADD_FAILURE = `${prefix}/ADD_FAILURE`;

export const addContribution = data => ({
  types: [ADD_REQUEST, ADD_SUCCESS, ADD_FAILURE],
  promise: api => api.contributions.create(data),
});

export const APPROVE_REQUEST = `${prefix}/APPROVE_REQUEST`;
export const APPROVE_SUCCESS = `${prefix}/APPROVE_SUCCESS`;
export const APPROVE_FAILURE = `${prefix}/APPROVE_FAILURE`;

export const approveContribution = (id, email) => ({
  types: [APPROVE_REQUEST, APPROVE_SUCCESS, APPROVE_FAILURE],
  promise: api => api.contributions.approve(id, email),
});

export const DECLINE_REQUEST = `${prefix}/DECLINE_REQUEST`;
export const DECLINE_SUCCESS = `${prefix}/DECLINE_SUCCESS`;
export const DECLINE_FAILURE = `${prefix}/DECLINE_FAILURE`;

export const declineContribution = (id, email) => ({
  types: [DECLINE_REQUEST, DECLINE_SUCCESS, DECLINE_FAILURE],
  promise: api => api.contributions.decline(id, email),
});

export const UPDATED = `${prefix}/UPDATED`;

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`;

export const updateContribution = data => ({
  types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: api => api.contributions.updateContribution(data),
});

export const SEND_CONTRIBUTION_EMAIL_REQUEST = `${prefix}/SEND_CONTRIBUTION_EMAIL_REQUEST`;
export const SEND_CONTRIBUTION_EMAIL_SUCCESS = `${prefix}/SEND_CONTRIBUTION_EMAIL_SUCCESS`;
export const SEND_CONTRIBUTION_EMAIL_FAILURE = `${prefix}/SEND_CONTRIBUTION_EMAIL_FAILURE`;

export const sendContributionEmail = id => ({
  types: [
    SEND_CONTRIBUTION_EMAIL_REQUEST,
    SEND_CONTRIBUTION_EMAIL_SUCCESS,
    SEND_CONTRIBUTION_EMAIL_FAILURE,
  ],
  promise: api => api.contributions.sendContributionEmail(id),
});
