import Base from './base';

export default class Users extends Base {
  list(query) {
    return this.apiClient.get('users', query);
  }

  show(id) {
    return this.apiClient.get(`users/${id}`);
  }

  grantAdmin(id) {
    return this.apiClient.post(`users/grantAdmin/${id}`);
  }

  createInvestor(data) {
    return this.apiClient.post(`users/investor`, data);
  }

  investorList(query) {
    return this.apiClient.get('users/investor', query);
  }

  createContact(data) {
    return this.apiClient.post(`users/createContact`, data);
  }

  sendContactUs(data) {
    return this.apiClient.post(`users/contactUs`, data);
  }

  sendContactProfile(data) {
    return this.apiClient.post(`users/profile/contactUs`, data);
  }

  sendApplicantRequest(data) {
    return this.apiClient.post('users/onboarding', data);
  }

  getAccessToken({ id }) {
    return this.apiClient.get(`users/onboarding/${id}`);
  }

  updateUser(data) {
    return this.apiClient.put('users', data);
  }

  markAsRead(id) {
    return this.apiClient.patch(`users/notifications/${id}`);
  }

  getNotifications(id) {
    return this.apiClient.patch(`users/notifications/user/${id}`);
  }

  changeInvestorType(id) {
    return this.apiClient.patch(`users/changeInvestorType/${id}`);
  }

  getIbans() {
    return this.apiClient.get(`users/getIbans`);
  }
}
