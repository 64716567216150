import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { pathname } from '~redux/router/selectors';
import { loadProfile, logout } from '~redux/profile/actions';
import {
  needSetupTwoFASelector,
  isCompanyAdmin,
  isSuperAdmin,
  isAuthenticated,
} from '~redux/profile/selectors';

import MainLayout from './MainLayout';

const selector = createStructuredSelector({
  pathname,
  needSetupTwoFA: needSetupTwoFASelector,
  isCompanyAdmin,
  isSuperAdmin,
  isAuthenticated,
});

const actions = {
  init: loadProfile,
  logout,
};

export { MainLayout };

export default connect(
  selector,
  actions,
)(MainLayout);
