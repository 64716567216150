import { connect } from 'react-redux';
import AddInvestorPage from './AddInvestor.jsx';

import { addInvestor } from '~redux/users/actions';

const selectors = null;
const actions = {
  addInvestor,
};

export default connect(
  selectors,
  actions,
)(AddInvestorPage);
