import { createStore, applyMiddleware, compose as simpleCompose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import apiMiddleware from './middlewares/api';
import wsMiddleware from './middlewares/ws';

import createRootReducer from './reducer';
import rootSaga from './sagas';

export default function configureStore(api, history) {
  const compose =
    process.env.NODE_ENV === 'production' ? simpleCompose : composeWithDevTools;

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    createRootReducer(history),
    compose(
      applyMiddleware(
        apiMiddleware(api),
        wsMiddleware(api),
        routerMiddleware(history),
        sagaMiddleware,
      ),
    ),
  );

  sagaMiddleware.run(() => rootSaga(history, api));

  return store;
}
