import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isAuthenticated, loading } from '~redux/profile/selectors';
import AuthContainer from './AuthContainer';

const selector = createStructuredSelector({
  isAuthenticated,
  loading,
});

export default connect(
  selector,
  null,
)(AuthContainer);
