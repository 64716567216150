import React from 'react';
import { Modal, Button } from 'antd';
import cn from 'classnames';

import formStyle from '../../../../../components/Assets/Form/Form.module.scss';
import s from '../../Asset.module.scss';

import { INVESTOR_TYPE_LABELS, SWITCHED_INVESTOR_TYPE } from '~constants/users';

const InvestorTypeModal = ({
  toggleInvestorTypeModal,
  toggleMinInvestmentModal,
  isInvestorTypeModalOpen,
  profile: { investorType },
  updateUser,
}) => {
  const handleClick = () => {
    toggleInvestorTypeModal(false);

    toggleMinInvestmentModal(true);
  };

  const handleCancel = () => {
    toggleInvestorTypeModal(false);
  };

  const handleInvestorTypeSwitch = async () => {
    await updateUser({
      investorType: SWITCHED_INVESTOR_TYPE[investorType],
      notificationMessage: `Your investor type was changed to ${[
        INVESTOR_TYPE_LABELS[SWITCHED_INVESTOR_TYPE[investorType]],
      ]}`,
    });
  };

  return (
    <Modal
      width={540}
      footer={null}
      centered
      closable
      onCancel={handleCancel}
      className={s.modal}
      visible={isInvestorTypeModalOpen}
    >
      <div className={s.wrapper}>
        <h3 className={s.title}>Please, check your investor type!</h3>
        <p className={s.text}>
          You are currently registered as a{' '}
          <strong>{INVESTOR_TYPE_LABELS[investorType]}</strong>
        </p>
        <p className={s.text}>
          You will not be able to change your investor type after your proceed
          with whitelisting
        </p>
        <p className={s.text}>
          Click <strong>PROCEED</strong> if you wish to continue as a{' '}
          {INVESTOR_TYPE_LABELS[investorType]}
        </p>
        <div className={s.buttons}>
          <Button
            onClick={handleInvestorTypeSwitch}
            className={cn(formStyle.back, s.btn)}
          >
            Switch to{' '}
            {[INVESTOR_TYPE_LABELS[SWITCHED_INVESTOR_TYPE[investorType]]]}
          </Button>
          <Button onClick={handleClick} className={cn(formStyle.submit, s.btn)}>
            Proceed
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InvestorTypeModal;
