import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { title, meta } from '~redux/router/selectors';

import Helmet from './HelmetWrapper';

const selector = createStructuredSelector({ title, meta });

export default connect(
  selector,
  null,
)(Helmet);
