import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { removeSelectedAsset, updateAssetFields } from '~redux/assets/actions';
import { asset, formValues } from '~redux/assets/selectors';

import { compose } from '~utils';

import EditModal from './EditModal';

const selectors = createStructuredSelector({
  asset,
  initialValues: asset,
  formValues,
});

const actions = { removeSelectedAsset, updateAssetFields };

const reduxConnect = connect(
  selectors,
  actions,
);

const formConect = reduxForm({
  form: 'assetsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
});

export default compose(
  reduxConnect,
  formConect,
)(EditModal);
