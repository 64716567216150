import React, { Component } from 'react';
import LoginForm from './Form';

import s from './Login.module.scss';

export default class Login extends Component {
  render() {
    const { login } = this.props;

    return (
      <div className={s.container}>
        <h3>Log in</h3>
        <LoginForm onSubmit={login} />
      </div>
    );
  }
}
