export const prefix = 'assets';

export const LOAD_REQUEST = `${prefix}/LOAD_REQUEST`;
export const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`;
export const LOAD_FAILURE = `${prefix}/LOAD_FAILURE`;

export const loadAssets = () => ({
  types: [LOAD_REQUEST, LOAD_SUCCESS, LOAD_FAILURE],
  promise: api => api.assets.load(),
});

export const ADD_REQUEST = `${prefix}/ADD_REQUEST`;
export const ADD_SUCCESS = `${prefix}/ADD_SUCCESS`;
export const ADD_FAILURE = `${prefix}/ADD_FAILURE`;

export const addAsset = data => ({
  types: [ADD_REQUEST, ADD_SUCCESS, ADD_FAILURE],
  promise: api => api.assets.create(data),
});

export const ALL_REQUEST = `${prefix}/ALL_REQUEST`;
export const ALL_SUCCESS = `${prefix}/ALL_SUCCESS`;
export const ALL_FAILURE = `${prefix}/ALL_FAILURE`;

export const allAssets = () => ({
  types: [ALL_REQUEST, ALL_SUCCESS, ALL_FAILURE],
  promise: api => api.assets.all(),
});

export const GET_CAROUSEL_ASSETS_REQUEST = `${prefix}/GET_CAROUSEL_ASSETS_REQUEST`;
export const GET_CAROUSEL_ASSETS_SUCCESS = `${prefix}/GET_CAROUSEL_ASSETS_SUCCESS`;
export const GET_CAROUSEL_ASSETS_FAILURE = `${prefix}/GET_CAROUSEL_ASSETS_FAILURE`;

export const getCarouselAssets = () => ({
  types: [
    GET_CAROUSEL_ASSETS_REQUEST,
    GET_CAROUSEL_ASSETS_SUCCESS,
    GET_CAROUSEL_ASSETS_FAILURE,
  ],
  promise: api => api.assets.getCarouselAssets(),
});

export const APPROVE_REQUEST = `${prefix}/APPROVE_REQUEST`;
export const APPROVE_SUCCESS = `${prefix}/APPROVE_SUCCESS`;
export const APPROVE_FAILURE = `${prefix}/APPROVE_FAILURE`;

export const approveAsset = id => ({
  types: [APPROVE_REQUEST, APPROVE_SUCCESS, APPROVE_FAILURE],
  promise: api => api.assets.approve(id),
});

export const START_SALE_REQUEST = `${prefix}/START_SALE_REQUEST`;
export const START_SALE_SUCCESS = `${prefix}/START_SALE_SUCCESS`;
export const START_SALE_FAILURE = `${prefix}/START_SALE_FAILURE`;

export const startSaleAsset = id => ({
  types: [START_SALE_REQUEST, START_SALE_SUCCESS, START_SALE_FAILURE],
  promise: api => api.assets.startSale(id),
});

export const GET_ASSET_REQUEST = `${prefix}/GET_ASSET_REQUEST`;
export const GET_ASSET_SUCCESS = `${prefix}/GET_ASSET_SUCCESS`;
export const GET_ASSET_FAILURE = `${prefix}/GET_ASSET_FAILURE`;

export const getAsset = id => ({
  types: [GET_ASSET_REQUEST, GET_ASSET_SUCCESS, GET_ASSET_FAILURE],
  promise: api => api.assets.getById(id),
});

export const UPDATED = `${prefix}/UPDATED`;

export const REMOVE_SELECTED_ASSET = `${prefix}/REMOVE_SELECTED_ASSET`;
export const removeSelectedAsset = () => ({
  type: REMOVE_SELECTED_ASSET,
});

export const END_SALE_REQUEST = `${prefix}/END_SALE_REQUEST`;
export const END_SALE_SUCCESS = `${prefix}/END_SALE_SUCCESS`;
export const END_SALE_FAILURE = `${prefix}/END_SALE_FAILURE`;

export const closeSale = data => ({
  types: [END_SALE_REQUEST, END_SALE_SUCCESS, END_SALE_FAILURE],
  promise: api => api.assets.closeSale(data),
});

export const UPLOAD_FILE_REQUEST = `${prefix}/UPLOAD_FILE_REQUEST`;
export const UPLOAD_FILE_SUCCESS = `${prefix}/UPLOAD_FILE_SUCCESS`;
export const UPLOAD_FILE_FAILURE = `${prefix}/UPLOAD_FILE_FAILURE`;

export const uploadAssetFile = ({ file, id, path, uid, name }) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('id', id);
  formData.append('name', name);
  formData.append('path', path);
  formData.append('uid', uid);

  return {
    types: [UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE],
    promise: api => api.assets.uploadAssetFile(formData),
  };
};

export const REMOVE_FILE_REQUEST = `${prefix}/REMOVE_FILE_REQUEST`;
export const REMOVE_FILE_SUCCESS = `${prefix}/REMOVE_FILE_SUCCESS`;
export const REMOVE_FILE_FAILURE = `${prefix}/REMOVE_FILE_FAILURE`;
export const removeAssetFile = data => ({
  types: [REMOVE_FILE_REQUEST, REMOVE_FILE_SUCCESS, REMOVE_FILE_FAILURE],
  promise: api => api.assets.removeAssetFile(data),
});

export const UPDATE_ASSET_REQUEST = `${prefix}/UPDATE_ASSET_REQUEST`;
export const UPDATE_ASSET_SUCCESS = `${prefix}/UPDATE_ASSET_SUCCESS`;
export const UPDATE_ASSET_FAILURE = `${prefix}/UPDATE_ASSET_FAILURE`;

export const updateAssetFields = data => ({
  types: [UPDATE_ASSET_REQUEST, UPDATE_ASSET_SUCCESS, UPDATE_ASSET_FAILURE],
  promise: api => api.assets.updateAsset(data),
});

export const TOGGLE_FULLSCREEN_SLIDER = `${prefix}/TOGGLE_FULLSCREEN_SLIDER`;

export const toggleOpenFullScreenSlider = isOpen => ({
  type: TOGGLE_FULLSCREEN_SLIDER,
  isOpen,
});

export const SET_ACTIVE_SLIDE = `${prefix}/SET_ACTIVE_SLIDE`;

export const setActiveSlide = activeSlide => ({
  type: SET_ACTIVE_SLIDE,
  activeSlide,
});
