import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { ROUTES } from '~constants/routes';

import ss from '../../Contact.module.scss';
import formStyle from '../../../../Assets/Form/Form.module.scss';

const Whitelisted = ({ assetId, isMaximumReached }) => (
  <>
    {isMaximumReached ? (
      <>
        <h1 className={ss.title}>The maximum amount has been reached</h1>
        <Link to={ROUTES.CONTACT_US}>
          <Button className={cn(ss.button, formStyle.submit)} htmlType="button">
            Contact Us
          </Button>
        </Link>
      </>
    ) : (
      <Link to={ROUTES.INVEST.replace(':id', assetId)}>
        <Button className={cn(ss.button, formStyle.submit)} htmlType="button">
          Invest
        </Button>
      </Link>
    )}
  </>
);

export default Whitelisted;
