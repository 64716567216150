import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';

import { formValues } from '~redux/assets/selectors';
import { compose } from '~utils';
import AssetSetting from './AssetSetting';

const selectors = createStructuredSelector({ formValues });

const reduxConnect = connect(
  selectors,
  null,
);

const formConect = reduxForm({
  form: 'assetsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default compose(
  reduxConnect,
  formConect,
)(AssetSetting);
