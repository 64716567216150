import React, { Component } from 'react';

import Form from '../../Investor/Reset/Form';

import s from './ChangePassword.module.scss';

export default class ChangePassword extends Component {
  componentDidMount() {
    const { isPasswordChanged, onFormReset } = this.props;

    if (isPasswordChanged) onFormReset('resetPassword');
  }

  onSubmit = data => {
    const {
      resetPasswordByEmail,
      recoveryPasswordValues: { email },
    } = this.props;

    resetPasswordByEmail({ ...data, email });
  };

  render() {
    return (
      <div className={s.content}>
        <h3>Change password</h3>
        <Form onSubmit={this.onSubmit} buttonText="Submit" />
      </div>
    );
  }
}
