import React from 'react';
import { Spin } from 'antd';
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from '~constants/routes';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
};

class PrivateRoute extends React.PureComponent {
  render() {
    const {
      component: Component,
      isAuthenticated,
      isTwoFANeeded,
      loading,
      ...rest
    } = this.props;

    if (loading) return <Spin size="large" style={style} />;

    if (isAuthenticated && isTwoFANeeded) {
      return (
        <Redirect
          to={{
            pathname: ROUTES.SETUP_TWOFA,
            state: { from: this.props.location },
          }}
        />
      );
    }
    if (isAuthenticated) {
      return <Route component={Component} {...rest} />;
    }

    return (
      <Redirect
        to={{ pathname: ROUTES.LOGIN, state: { from: this.props.location } }}
      />
    );
  }
}

export default PrivateRoute;
