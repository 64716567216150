import React, { Component } from 'react';
import Amount from './Amount';
import Subscription from './Subscription';
import IBAN from './IBAN';
import Transfer from './Transfer';
import ThankYouPage from './ThankYouPage';
import { STEPS, STEP_INFO } from '~constants/invest';

import styles from './Form.module.scss';

const steps = {
  [STEPS.AMOUNT]: Amount,
  [STEPS.SUBSCRIPTION]: Subscription,
  [STEPS.IBAN]: IBAN,
  [STEPS.TRANSFER]: Transfer,
  [STEPS.FINISH]: ThankYouPage,
};

class Form extends Component {
  componentDidMount() {
    const { getAsset } = this.props;
    const assetId = window.location.pathname.split('/').pop();
    getAsset(assetId);
  }

  render() {
    const { step, onNext, onPrev, onSubmit, history } = this.props;
    const Step = steps[step];

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          {`STEP ${step + 1} OF ${Object.values(STEPS).length}`}
        </div>
        <h1 className={styles.title}>{Object.values(STEP_INFO)[step].title}</h1>
        <div className={styles.body}>
          <Step
            onSubmit={step >= STEPS.IBAN ? onSubmit : onNext}
            onCancel={step === STEPS.FINISH ? onSubmit : onPrev}
            history={history}
          />
        </div>
      </div>
    );
  }
}

export default Form;
