import React, { Component } from 'react';
import { Form, Button } from 'antd';
import { Field } from 'redux-form';
import { TextField } from 'redux-form-antd';

import formStyle from '../../../components/Assets/Form/Form.module.scss';
import ss from './Referral.module.scss';

export default class Referral extends Component {
  render() {
    const { handleSubmit, referral } = this.props;

    return (
      <div className={ss.content}>
        <h3>Referred by</h3>

        {referral ? (
          <span className={ss.referral}>{referral}</span>
        ) : (
          <Form onSubmit={handleSubmit} className={ss.form}>
            <Form.Item className={ss.input}>
              <Field
                name="referral"
                type="text"
                label={null}
                placeholder="Fill in the name or referent entity"
                component={TextField}
              />
            </Form.Item>
            <Button htmlType="submit" className={formStyle.next}>
              Submit
            </Button>
          </Form>
        )}
      </div>
    );
  }
}
