import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import {
  signupInvestorFormValues,
  changedPhoneNumber,
} from '~redux/users/selectors';

import PhoneInput from './PhoneInput';

const slectors = createStructuredSelector({
  phone: createSelector(
    signupInvestorFormValues,
    changedPhoneNumber,
    (signupInvestorFormValues, changedPhoneNumber) =>
      signupInvestorFormValues.phone || changedPhoneNumber,
  ),
  changedPhoneNumber,
});

export default connect(
  slectors,
  null,
)(PhoneInput);
