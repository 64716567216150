import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { sendContactProfile } from '~redux/users/actions';
import { loading } from '~redux/users/selectors';
import { profile, profileContactFormValues } from '~redux/profile/selectors';

import { compose } from '~utils';

import ContactForm from './ContactForm';

const selector = createStructuredSelector({
  profileContactFormValues,
  profile,
  loading,
});

const actions = {
  sendContactProfile,
};

const reduxConnect = connect(
  selector,
  actions,
);

const formConect = reduxForm({
  form: 'profileContactForm',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
});

export default compose(
  reduxConnect,
  formConect,
)(ContactForm);
