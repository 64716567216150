import React from 'react';
import SanitizedHTML from 'react-sanitized-html';
import ImageCarousel from '../ImageCarousel';
import {
  ALLOWED_HTML_TAGS,
  ALLOWED_HTML_ATRIBUTES,
} from '~constants/allowedTags';

import s from './Description.module.scss';

const Description = ({ asset }) => (
  <div className={s.description}>
    <ImageCarousel asset={asset} />
    <div className={s.customHTML}>
      <SanitizedHTML
        html={asset.description}
        allowedTags={ALLOWED_HTML_TAGS}
        allowedAttributes={ALLOWED_HTML_ATRIBUTES}
      />
    </div>
  </div>
);

export default Description;
