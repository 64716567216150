import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { ROUTES } from '~constants/routes';

import ss from '../../Contact.module.scss';
import formStyle from '../../../../Assets/Form/Form.module.scss';

const NotAuthenicated = () => {
  return (
    <>
      <h1 className={ss.title}>Create an investor account</h1>
      <Link to={ROUTES.SIGNUP}>
        <Button className={cn(ss.button, formStyle.submit)} htmlType="button">
          INVEST NOW
        </Button>
      </Link>
    </>
  );
};

export default NotAuthenicated;
