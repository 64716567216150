import React, { Component } from 'react';
import { Form, Button, Row } from 'antd';
import classnames from 'classnames';

import formStyle from '../Form.module.scss';
import styles from './Transfer.module.scss';

const BANK_INFO = [
  'IBAN',
  'BIC_CODE',
  'Bank',
  'Currency',
  'Reference',
  'Beneficiary',
];

class Transfer extends Component {
  handleSubmit = async e => {
    const {
      formValues,
      asset: { id: AssetId, investedAmount, vestingValue },
      counter,
      profile: { id: UserId },
      addContribution,
      addInvestor,
      onSubmit,
      investorData,
      closeSale,
    } = this.props;

    const data = {
      ...formValues,
      // TODO: set the name of asset owner instead of tokenName?
      reference: `tokenName - ${counter}`,
      AssetId,
      UserId,
    };

    await addContribution(data);

    await addInvestor({
      ...investorData,
      project: AssetId,
      userId: UserId,
    });

    if (investedAmount + formValues.amount >= vestingValue) {
      closeSale({ id: AssetId });
    }
    onSubmit(e);
  };

  render() {
    const {
      onCancel,
      valid,
      asset,
      asset: { name, city, country, grossYield, currency, duration },
      formValues: { amount },
      counter,
    } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <h4>You want to invest in:</h4>
          <div className={styles.info}>
            <p className={styles.title}>{name}</p>
            <p className={styles.location}>
              {city}, {country}
            </p>
            <div className={styles.divider} />
            <div className={styles.estimation}>
              <p>Estimated IRR: {grossYield}%</p>
              <p>Duration: {duration} years</p>
            </div>
          </div>
          <div className={styles.block}>
            <h4>For an amount of:</h4>
            <div className={styles.financial}>
              <p>{currency}</p>
              <p>{amount}</p>
            </div>
          </div>
          <div className={styles.block}>
            <h4>Amount of tokens you will receive:</h4>
            <div className={styles.financial}>
              <p>GMG LX</p>
              <p>{amount}</p>
            </div>
            <p className={styles.transfer}>
              Transfer should arrive by <strong>Apr 26</strong>
            </p>
          </div>
        </div>
        <Form>
          <div className={styles.form}>
            {BANK_INFO.map(el => (
              <div className={styles.row} key={el}>
                <p className={styles.label}>{el}</p>
                <p className={styles.data}>
                  {el !== 'Reference'
                    ? asset[el.toLowerCase()]
                    : // TODO: set the name of asset owner instead of tokenName?
                      `tokenName - ${counter}`}
                </p>
              </div>
            ))}
          </div>
        </Form>
        <Row className={styles.actions}>
          <Button
            onClick={onCancel}
            className={classnames(formStyle.back, styles.btn)}
          >
            Go back
          </Button>
          <Button
            disabled={!valid}
            className={classnames(formStyle.submit, styles.btn)}
            onClick={this.handleSubmit}
          >
            FINISH INVESTMENT
          </Button>
        </Row>
      </div>
    );
  }
}

export default Transfer;
