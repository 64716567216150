import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import { ROUTES } from '~constants/routes';
import { LINK_LABELS } from '~constants/labels';
import classes from './MainFooter.module.scss';

const { Footer } = Layout;

const getLinks = isAuthenticated => {
  const links = [
    <li key={LINK_LABELS.HOME}>
      <Link to={ROUTES.HOME}>{LINK_LABELS.HOME}</Link>
    </li>,
    <li key={LINK_LABELS.ABOUT_US}>
      <Link to={ROUTES.ABOUT_US}>{LINK_LABELS.ABOUT_US}</Link>
    </li>,
    <li key={LINK_LABELS.CONTACT_US}>
      <Link to={ROUTES.CONTACT_US}>{LINK_LABELS.CONTACT_US}</Link>
    </li>,
  ];

  if (isAuthenticated) {
    links.splice(
      1,
      0,
      <li key={LINK_LABELS.PROFILE}>
        <Link to={ROUTES.PROFILE_INFO}>{LINK_LABELS.PROFILE}</Link>
      </li>,
    );
  }

  return <ul>{links}</ul>;
};

const MainFooter = ({ isAuthenticated }) => (
  <Footer theme="dark" className={classes.footer}>
    {getLinks(isAuthenticated)}
  </Footer>
);

export default MainFooter;
