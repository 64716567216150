import { prefix } from './actions';
import { createSelector } from 'reselect';
import { META } from '~constants/routes';

export const location = state => state[prefix].location;
export const pathname = state => state[prefix].location.pathname;

export const title = createSelector(
  pathname,
  path => META[path] && META[path].title,
);

export const meta = createSelector(
  pathname,
  path => META[path],
);
