import moment from 'moment';
import uuid from 'uuid';
export const prefix = 'notify';

export const PUT_NOTIFICATION = `${prefix}/PUT_NOTIFICATION`;
export const putNotification = notification => ({
  type: PUT_NOTIFICATION,
  payload: {
    notification: {
      ...notification,
      date: moment().fromNow(),
      _id: uuid(),
    },
  },
});

export const REMOVE_NOTIFICATION = `${prefix}/REMOVE_NOTIFICATION`;
export const removeNotification = id => ({
  type: REMOVE_NOTIFICATION,
  payload: {
    id,
  },
});
