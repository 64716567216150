import React, { Component } from 'react';
import { Modal, Button, Row } from 'antd';
import cn from 'classnames';

import styles from '../../Table/Table.module.scss';
import formStyle from '../../../../components/Assets/Form/Form.module.scss';

export default class ResendEmailModal extends Component {
  render() {
    const { visible, handleResendEmailSubmit, closeResendModal } = this.props;

    return (
      <Modal
        className={cn(styles.resendEmailModal, styles.deleteModal)}
        visible={visible}
        centered
        footer={null}
        onCancel={closeResendModal}
      >
        <h2>Resend contribution email</h2>
        <p>
          Would you like to resend the contribution confirmation email to your
          investor?
        </p>
        <div className={styles.bottom}>
          <Row className={formStyle.actions}>
            <Button
              key="NO"
              onClick={closeResendModal}
              className={formStyle.cancel}
            >
              NO
            </Button>
            <Button
              key="YES"
              onClick={handleResendEmailSubmit}
              className={formStyle.next}
            >
              YES
            </Button>
          </Row>
        </div>
      </Modal>
    );
  }
}
