import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { allAssets, approveAsset, startSaleAsset } from '~redux/assets/actions';
import { loading, list } from '~redux/assets/selectors';
import AdminAssets from './AdminAssets';

const selector = createStructuredSelector({
  loading,
  list,
});

const actions = {
  allAssets,
  approveAsset,
  startSaleAsset,
};

export { AdminAssets };

export default connect(
  selector,
  actions,
)(AdminAssets);
