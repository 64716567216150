import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Chart from './Chart';

import { history } from '../../../redux/dashboard/selector';
import { getHistory } from '../../../redux/dashboard/actions';

const selectors = createStructuredSelector({
  data: history,
});

const actions = {
  getHistory,
};

export default connect(
  selectors,
  actions,
)(Chart);
