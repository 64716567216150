import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Form, Button, Spin } from 'antd';
import { TextField, TextAreaField } from 'redux-form-antd';
import {
  maxLength,
  minLength,
  required,
  email,
} from '~redux/redux-form-validators';
import makeField from '../../../components/WrappedFormElement';
import FileUpload from '../../../components/Investor/FileUpload';
import { ROUTES } from '~constants/routes';

import styles from './ContactForm.module.scss';

const FileUploadElement = makeField(FileUpload);

export default class ContactForm extends Component {
  state = {
    upload: null,
  };

  componentDidMount() {
    const { reset } = this.props;
    reset(); // to reset form fields if the page was not refreshed
  }

  handleUpload = async file => {
    const reader = new FileReader();
    reader.onloadend = () =>
      this.setState({
        upload: { name: file.name, type: file.type, data: reader.result },
      });

    reader.readAsDataURL(file);
  };

  handleSubmit = async e => {
    const {
      sendContactProfile,
      profileContactFormValues,
      history,
    } = this.props;

    const { upload } = this.state;

    e.preventDefault();

    const defaultData = {
      ...profileContactFormValues,
      subject: 'Contact from profile page',
      upload,
    };

    const { data } = await sendContactProfile(defaultData);

    if (data && data.success) {
      history.push(ROUTES.PROFILE_INFO);
    }
  };

  render() {
    const { valid, loading } = this.props;

    return (
      <Spin spinning={loading} size="large">
        <div className={styles.container}>
          <div className={styles.title}>Contact Us</div>
          <div className={styles.content}>
            <div className={styles.subtitle}>
              Your email and message are required
            </div>
          </div>
          <Form onSubmit={this.handleSubmit} className={styles.form}>
            <Form.Item className={styles.sInput}>
              <Field
                name="email"
                type="text"
                label="Email"
                placeholder="Email"
                component={TextField}
                validate={[required, email, minLength(3), maxLength(30)]}
              />
            </Form.Item>
            <Form.Item className={styles.sInput}>
              <Field
                name="message"
                type="text"
                label="Your message"
                placeholder="Write here"
                component={TextAreaField}
                rows={4}
                validate={[required, minLength(3), maxLength(300)]}
              />
            </Form.Item>
            <Form.Item className={styles.file}>
              <Field
                label="Upload file"
                name="upload"
                component={FileUploadElement}
                onChange={this.handleUpload}
              />
            </Form.Item>
            <Button
              disabled={!valid}
              className={styles.submit}
              htmlType="submit"
            >
              SUBMIT
            </Button>
          </Form>
        </div>
      </Spin>
    );
  }
}
