import { createSelector } from 'reselect';
import moment from 'moment';
import { prefix } from './actions';
import { STATUSES, ASSET_FILES } from '~constants/assets';
import getDocLinks from '~common/utils/getDocLinks';

const fromSelf = selector =>
  createSelector(
    state => state[prefix],
    selector,
  );

const fromAsset = selector =>
  fromSelf(s => !!s.selectedAsset && selector(s.selectedAsset));

export const loading = state => state[prefix].loading;

export const list = state => state[prefix].list;

export const asset = state => state[prefix].selectedAsset;

export const onSaleAssets = createSelector(
  list,
  assets => assets.filter(asset => asset.status === STATUSES.ON_SALE),
);

export const endedAssets = createSelector(
  list,
  assets => assets.filter(asset => asset.status === STATUSES.ENDED),
);

export const waitingListAssets = createSelector(
  list,
  assets => assets.filter(asset => asset.status === STATUSES.WAITING_LIST),
);

export const comingSoonAssets = createSelector(
  list,
  assets => assets.filter(asset => asset.status === STATUSES.COMING_SOON),
);

export const assetsByTypeSelector = createSelector(
  onSaleAssets,
  waitingListAssets,
  endedAssets,
  comingSoonAssets,
  (onSale, waitingList, ended, comingSoon) => ({
    [STATUSES.ON_SALE]: [...onSale, ...waitingList],
    [STATUSES.COMING_SOON]: comingSoon,
    [STATUSES.ENDED]: ended,
  }),
);

export const formValues = state =>
  (state.form.assetsForm && state.form.assetsForm.values) || null;

const uploadedFiles = state => state[prefix].uploadedFiles || {};

export const uploadedFileUid = createSelector(
  uploadedFiles,
  documents => {
    const spread = [];

    const files = Object.values(documents).forEach(el => spread.push(...el));
    if (!files) return null;
    const newestFile = files.pop();

    if (
      newestFile &&
      [ASSET_FILES.assetImages, ASSET_FILES.assetDocuments].includes(
        newestFile.url.split('/').slice(-2)[0],
      )
    )
      return newestFile.uid;

    return null;
  },
);

export const isFileRemoved = state => state[prefix].isFileRemoved;

export const assetContributionData = fromAsset(asset => {
  const {
    // TODO: use asset owner name instead of tokenName?
    // tokenName,
    id,
    vestingValue,
    name,
    currency,
    iban: assetIban,
    bic_code,
    bank,
    valueDate,
    files,
  } = asset;

  const docLinks = getDocLinks(files);

  return {
    // tokenName,
    AssetId: id,
    vestingValue,
    assetName: name,
    currency,
    assetIban,
    bic: bic_code,
    bank,
    valueDate: moment(valueDate).format('DD MMM YYYY'),
    docLinks,
  };
});

const saleStartedAt = fromAsset(a => a.saleStartedAt);

const duration = fromAsset(a => a.duration);

export const remainingSaleDays = createSelector(
  saleStartedAt,
  duration,
  (saleStartedAt, duration) =>
    moment(saleStartedAt)
      .add(duration, 'days')
      .diff(moment(), 'days'),
);

export const minInvestment = fromAsset(a => a.minInvestment);

export const error = state => state[prefix].error;

export const isFullScreenSliderOpen = state =>
  state[prefix].isFullScreenSliderOpen;

export const activeSlide = state => state[prefix].activeSlide;
