import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { compose } from '~utils';

import { updateUser } from '~redux/profile/actions';
import { referral } from '~redux/profile/selectors';

import Referral from './Referral';

const selectors = createStructuredSelector({ referral });

const actions = { onSubmit: updateUser };

const formConect = reduxForm({
  form: 'referralForm',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
  onSubmitSuccess: (result, dispatch) => dispatch(reset('referralForm')),
});

const reduxConect = connect(
  selectors,
  actions,
);

export default compose(
  reduxConect,
  formConect,
)(Referral);
