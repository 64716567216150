import React, { Component } from 'react';
import cn from 'classnames';
import { Form, Button, Popover, notification } from 'antd';
import { TextField } from 'redux-form-antd';
import { Field } from 'redux-form';
import { iban } from '~redux/redux-form-validators';

import formStyle from '../../../components/Assets/Form/Form.module.scss';
import ss from './FinancialInfo.module.scss';

export default class FinancialInfo extends Component {
  state = {
    isButtonEnabled: false,
  };

  componentDidUpdate(prevProps) {
    const { isPasswordChanged, onFormReset } = this.props;

    if (
      prevProps.isPasswordChanged !== isPasswordChanged &&
      isPasswordChanged
    ) {
      onFormReset('resetPassword');
    }
  }

  handleChange = value => {
    this.setState({ isButtonEnabled: Boolean(value) });
  };

  handleDelClick = ({
    target: {
      dataset: { source, value },
    },
  }) => {
    const {
      profile: { iban },
      updateUser,
    } = this.props;

    if (source === 'iban') {
      return updateUser({ [source]: iban.filter(el => el !== value) });
    }
  };

  handleInfoClick = () => {
    window.Beacon('open');
  };

  handleSubmit = e => {
    e.preventDefault();

    const {
      financialInfoFormValues,
      uniqueIbans,
      updateUser,
      onFormReset,
    } = this.props;

    if (financialInfoFormValues.iban) {
      if (uniqueIbans.includes(financialInfoFormValues.iban)) {
        notification.error({
          message: 'IBAN already exists!',
          duration: 3,
          placement: 'topRight',
        });

        return onFormReset('changeFinancialInfo');
      }

      const newIbans = [...uniqueIbans, financialInfoFormValues.iban];

      updateUser({
        iban: newIbans,
        notificationMessage: 'A new IBAN was added',
      });

      return onFormReset('changeFinancialInfo');
    }
  };

  render() {
    const { valid, ibansUsed, uniqueIbans } = this.props;

    const { isButtonEnabled } = this.state;

    return (
      <div className={ss.content}>
        <div className={ss.block}>
          <h3>Bank Information</h3>
          <h4 className={ss.heading}>IBAN</h4>
          {uniqueIbans.map(el => (
            <div className={ss.line} key={el}>
              <p>{el}</p>
              {ibansUsed.includes(el) ? (
                <Popover
                  placement="top"
                  content="To modify an in-use IBAN, please use the chat box to contact us."
                >
                  <button
                    className={cn(ss.btn, ss.infoBtn)}
                    onClick={this.handleInfoClick}
                  >
                    i
                  </button>
                </Popover>
              ) : (
                <button
                  className={cn(ss.btn, ss.delBtn)}
                  onClick={this.handleDelClick}
                  data-value={el}
                  data-source="iban"
                >
                  x
                </button>
              )}
            </div>
          ))}
          <Form className={ss.form} onSubmit={this.handleSubmit}>
            <div className={ss.row}>
              <Form.Item className={ss.input}>
                <Field
                  name="iban"
                  type="text"
                  placeholder="CH5604835012345678009"
                  component={TextField}
                  validate={[iban]}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Button
                disabled={!isButtonEnabled || !valid}
                htmlType="submit"
                className={formStyle.submit}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
