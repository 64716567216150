import React from 'react';
import cn from 'classnames';
import LoggedInMenu from '../LoggedInMenu';
import GuestMenu from '../GuestMenu';

import ss from './NavigationMenu.module.scss';

const NavigationMenu = ({ isAuthenticated, desktopOnly, hideMenu }) => (
  <ul className={cn(ss.list, desktopOnly && ss.desktopOnly)}>
    {isAuthenticated ? (
      <LoggedInMenu styles={ss} onMenuClick={hideMenu} />
    ) : (
      <GuestMenu styles={ss} onMenuClick={hideMenu} />
    )}
  </ul>
);

export default NavigationMenu;
