import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { login } from '~redux/profile/actions';
import { loginInvestorFormValues } from '~redux/users/selectors';

import Login from './Login';

const selectors = createStructuredSelector({ loginInvestorFormValues });

const actions = { login };

export default connect(
  selectors,
  actions,
)(Login);
