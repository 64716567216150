import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '~constants/routes';

import styles from './Footer.module.scss';
import logo from '~image-assets/logos/ELTENEMO_FINAL-Bleu.png';

const LINKS = [
  //   { label: 'Projects', to: ROUTES.PROJECTS },
  { label: 'About us', to: ROUTES.ABOUT_US },
  //   { label: 'Terms of use', to: ROUTES.LEGALS_MENTIONS },
  { label: 'Privacy Policy', to: ROUTES.PRIVACY_POLICY },
  //   { label: 'FAQ', to: ROUTES.FAQ },
  { label: 'Contact us', to: ROUTES.CONTACT_US },
];

const Footer = ({ simpleLayout }) =>
  simpleLayout ? null : (
    <div className={styles.footer}>
      <div className={styles.header}>
        <Link to={ROUTES.HOME}>
          <img className={styles.logo} src={logo} alt="Logo" />
        </Link>
        <br />© {new Date().getFullYear()} Eltenemo (Switzerland) SA
      </div>
      <ul className={styles.list}>
        {LINKS.map(({ label, to }) => (
          <li className={styles.item} key={label}>
            <Link className={styles.link} to={to}>
              {label}
            </Link>
          </li>
        ))}
      </ul>
      <div className={styles.contact}>
        <div className={styles.title}>Contact</div>
        <br />
        <b>Eltenemo (Switzerland) SA</b>
        <br />
        Switzerland
        <br />
        <br />
        <b>Email: </b>contact@eltenemo.com
      </div>
    </div>
  );

export default Footer;
