import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

import styles from './Button.module.scss';

const Button = ({ content, isTooltipShown = false, ...props }) => {
  const Wrapper = props.to ? Link : 'div';
  return (
    <Wrapper className={styles.column} {...props}>
      {isTooltipShown && (
        <Tooltip
          overlayClassName={styles.tooltip}
          title={() => (
            <>
              <p>
                Your asset will be pending approval by the administrator after
                creation.
              </p>
              <p>The review usually takes 3-5 days.</p>
              <p>The asset can be edited any time before it's approved.</p>
              <p>
                If you wish to change anything after the asset is put on sale,
                you will need to contact us.
              </p>
            </>
          )}
        >
          <div className={styles.icon}>i</div>
        </Tooltip>
      )}
      <div className={styles.content}>{content}</div>
    </Wrapper>
  );
};
export default Button;
