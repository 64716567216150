import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import {
  uploadedFileUid,
  isFileRemoved,
  loading,
} from '~redux/assets/selectors';
import { compose } from '~utils';

import AssetFileUpload from './AssetFileUpload';

const selectors = createStructuredSelector({
  uploadedFileUid,
  isFileRemoved,
  loading,
});

const reduxConnect = connect(
  selectors,
  null,
);

const formConect = reduxForm({
  form: 'assetFiles',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default compose(
  reduxConnect,
  formConect,
)(AssetFileUpload);
