import React, { Component } from 'react';
import { Button } from 'antd';

import s from './ResetTwoFa.module.scss';
import formStyle from '../../../components/Assets/Form/Form.module.scss';

export default class ResetTwoFa extends Component {
  handleClick = () => {
    const { resetTwoFA } = this.props;
    resetTwoFA();
  };

  render() {
    return (
      <div className={s.content}>
        <h3>2FA</h3>
        <div className={s.row}>
          <p>Reset two factor authentication</p>
          <Button
            className={formStyle.next}
            htmlType="submit"
            onClick={this.handleClick}
          >
            Reset
          </Button>
        </div>
      </div>
    );
  }
}
