import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.GET_RATE_REQUEST:
      case actions.GET_HISTORY_REQUEST:
        return true;
      case actions.GET_RATE_SUCCESS:
      case actions.GET_RATE_FAILURE:
      case actions.GET_HISTORY_SUCCESS:
      case actions.GET_HISTORY_FAILURE:
        return false;
      default:
        return state;
    }
  },
  rate(state = {}, action) {
    switch (action.type) {
      case actions.GET_RATE_REQUEST:
        return action.payload;
      default:
        return state;
    }
  },
  history(state = [], action) {
    switch (action.type) {
      case actions.GET_HISTORY_REQUEST:
        return action.payload;
      default:
        return state;
    }
  },
});
