import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Form, Row, Layout, Button } from 'antd';

import Input from './components/Input';
import { ROUTES } from '~constants/routes';
import makeField from '../../../../components/WrappedFormElement';
import { required } from '~redux/redux-form-validators';

import ss from './ResetCodeForm.module.scss';
import formStyle from '../../../../components/Assets/Form/Form.module.scss';

const { Content } = Layout;
const INPUTS = ['first', 'second', 'third', 'fourth'];
const FormInput = makeField(Input);

class ResetCodeForm extends Component {
  elements = [];

  componentDidMount() {
    this.elements[0].focus();
  }

  handleKeyPress = (e, index) => {
    if (e.charCode < 48 || e.charCode > 57) return;

    if (index < INPUTS.length - 1) {
      this.elements[index + 1].focus();
    }
  };

  handleSubmit = () => {
    const { history } = this.props;

    history.push(ROUTES.RESET_PASSWORD);
  };

  getRef = ref => {
    this.elements.push(ref);
  };

  handleResend = e => {
    const {
      passwordRecovery,
      history,
      recoveryPasswordValues: { email },
    } = this.props;

    e.preventDefault();

    passwordRecovery({ email });
    history.push(ROUTES.RESET);
  };

  render() {
    const { valid } = this.props;

    return (
      <Layout>
        <Content>
          <div className={ss.container}>
            <h3 className={ss.title}>
              We have just sent you a code to your e-mail address
            </h3>
            <p className={ss.subtitle}>
              Please enter your code using the field below
            </p>
            <Form onSubmit={this.handleSubmit} className={ss.form}>
              <Row className={ss.row}>
                {INPUTS.map((name, index) => (
                  <Form.Item key={name}>
                    <Field
                      name={name}
                      id={index}
                      getRef={this.getRef}
                      onCustomKeyPress={e => this.handleKeyPress(e, index)}
                      component={FormInput}
                      placeholder="0"
                      type="number"
                      validate={[required]}
                    />
                  </Form.Item>
                ))}
              </Row>
              <Button
                disabled={!valid}
                htmlType="submit"
                className={formStyle.submit}
              >
                NEXT
              </Button>
            </Form>
            <div className={ss.divider} />
            <p className={ss.question}>You have not received an email?</p>
            <span className={ss.link} onClick={this.handleResend}>
              Send another email
            </span>
          </div>
        </Content>
      </Layout>
    );
  }
}

export default ResetCodeForm;
