import React from 'react';
import { Form, Button, Row, Checkbox } from 'antd';
import { TextField } from 'redux-form-antd';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';

import makeField from '../../../../components/WrappedFormElement';
import { ROUTES } from '~constants/routes';

import formStyle from '../../../../components/Assets/Form/Form.module.scss';
import styles from './Form.module.scss';
import { required, email, minLength } from '~redux/redux-form-validators';

const CheckboxField = makeField(Checkbox);

class LoginForm extends React.Component {
  render() {
    const { handleSubmit, valid } = this.props;

    return (
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Form.Item className={styles.input}>
          <Field
            name="email"
            type="text"
            placeholder="Email"
            required
            component={TextField}
            validate={[required, email]}
          />
        </Form.Item>
        <Form.Item className={styles.input}>
          <Field
            name="password"
            type="password"
            placeholder="Password"
            required
            component={TextField}
            validate={[required, minLength(8)]}
          />
        </Form.Item>
        <Link className={styles.forgot} to={ROUTES.RESET}>
          Forgot password?
        </Link>
        <Form.Item className={styles.input}>
          <Field name="shouldKeepSignedIn" component={CheckboxField}>
            Stay logged in
          </Field>
        </Form.Item>
        <Row className={styles.actions}>
          <Button
            disabled={!valid}
            className={formStyle.submit}
            htmlType="submit"
          >
            LOG IN
          </Button>
        </Row>
        <div className={styles.footer}>
          <div className={styles.divider} />
          <div>You don’t have an account?</div>
          <Link to={ROUTES.SIGNUP}>Register</Link>
        </div>
      </Form>
    );
  }
}

export default LoginForm;
