import React from 'react';
import classnames from 'classnames';
import { Modal } from 'antd';

import styles from './Modal.module.scss';

const maskStyle = { backgroundColor: 'rgba(255, 255, 255, 0.75)' };

const ModalDialog = ({
  img,
  header,
  altImg,
  mainText,
  extraClass,
  titleClassName = '',
  content = null,
  footer,
  width = 434,
  ...rest
}) => (
  <Modal
    {...rest}
    markStyle={maskStyle}
    className={classnames(styles.modal, styles[extraClass])}
    width={width}
    footer={null}
    centered
  >
    <div className={classnames(styles.container, styles[extraClass])}>
      {img && <img className={styles.img} src={img} alt={altImg} />}
      {header && (
        <p className={classnames(styles.title, titleClassName)}>{header}</p>
      )}
      {mainText && <p>{mainText}</p>}
      {content}
      {footer && <p>{footer}</p>}
    </div>
  </Modal>
);

export default ModalDialog;
