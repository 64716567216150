import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { loadProfile } from '~redux/profile/actions';
import { pathname } from '~redux/router/selectors';

import Layout from './Layout';

const selector = createStructuredSelector({ pathname });
const actions = {
  init: loadProfile,
};

export default connect(
  selector,
  actions,
)(Layout);
