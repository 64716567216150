import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { compose } from '~utils';

import AddAsset from './AddAsset';

import { loadAssets, addAsset } from '~redux/assets/actions';

import { list, loading } from '~redux/assets/selectors';

const selector = createStructuredSelector({
  list,
  loading,
});

const actions = {
  loadAssets,
  addAsset,
};

const reduxConect = connect(
  selector,
  actions,
);

const formConect = reduxForm({
  form: 'assetsForm',
});

export default compose(
  reduxConect,
  formConect,
)(AddAsset);
