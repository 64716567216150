import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { compose } from '~utils';

import { error as err } from '~redux/profile/selectors';

import Form from './Form';

const selectors = createStructuredSelector({
  err,
});

const formConect = reduxForm({
  form: 'resetPassword',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
});

export { Form };

const reduxConnect = connect(
  selectors,
  null,
);

export default compose(
  reduxConnect,
  formConect,
)(Form);
