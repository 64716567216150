export const prefix = 'users';

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`;
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`;
export const LIST_FAILURE = `${prefix}/LIST_FAILURE`;

export const listUsers = () => ({
  types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: api => api.users.list(),
});

export const SHOW_REQUEST = `${prefix}/SHOW_REQUEST`;
export const SHOW_SUCCESS = `${prefix}/SHOW_SUCCESS`;
export const SHOW_FAILURE = `${prefix}/SHOW_FAILURE`;

export const showUser = id => ({
  types: [SHOW_REQUEST, SHOW_SUCCESS, SHOW_FAILURE],
  promise: api => api.users.show(id),
  id,
});

export const GRANT_REQUEST = `${prefix}/GRANT_REQUEST`;
export const GRANT_SUCCESS = `${prefix}/GRANT_SUCCESS`;
export const GRANT_FAILURE = `${prefix}/GRANT_FAILURE`;

export const grantAdmin = id => ({
  types: [GRANT_REQUEST, GRANT_SUCCESS, GRANT_FAILURE],
  promise: api => api.users.grantAdmin(id),
  id,
});

export const HIDE = `${prefix}/HIDE`;

export const hideUser = () => ({
  type: HIDE,
});

export const TOGGLE_INVETSTOR_TYPE_MODAL = `${prefix}/TOGGLE_INVETSTOR_TYPE_MODAL`;

export const toggleInvestorTypeModal = isOpen => ({
  type: TOGGLE_INVETSTOR_TYPE_MODAL,
  isOpen,
});

export const TOGGLE_MIN_INVESTMENT_MODAL = `${prefix}/TOGGLE_MIN_INVESTMENT_MODAL`;

export const toggleMinInvestmentModal = isOpen => ({
  type: TOGGLE_MIN_INVESTMENT_MODAL,
  isOpen,
});

export const ADD_INVESTOR_REQUEST = `${prefix}/ADD_INVESTOR_REQUEST`;
export const ADD_INVESTOR_SUCCESS = `${prefix}/ADD_INVESTOR_SUCCESS`;
export const ADD_INVESTOR_FAILURE = `${prefix}/ADD_INVESTOR_FAILURE`;

export const addInvestor = data => {
  const formData = new FormData();

  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });

  return {
    types: [ADD_INVESTOR_REQUEST, ADD_INVESTOR_SUCCESS, ADD_INVESTOR_FAILURE],
    promise: api => api.users.createInvestor(formData),
  };
};

export const LIST_INVESTORS_REQUEST = `${prefix}/LIST_INVESTORS_REQUEST`;
export const LIST_INVESTORS_SUCCESS = `${prefix}/LIST_INVESTORS_SUCCESS`;
export const LIST_INVESTORS_FAILURE = `${prefix}/LIST_INVESTORS_FAILURE`;

export const listInvestors = () => ({
  types: [
    LIST_INVESTORS_REQUEST,
    LIST_INVESTORS_SUCCESS,
    LIST_INVESTORS_FAILURE,
  ],
  promise: api => api.users.investorList(),
});

export const CREATE_CONTACT_REQUEST = `${prefix}/CREATE_CONTACT_REQUEST`;
export const CREATE_CONTACT_SUCCESS = `${prefix}/CREATE_CONTACT_SUCCESS`;
export const CREATE_CONTACT_FAILURE = `${prefix}/CREATE_CONTACT_FAILURE`;

export const createContact = data => ({
  types: [
    CREATE_CONTACT_REQUEST,
    CREATE_CONTACT_SUCCESS,
    CREATE_CONTACT_FAILURE,
  ],
  promise: api => api.users.createContact(data),
});

export const CONTACT_FORM_REQUEST = `${prefix}/CONTACT_FORM_REQUEST`;
export const CONTACT_FORM_SUCCESS = `${prefix}/CONTACT_FORM_SUCCESS`;
export const CONTACT_FORM_FAILURE = `${prefix}/CONTACT_FORM_FAILURE`;

export const sendContactUs = data => ({
  types: [CONTACT_FORM_REQUEST, CONTACT_FORM_SUCCESS, CONTACT_FORM_FAILURE],
  promise: api => api.users.sendContactUs(data),
});

export const PROFILE_CONTACT_FORM_REQUEST = `${prefix}/PROFILE_CONTACT_FORM_REQUEST`;
export const PROFILE_CONTACT_FORM_SUCCESS = `${prefix}/PROFILE_CONTACT_FORM_SUCCESS`;
export const PROFILE_CONTACT_FORM_FAILURE = `${prefix}/PROFILE_CONTACT_FORM_FAILURE`;

export const sendContactProfile = data => ({
  types: [
    PROFILE_CONTACT_FORM_REQUEST,
    PROFILE_CONTACT_FORM_SUCCESS,
    PROFILE_CONTACT_FORM_FAILURE,
  ],
  promise: api => api.users.sendContactProfile(data),
});
