import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { createStructuredSelector, createSelector } from 'reselect';

import InvestmentInfo from './InvestmentInfo';
import { signupInvestorFormValues } from '~redux/users/selectors';

import { compose } from '~utils';

const selectors = createStructuredSelector({
  isTermsChecked: createSelector(
    signupInvestorFormValues,
    values => values.terms,
  ),
  signupInvestorFormValues,
});

const reduxConnect = connect(
  selectors,
  null,
);

const formConect = reduxForm({
  form: 'signupInvestorForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmitSuccess: (result, dispatch) => dispatch(reset('signupInvestorForm')),
});

export default compose(
  reduxConnect,
  formConect,
)(InvestmentInfo);
