import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { listInvestors, showUser } from '~redux/users/actions';
import { loading, investors } from '~redux/users/selectors';
import Investors from './Investors';

const selector = createStructuredSelector({
  loading,
  investors,
});

const actions = {
  listInvestors,
  showUser,
};

export default connect(
  selector,
  actions,
)(Investors);
