import React, { Component } from 'react';
import { Upload, Icon, message } from 'antd';
import cn from 'classnames';

import {
  ASSET_FILES,
  FILE_MIME_TYPES,
  FILE_TYPES,
  RESULTS,
  ASSET_FILES_MAX_SIZE,
} from '~constants/assets';
import { MB_IN_BYTES } from '~constants/users';

import s from './FileUpload.module.scss';

const { Dragger } = Upload;

const uploadHints = {
  logoImage: 'Please, upload one image to be used as a logo',
  coverImage: 'Please, upload one image to be used as a cover',
  assetImages: 'Please, upload multiple images of your asset',
  assetDocuments: 'Please, upload multiple documents of your asset',
  assetVideo: 'Please, upload the short video presentation',
  // TODO: find out if those docs are necessary
  assetMemorandum: 'Please, upload the Series Memorandum',
  assetSubscription: 'Please, upload the Subscription Form',
};

const errorMessages = {
  logoImage: 'You can only upload JPG, JEPG or PNG file!',
  coverImage: 'You can only upload JPG, JEPG or PNG file!',
  assetImages: 'You can only upload JPG, JEPG or PNG files!',
  assetDocuments: 'You can only upload PDF files!',
  assetVideo: 'You can only upload WEBM, MP4 or MPEG file!',
  assetMemorandum: 'You can only upload PDF file!',
  assetSubscription: 'You can only upload PDF file!',
};

export default class FileUpload extends Component {
  state = {
    fileList: [],
    isFileRight: false,
  };

  static getDerivedStateFromProps(props, state) {
    const {
      asset: { files = {} },
      name,
    } = props;

    return {
      ...state,
      fileList: files?.[name] || [],
    };
  }

  beforeUpload = (file, name) => {
    const isCorrectFormat = FILE_MIME_TYPES[this.props.name].includes(
      file.type,
    );

    if (!isCorrectFormat) {
      return message.error(errorMessages[this.props.name]);
    }
    const isRightSize = file.size < ASSET_FILES_MAX_SIZE[this.props.name];

    if (!isRightSize) {
      const maxSize = ASSET_FILES_MAX_SIZE[this.props.name] / MB_IN_BYTES;
      return message.error(`File must be smaller than ${maxSize}MB!`);
    }

    file.location = name;

    if (isCorrectFormat && isRightSize) {
      this.setState({ isFileRight: true });
    } else {
      this.setState({ isFileRight: false });
    }
  };

  handleChange = ({ file, fileList }) => {
    const { name } = this.props;
    const { status, size, type } = file;
    const maxSize = file.size < ASSET_FILES_MAX_SIZE[name];

    if (size > maxSize || !FILE_MIME_TYPES[name].includes(type)) return;

    let newList;

    if (status === 'done') {
      message.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${file.name} file upload failed.`);
    }

    if (
      [ASSET_FILES.assetImages, ASSET_FILES.assetDocuments].includes(
        file.location,
      )
    ) {
      newList = fileList.slice(-8);
    } else {
      newList = fileList.slice(-1);
    }

    this.setState({ fileList: newList });
  };

  customRequest = async (e, imageType) => {
    await this.beforeUpload(e.file, imageType);
    const { isFileRight } = this.state;

    if (!isFileRight) return;

    const {
      file,
      file: { location, uid, name: fileName },
    } = e;

    const {
      uploadAssetFile,
      asset: { id, name },
    } = this.props;

    const { data } = await uploadAssetFile({
      file,
      id,
      uid,
      path: `AssetPics/${name}/${location}`,
      name: fileName,
    });

    if (data && data.message === RESULTS.SUCCESS) {
      e.onSuccess();
      message.success(`${fileName} file uploaded successfully.`);
    }

    if (data && data.message === RESULTS.ERROR) {
      e.onError();
      message.error(`${fileName} file upload failed.`);
    }

    return {
      abort() {},
    };
  };

  handleRemove = async e => {
    const { location, uid, url } = e;
    const {
      removeAssetFile,
      asset: { id, name },
    } = this.props;

    const ultimateLocation = location || url.split('/').slice(-2)[0];

    const { data } = await removeAssetFile({
      uid,
      path: `AssetPics/${name}/${ultimateLocation}`,
      id,
    });

    if (data && data.message === 'Removed') {
      message.success('Removed');
    }

    if (data && data.message === 'Error') {
      message.error('File was not removed');
    }
  };

  render() {
    const { name } = this.props;
    const { fileList } = this.state;

    return (
      <div className={s.container}>
        <Dragger
          onChange={e => this.handleChange(e, name)}
          accept={FILE_TYPES[name].join(',')}
          className={
            [ASSET_FILES.assetImages, ASSET_FILES.assetDocuments].includes(name)
              ? cn(s.upload, s.half)
              : s.upload
          }
          customRequest={e => this.customRequest(e, name)}
          onRemove={this.handleRemove}
          fileList={fileList}
          listType={name.includes('Image') ? 'picture' : 'text'}
        >
          <p className={s.arrow}>
            <Icon type="arrow-up" />
          </p>
          <p className="ant-upload-text">Drop file here or browse</p>
          <p className="ant-upload-hint">{uploadHints[name]}</p>
        </Dragger>
      </div>
    );
  }
}
