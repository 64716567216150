import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import GuestRoute from './GuestRoute';
import {
  loading,
  isAdmin,
  isAuthenticated,
  needSetupTwoFASelector,
  redirectLoggedInUserUrl,
} from '~redux/profile/selectors';
import { createStructuredSelector } from 'reselect';

const selector = createStructuredSelector({
  loading,
  isAdmin,
  isAuthenticated,
  needSetupTwoFASelector,
  redirectLoggedInUserUrl,
});

export default withRouter(
  connect(
    selector,
    null,
  )(GuestRoute),
);
