import React, { Component } from 'react';
import Contact from '../../../components/Investor/Contact';
import AssetContent from '../../../components/Investor/AssetContent';
import Banner from '../../../components/Investor/Banner';

import classes from './ClosedAsset.module.scss';
import { ROUTES } from '~constants/routes';

export default class ClosedAsset extends Component {
  componentWillMount() {
    const {
      match: {
        params: { id },
      },
      getAsset,
      loadContributions,
      profile: { id: userId },
    } = this.props;

    getAsset(id);

    loadContributions(userId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading && prevProps.loading !== this.props.loading) {
      const {
        userInvestedAssets,
        asset: { id },
        history,
      } = this.props;

      if (!userInvestedAssets.includes(id))
        return history.push(ROUTES.PROJECTS); // redirect the user to marketplace if they are trying to access closed asset they didn't invest in via direct link
    }
  }

  render() {
    const {
      asset,
      media: { logo },
    } = this.props;

    if (!asset) return null;

    const {
      name,
      files: { coverImage = [], logoImage = [] },
    } = asset;

    const coverImageUrl = coverImage[0]?.url;

    const logoImageUrl = logoImage[0]?.url || logo;

    return (
      <div className={classes.container}>
        <Banner img={coverImageUrl} name={name} />
        <AssetContent
          logo={logoImageUrl}
          asset={asset}
          isClosed
          rootClass="closedAsset"
        />
        <Contact isClosed={true} />
      </div>
    );
  }
}
