import React, { Component } from 'react';
import { Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { ROUTES } from '~constants/routes';
import FinancialInfo from '../FinancialInfo/';

import formStyle from '../../../components/Assets/Form/Form.module.scss';
import ss from './Investments.module.scss';

const { Column } = Table;

export default class Investments extends Component {
  state = { width: window.innerWidth };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { list } = this.props;

    return (
      <div className={ss.content}>
        <div className={ss.row}>
          <h3 className={ss.heading}>Transactions history</h3>
          {list.length ? (
            <Table
              dataSource={list}
              pagination={{ simple: true, defaultPageSize: 5 }}
              bordered
              rowKey={record => record.id}
              className={ss.table}
            >
              <Column
                dataIndex="Asset.name"
                key="assetName"
                title="Asset name"
              />
              <Column
                key="status"
                title="Status"
                render={(text, record) => (
                  <div className={ss[record.status]}>
                    {record.status.toLowerCase()}
                  </div>
                )}
              />
              <Column
                key="amount"
                title="Amount"
                render={(text, record) => (
                  <div>
                    <NumberFormat
                      value={record.amount}
                      displayType="text"
                      thousandSeparator={true}
                      prefix={`${record.Asset.currency} `}
                    />
                  </div>
                )}
              />
            </Table>
          ) : (
            <div className={ss.info}>
              <p className={ss.transactions}>No transaction information</p>
              <Link to={ROUTES.PROJECTS}>
                <Button htmlType="button" className={formStyle.submit}>
                  Start investing
                </Button>
              </Link>
            </div>
          )}
        </div>
        <div className={ss.row}>
          <FinancialInfo />
        </div>
      </div>
    );
  }
}
