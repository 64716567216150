import React from 'react';
import { Collapse } from 'antd';

import { faqObj } from './faqContent';
import s from './Sections.module.scss';

const { Panel } = Collapse;

const customPanelStyle = {
  marginBottom: 24,
  overflow: 'hidden',
};

const SectionContent = ({ topic }) => {
  return (
    <Collapse bordered={false} accordion className={s.collapse}>
      {topic.map(({ header, content }) => (
        <Panel
          className={s.pannel}
          style={customPanelStyle}
          header={header}
          key={header}
        >
          <div className={s.text}>{content}</div>
        </Panel>
      ))}
    </Collapse>
  );
};

export const faqData = [];
Object.keys(faqObj).forEach(key => {
  faqData.push({
    title: key,
    content: <SectionContent topic={faqObj[key]} />,
  });
  return faqData;
});
