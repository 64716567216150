import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Notification from './Notifications';

import { notifications } from '~redux/profile/selectors';
import { markAsRead } from '~redux/profile/actions';

const selector = createStructuredSelector({
  notifications,
});

const actions = {
  onRemove: markAsRead,
};

export default connect(
  selector,
  actions,
)(Notification);
