import React, { Component } from 'react';
import { Spin, Modal } from 'antd';

import AssetContent from '../../../components/Investor/AssetContent';
import Banner from '../../../components/Investor/Banner';

import s from './Asset.module.scss';
import InvestorTypeModal from './components/InvestorTypeModal';
import MinInvestmentModal from './components/MinInvestmentModal';
import AssetNotFound from './components/AssetNotFound';

import ImageCarousel from '../../../components/Investor/AssetContent/components/ImageCarousel';

export default class Asset extends Component {
  constructor(props) {
    super(props);

    const { match, getAsset } = props;
    getAsset(match.params.id);
  }

  componentWillUnmount() {
    this.props.removeSelectedAsset();
  }

  render() {
    const {
      asset,
      media: { logo },
      loading,
      history,
      error,
      isFullScreenSliderOpen,
      toggleOpenFullScreenSlider,
    } = this.props;

    if (!asset) return null;
    if (error) return <AssetNotFound error={error} />;

    const {
      name,
      files: { coverImage, logoImage },
    } = asset;

    const coverImageUrl = coverImage[0]?.url;

    const logoImageUrl = logoImage[0]?.url || logo;

    return (
      <Spin spinning={loading} size="large" wrapperClassName={s.spin}>
        <Modal
          className={s.sliderModal}
          footer={null}
          visible={isFullScreenSliderOpen}
          closable
          onCancel={() => toggleOpenFullScreenSlider(false)}
        >
          <ImageCarousel extraClass="fullScreen" />
        </Modal>

        <div className={s.container}>
          <InvestorTypeModal />
          <MinInvestmentModal history={history} />
          <Banner img={coverImageUrl} name={name} />
          <AssetContent logo={logoImageUrl} asset={asset} isClosed={false} />
        </div>
      </Spin>
    );
  }
}
