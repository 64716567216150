import React, { Component } from 'react';
import { Field } from 'redux-form';
import { TextField } from 'redux-form-antd';
import { Form, Button } from 'antd';
import cn from 'classnames';

import { email as emailFormat, required } from '~redux/redux-form-validators';

import ss from './EmailForm.module.scss';

class EmailForm extends Component {
  handleSubmit = async e => {
    e.preventDefault();

    const {
      onSubmit,
      source,
      formValues: { email },
    } = this.props;

    if (!email) return;

    await onSubmit({ email, source });
  };

  render() {
    const { rootClassName = '', valid } = this.props;

    return (
      <div className={cn(ss.container, rootClassName)}>
        <Form onSubmit={this.handleSubmit} className={ss.form}>
          <Form.Item className={ss.input}>
            <Field
              name="email"
              type="text"
              placeholder="Email..."
              required
              component={TextField}
              validate={[emailFormat, required]}
            />
          </Form.Item>
          <Button htmlType="submit" className={ss.btn} disabled={!valid}>
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

export default EmailForm;
