import React, { Component } from 'react';
import { Icon, Button, Spin, Modal as AntModal } from 'antd';
import cn from 'classnames';

import SignupForm from './RightForm';
import Modal from '../../../components/Modal';
import TermsText from '../../LegalsMentions/components';
import PrivacyText from '../../PrivacyPolicy/components';

import { ROUTES } from '~constants/routes';

import ss from './Signup.module.scss';
import formStyle from '../../../components/Assets/Form/Form.module.scss';

import emailImg from '~image-assets/email.png';

export default class Signup extends Component {
  // TODO: move this logic to GuestRoute and saga
  async componentDidMount() {
    const { loadProfile, history } = this.props;
    const { data } = await loadProfile();
    if (data && data.id) {
      history.push(ROUTES.HOME);
    }
  }

  state = { isOpenModal: false };

  showModal = () => {
    this.setState({ isOpenModal: true });
  };

  handleCloseModal = () => {
    const { history } = this.props;

    this.setState({ isOpenModal: false });
    history.push(ROUTES.HOME);
  };

  showTermsModal = source => {
    AntModal.info({
      title: source === 'terms' ? 'Terms and conditions' : 'Privacy Policy',
      icon: null,
      maskClosable: true,
      className: ss.termsModal,
      okButtonProps: {
        type: 'danger',
      },
      content: source === 'terms' ? <TermsText /> : <PrivacyText />,
      onOk() {},
    });
  };

  render() {
    const { isOpenModal } = this.state;
    const { loading } = this.props;

    return (
      <Spin spinning={loading} size="large">
        <div
          className={cn(
            ss.container,
            ss[window.location.pathname.split('/').pop()],
          )}
        >
          <aside>
            <h3>Create an account to start investing.</h3>
            <div className={ss.devider} />
            <p>
              <Icon className={ss.icon} type="check-circle" theme="filled" />
              Efiko is an all-in-one platform for impact-driven investment
              projects.
            </p>
            <p>
              <Icon className={ss.icon} type="check-circle" theme="filled" />A
              Efiko account gives you access to selected offering.
            </p>
            <p>
              <Icon className={ss.icon} type="check-circle" theme="filled" />
              From identity verification, payment, to paperwork, Efiko simplify
              every steps of the investment process.
            </p>
          </aside>
          <div className={ss.content}>
            <SignupForm showModal={this.showModal} />
          </div>
          <Modal
            img={emailImg}
            altImg="Asset logo"
            header="You have just received an email!"
            extraClass="center"
            mainText={
              <>
                Please use the link in the <b>email</b> to confirm your
                registration.
              </>
            }
            footer={
              <Button
                className={formStyle.submit}
                htmlType="button"
                onClick={this.handleCloseModal}
              >
                Close
              </Button>
            }
            visible={isOpenModal}
            onCancel={this.handleCloseModal}
          />
        </div>
      </Spin>
    );
  }
}
