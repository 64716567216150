import Base from './base';

export default class Auth extends Base {
  profile() {
    return this.apiClient.get('users/me');
  }

  login(email, password, shouldKeepSignedIn) {
    return this.apiClient.post('auth', { email, password, shouldKeepSignedIn });
  }

  signup(data) {
    return this.apiClient.post('users', data);
  }

  confirm(hash) {
    return this.apiClient.post(`actions/activateProfile/${hash}`);
  }

  resetPassword(email) {
    return this.apiClient.post('users/resetPassword', { email });
  }

  setPassword(code, password, confirmation_password) {
    return this.apiClient.post(`actions/changePassword/${code}`, {
      password,
      confirmation_password,
    });
  }

  setPasswordByEmail(email, password, confirmation_password) {
    return this.apiClient.post(`users/changePassword/${email}`, {
      password,
      confirmation_password,
    });
  }

  changePhoneNumber(email, phone) {
    return this.apiClient.patch(`users/profile`, {
      email,
      phone,
    });
  }

  setupTwoFA() {
    return this.apiClient.post('users/setupTwoFA');
  }

  activateTwoFA(code, shouldKeepSignedIn) {
    return this.apiClient.post('users/activateTwoFA', {
      code,
      shouldKeepSignedIn,
    });
  }

  checkTwoFA(code, shouldKeepSignedIn) {
    return this.apiClient.post('users/checkTwoFA', {
      code,
      shouldKeepSignedIn,
    });
  }

  resetTwoFA() {
    return this.apiClient.post('users/resetTwoFA');
  }

  async logout() {
    return null;
  }

  closeAccount(email) {
    return this.apiClient.put('users/profile/close', { email });
  }
}
