import { combineReducers } from 'redux';
import * as actions from './actions';
import * as contributionActions from '../contributions/actions';

export default combineReducers({
  loading(state = true, action) {
    switch (action.type) {
      case actions.PROFILE_REQUEST:
      case actions.LOGIN_REQUEST:
      case actions.LOGOUT_REQUEST:
      case actions.SIGNUP_REQUEST:
      case actions.RESET_PASSWORD_REQUEST:
      case actions.PASSWORD_RECOVERY_REQUEST:
      case actions.SUMSUB_APPLICANT_REQUEST:
      case actions.RESET_TWOFA_REQUEST:
      case actions.MARK_READ_REQUEST:
        // case actions.UPDATE_USER_REQUEST:
        return true;
      case actions.PROFILE_SUCCESS:
      case actions.PROFILE_FAILURE:
      case actions.LOGIN_SUCCESS:
      case actions.LOGIN_FAILURE:
      case actions.LOGOUT_SUCCESS:
      case actions.LOGOUT_FAILURE:
      case actions.SIGNUP_SUCCESS:
      case actions.SIGNUP_FAILURE:
      case actions.RESET_PASSWORD_SUCCESS:
      case actions.RESET_PASSWORD_FAILURE:
      case actions.PASSWORD_RECOVERY_SUCCESS:
      case actions.PASSWORD_RECOVERY_FAILURE:
      case actions.SUMSUB_APPLICANT_SUCCESS:
      case actions.SUMSUB_APPLICANT_FAILURE:
      case actions.RESET_TWOFA_SUCCESS:
      case actions.RESET_TWOFA_FAILURE:
      case actions.MARK_READ_SUCCESS:
      case actions.MARK_READ_FAILURE:
        // case actions.UPDATE_USER_SUCCESS:
        // case actions.UPDATE_USER_FAILURE:
        return false;
      default:
        return state;
    }
  },
  user(state = null, action) {
    switch (action.type) {
      case actions.PROFILE_SUCCESS: {
        const { notifications, ...rest } = action.data;
        return { ...state, ...rest };
      }

      case actions.CHANGE_PHONE_SUCCESS:
        return { ...state, phone: action.data.phone };

      case actions.UPDATE_USER_SUCCESS: {
        return { ...state, ...action.data.user };
      }
      case actions.LOGOUT_SUCCESS:
        return null;

      default:
        return state;
    }
  },
  isConfirmed(state = false, action) {
    switch (action.type) {
      case actions.CONFIRM_SUCCESS:
        return true;
      default:
        return state;
    }
  },
  isPasswordChanged(state = false, action) {
    switch (action.type) {
      case actions.RESET_PASSWORD_REQUEST:
      case actions.RESET_PASSWORD_FAILURE:
        return false;
      case actions.RESET_PASSWORD_SUCCESS:
        return true;
      default:
        return state;
    }
  },
  isRecoveryRequested(state = false, action) {
    switch (action.type) {
      case actions.PASSWORD_RECOVERY_REQUEST:
      case actions.PASSWORD_RECOVERY_FAILURE:
      case actions.RESET_SUCCESS_STATE:
        return false;
      case actions.PASSWORD_RECOVERY_SUCCESS:
        return true;
      default:
        return state;
    }
  },
  error(state = null, action) {
    switch (action.type) {
      case actions.LOGIN_FAILURE:
      case actions.LOGOUT_FAILURE:
      case actions.PASSWORD_RECOVERY_FAILURE:
      case actions.RESET_PASSWORD_FAILURE:
        return action.error;
      default:
        return state;
    }
  },
  successfullyRegistered(state = false, action) {
    switch (action.type) {
      case actions.SIGNUP_SUCCESS:
        return true;
      case actions.RESET_SUCCESS_STATE:
        return false;
      default:
        return state;
    }
  },
  twoFA(state = null, action) {
    switch (action.type) {
      case actions.SETUP_TWOFA_SUCCESS:
        return action.data;
      case actions.RESET_TWOFA_SUCCESS:
        return 'success';
      default:
        return state;
    }
  },
  ibansUsed(state = [], action) {
    switch (action.type) {
      case actions.IBANS_CONTRIBUTIONS_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  notifications(state = [], action) {
    switch (action.type) {
      case actions.PROFILE_SUCCESS:
        return action.data.notifications;

      case actions.RESET_PASSWORD_SUCCESS:
      case actions.CHANGE_PHONE_SUCCESS:
      case contributionActions.ADD_SUCCESS:
        return [action.data.success.notification, ...state];

      case actions.MARK_READ_SUCCESS:
        return action.data;

      default:
        return state;
    }
  },
});
