import React from 'react';

const SampleNextArrow = props => {
  const { className, style, onClick, dots: { left = 0, top = 0 } = {} } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        top: top + 4,
        left: left + 45,
      }}
      onClick={onClick}
    />
  );
};

export default SampleNextArrow;
