import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import LoginPage from '../../pages/LoginPage';
import SignupPage from '../../pages/SignupPage';
import Reset from '../../pages/Investor/Reset';
import ResetPasswordPage from '../../pages/Investor/Reset/ResetPasswordPage';
import PasswordRecovery from '../../pages/PasswordRecovery';
import GuestRoute from '../../components/GuestRoute';
import { ROUTES } from '~constants/routes';
import PropTypes from 'prop-types';
import styles from './AuthContainer.module.scss';

export default class AuthContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  render() {
    const { isAuthenticated, loading } = this.props;

    return (
      <div className={styles.container}>
        {isAuthenticated && !loading && <Redirect to={ROUTES.ADMIN_ROOT} />}
        {loading && (
          <Spin
            size="large"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: 2,
            }}
          />
        )}
        {!isAuthenticated && !loading && (
          <Switch>
            <GuestRoute path={ROUTES.ADMIN_LOGIN} component={LoginPage} exact />
            <GuestRoute
              path={ROUTES.ADMIN_SIGNUP}
              component={SignupPage}
              exact
            />
            <GuestRoute path={ROUTES.RESET} component={Reset} exact />
            <GuestRoute
              path={ROUTES.RESET_PASSWORD}
              component={ResetPasswordPage}
              exact
            />
            <GuestRoute
              path={ROUTES.PASSWORD_RECOVERY}
              component={PasswordRecovery}
              exact
            />
          </Switch>
        )}
      </div>
    );
  }
}
