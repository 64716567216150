import React from 'react';
import ss from './AssetProgressBar.module.scss';

const AssetProgressBar = ({ progress }) => {
  return (
    <div className={ss.bar}>
      <div className={ss.financedAmount}>
        <b>{progress}%</b> financed
      </div>
      <div className={ss.fill} style={{ width: progress + '%' }} />
    </div>
  );
};

export default AssetProgressBar;
