import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { asset } from '~redux/assets/selectors';
import { getAsset } from '~redux/assets/actions';

import { compose } from '~utils';

import Amount from './Amount';

const selectors = createStructuredSelector({
  asset,
  amount: state => formValueSelector('investmentForm')(state, 'amount'),
});

const actions = { getAsset };

const reduxConnect = connect(
  selectors,
  actions,
);

const formConect = reduxForm({
  form: 'investmentForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default compose(
  reduxConnect,
  formConect,
)(Amount);
