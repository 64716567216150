import React from 'react';

import s from '../../Asset.module.scss';

const AssetNotFound = ({ error }) => {
  return (
    <div className={s.notFound}>
      <p>{error}</p>
    </div>
  );
};

export default AssetNotFound;
