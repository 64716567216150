import { reduxForm } from 'redux-form';

import AssetType from './AssetType';

const formConect = reduxForm({
  form: 'assetsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default formConect(AssetType);
