import React from 'react';
import classnames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import makeField from '../WrappedFormElement';
import classes from './PhoneInput.module.scss';

const Phone = ({
  error,
  touched,
  defaultCountry = 'ch',
  onBlur,
  extraClass,
  phone,
  onChange,
}) => (
  <div>
    <PhoneInput
      value={phone}
      onBlur={onBlur}
      onChange={onChange}
      defaultCountry={defaultCountry}
      searchPlaceholder="Type name of country"
      searchClass={classes.search}
      inputClass={classnames(classes.text, classes[extraClass], {
        [classes.redBorder]: error && touched,
      })}
      dropdownClass={classes.list}
      buttonClass={classnames({ [classes.redBorder]: error && touched })}
      enableSearchField
    />
    {error && touched && <span className={classes.error}>{error}</span>}
  </div>
);

export default makeField(Phone);
