import React, { Component } from 'react';
import { Layout, Spin } from 'antd';
import styles from './Onboarding.module.scss';
import { ROUTES } from '~constants/routes';
import { USER_STATUS } from '~constants/users';

import { launchWebSdk } from '../../redux/profile/utils';

const { Content } = Layout;

class Onboarding extends Component {
  state = { step: 0, isOpenModal: false };

  async componentDidMount() {
    const {
      getAccessToken,
      history,
      updateUser,
      profile,
      match: {
        params: { shareholderId },
      },
    } = this.props;

    if (shareholderId) {
      // TODO create endpoint for shareholders who try to access this route from email
    }

    const { status, id, investorType, email, phone, sumsubId } = profile;

    if (status === USER_STATUS.KYC_DECLINED) return history.push(ROUTES.HOME);

    const { data } = await getAccessToken({ id });

    const { accessToken } = data;

    const applicantId = await launchWebSdk(
      accessToken,
      email,
      phone,
      investorType,
      id,
    );

    if (!sumsubId) updateUser({ sumsubId: applicantId });
  }

  render() {
    const { loading } = this.props;

    return (
      <Spin spinning={loading} size="large">
        <Layout>
          <Content className={styles.content}>
            <div
              id="sumsub-websdk-container"
              className={styles.sumsubContainer}
            />
          </Content>
        </Layout>
      </Spin>
    );
  }
}

export default Onboarding;
