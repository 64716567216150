import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { compose } from '~utils';

import { passwordRecovery } from '~redux/profile/actions';
import { recoveryPasswordValues } from '~redux/profile/selectors';
import ResetCodeForm from './ResetCodeForm';

const selectors = createStructuredSelector({
  recoveryPasswordValues,
});
const actions = { passwordRecovery };

const formConect = reduxForm({
  form: 'passwordRecovery',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
});

const reduxConect = connect(
  selectors,
  actions,
);

export default compose(
  reduxConect,
  formConect,
)(ResetCodeForm);
