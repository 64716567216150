import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { listUsers, showUser, grantAdmin } from '~redux/users/actions';
import { loading, list } from '~redux/users/selectors';
import AdminUsers from './AdminUsers';

const selector = createStructuredSelector({
  loading,
  list,
});

const actions = {
  listUsers,
  showUser,
  grantAdmin,
};

export { AdminUsers };

export default connect(
  selector,
  actions,
)(AdminUsers);
