import React from 'react';
import classnames from 'classnames';
import styles from '../../Table/Table.module.scss';

const TableFooter = ({ toggleShowMore, showMore, status }) => {
  const footerClass = classnames({
    [styles.showMore]: true,
    [styles[status]]: true,
  });
  return (
    <p onClick={toggleShowMore} className={footerClass}>
      {showMore ? 'Show less' : 'Show more'}
    </p>
  );
};

export default TableFooter;
