import React, { Component } from 'react';

import s from './ConfirmEmail.module.scss';
import { ROUTES } from '~constants/routes';

export default class ConfirmEmail extends Component {
  async componentDidMount() {
    const { confirm, match, history } = this.props;

    const res = await confirm(match.params.hash);

    if (!res.error) history.push(ROUTES.LOGIN);
  }

  render() {
    return (
      <div className={s.container}>
        <h4>within seconds you will be redirected to the login form...</h4>
      </div>
    );
  }
}
