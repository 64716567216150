import Base from './base';

export default class Assets extends Base {
  create(data) {
    return this.apiClient.post('assets', data);
  }

  load() {
    return this.apiClient.get('assets');
  }

  all() {
    return this.apiClient.get('assets/all');
  }

  approve(id) {
    return this.apiClient.put(`assets/approve/${id}`);
  }

  startSale(id) {
    return this.apiClient.put(`assets/startSale/${id}`);
  }

  getById(id) {
    return this.apiClient.get(`assets/${id}`);
  }

  closeSale(data) {
    return this.apiClient.patch('assets/closeSale', data);
  }

  uploadAssetFile(data) {
    return this.apiClient.post('assets/uploadFile', data);
  }

  removeAssetFile(data) {
    return this.apiClient.post('assets/removeAssetFile', data);
  }

  updateAsset(data) {
    return this.apiClient.post('assets/updateAsset', data);
  }

  getCarouselAssets() {
    return this.apiClient.get('assets/getCarouselAssets');
  }
}
