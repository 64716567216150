import React, { Component } from 'react';

import cn from 'classnames';
import FileUpload from '../../../../../FileUpload';
import styles from '../../AssetFileUpload.module.scss';

export default class AssetImages extends Component {
  render() {
    const { num } = this.props;

    return (
      <div className={cn(styles.wrapper, { [styles.uploaded]: num > 1 })}>
        <FileUpload name="assetImages" label="Upload pictures of the asset" />
      </div>
    );
  }
}
