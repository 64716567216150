import React from 'react';

import { USER_STATUS } from '~constants/users';
import NotAuthenticated from './components/NotAuthenticated/';
import Active from './components/Active/';
import Whitelisted from './components/Whitelisted/';
import KycPending from './components/KycPending/';
import KycDeclined from './components/KycDeclined/';
import { STATUSES } from '../../../constants/assets';

import ss from './Contact.module.scss';

const Contact = ({
  isAuthenticated,
  status,
  isClosed,
  invest,
  asset: { id, status: assetStatus },
}) => {
  return isClosed ? null : (
    <div className={ss.container}>
      {!isAuthenticated && <NotAuthenticated />}
      {status === USER_STATUS.ACTIVE && <Active />}
      {status === USER_STATUS.KYC_APPROVED && !isClosed && (
        <Whitelisted
          invest={invest}
          assetId={id}
          isMaximumReached={assetStatus === STATUSES.WAITING_LIST}
        />
      )}
      {status === USER_STATUS.KYC_PENDING && !isClosed && <KycPending />}
      {status === USER_STATUS.KYC_DECLINED && !isClosed && <KycDeclined />}
    </div>
  );
};

export default Contact;
