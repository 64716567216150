import React, { Component } from 'react';

import Carousel from '../../../components/Investor/Carousel';
import Brief from '../../../components/Investor/Brief';
import List from '../../../components/Investor/Advantages/components/List';
import Benefits from '../../../components/Investor/Benefits';
import Investing from '../../../components/Investor/InvestingAlt';
// import Investing from '../../../components/Investor/Investing';
// import Process from '../../../components/Investor/Process';

import styles from './Home.module.scss';

import { advantages, propositions, processes, discovers } from './content';

export default class Home extends Component {
  componentDidMount() {
    this.props.getCarouselAssets();
  }

  render() {
    return (
      <div className={styles.container}>
        <Carousel />
        <Brief
          title="A brand new way to fund innovation"
          subtitle="Eltenemo pioneers innovative HAAS financing solutions to accelerate technology adoption and serve our mission to improve People lives and help safeguard the Planet."
        />
        <Benefits
          title="Why"
          subtitle="Eltenemo?"
          text="Eltenemo finances innovative technologies by providing investors access to sustainability driven investment aligned with Article 9 of the European Taxonomy."
          list={advantages}
        />
        <Brief
          title="How do we select the innovations we support?"
          subtitle="Based on 3 criteria that each needs to be met"
        />
        <List list={propositions} transparent />
        {/* <Process
          title="A 3 step process"
          list={processes}
          discovers={discovers}
        /> */}
        <Investing />
      </div>
    );
  }
}
