import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Aside from './Aside';

import { asset } from '~redux/assets/selectors';

const selectors = createStructuredSelector({ asset });

export default connect(
  selectors,
  null,
)(Aside);
