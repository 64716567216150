import IBAN from 'iban';
import BIC from 'bic';

export const required = value => (value ? null : 'This field is required');

export const maxLength = max => value => {
  if (!value || (value.trim && !value.trim())) return null;

  return value && value.replace(/\s/g, '').length <= max
    ? null
    : `Maximum ${max} symbols`;
};

export const minLength = min => value => {
  if (!value || (value.trim && !value.trim())) return null;

  return value && value.replace(/\s/g, '').length >= min
    ? null
    : `Minimum ${min} symbols`;
};

export const number = value => {
  return (!isNaN(parseFloat(value)) && isFinite(value)) || !value
    ? null
    : 'Please, enter valid number';
};

export const minValue = min => value => {
  return (!isNaN(parseFloat(value)) && isFinite(value) && value >= min) ||
    !value
    ? null
    : `The minimum investment value is ${min ? min.toLocaleString() : 0}`;
};

export const maxValue = max => value => {
  return (!isNaN(parseFloat(value)) && isFinite(value) && value <= max) ||
    !value
    ? null
    : `The maximum investment value is ${max.toLocaleString()}`;
};

export const positiveNumber = value => {
  return (!isNaN(parseFloat(value)) && isFinite(value) && value > 0) || !value
    ? null
    : 'Please, enter a positive number';
};

export const notNegativeNumber = value => {
  return (!isNaN(parseFloat(value)) && isFinite(value) && value >= 0) || !value
    ? null
    : 'Please, enter a positive number';
};

export const zip = value => (value && value.length > 15 ? 'Invalid zip' : null);

export const email = value => {
  if (!value || (value.trim && !value.trim())) return null;

  return /^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9+_\-.]+)\.([a-zA-Z]{2,5})$/.test(
    value,
  )
    ? null
    : 'Invalid email';
};

export const phone = value => {
  if (!value) return null;

  const number = value.replace(/\s/g, '');

  return /([(+]*[0-9]+[()+. -]*)/.test(number) ? null : 'Invalid phone';
};

export const iban = value => {
  return IBAN.isValid(value) || !value ? null : 'IBAN is not valid';
};

export const bic = value =>
  BIC.isValid(value) || !value ? null : 'BIC is not valid';

export const tokenName = value => () => (value ? value : null);

export const divisibleBy = divisibleBy => val => {
  if (!val) return;

  return val % divisibleBy === 0
    ? null
    : `The investment value should be divisible by ${divisibleBy.toLocaleString()}`;
};
