import moment from 'moment';
import { ROUTES, TYPEFORM } from '~constants/routes';
import { STATUSES } from '~constants/assets';

export const getDataByStatus = status => {
  if (STATUSES.ON_SALE === status) {
    return {
      title: 'Distributed by ',
      button: 'Get more details',
      label: 'Open',
      link: ROUTES.PROJECT,
    };
  } else if (STATUSES.COMING_SOON === status) {
    return {
      title: 'Distributed by ',
      button: 'COMING SOON',
      label: 'COMING SOON',
      link: TYPEFORM,
    };
  } else if (STATUSES.WAITING_LIST === status) {
    return {
      title: 'Distributed by ',
      button: 'Get more details',
      label: 'Waiting list',
      link: ROUTES.PROJECT,
    };
  }

  return {
    title: 'Distributed by ',
    button: 'Closed',
    label: 'Successfully financed',
    link: ROUTES.CLOSED_PROJECT,
  };
};

export const getRemainingDays = (saleStartedAt, duration) =>
  moment(saleStartedAt)
    .add(duration, 'days')
    .diff(moment(), 'days');
