import React, { Component } from 'react';
import styles from '../../ResetCodeForm.module.scss';

export default class Input extends Component {
  handleKeyPress = e => {
    const { id, onCustomKeyPress, value } = this.props;

    onCustomKeyPress(e, id, value);
  };

  handleChange = e => {
    const { onChange } = this.props;

    if (e.target.value.length === 1) {
      this.handleKeyPress(e);
    }
    if (e.target.value.length > 1) return;

    onChange(e);
  };

  render() {
    const { getRef, value } = this.props;

    return (
      <input
        type="number"
        value={value}
        className={styles.input}
        ref={getRef}
        onChange={this.handleChange}
      />
    );
  }
}
