import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import {
  investmentFormValues,
  subscriptionFormValues,
} from '~redux/users/selectors';
import { asset } from '~redux/assets/selectors';
import { email, username } from '~redux/profile/selectors';

import { compose } from '~utils';

import Subscription from './Subscription';

const selectors = createStructuredSelector({
  email,
  username,
  investmentFormValues,
  asset,
  checkboxCheckedStatus: createSelector(
    subscriptionFormValues,
    ({ tokenizedNotesAgree, memorandumsConfirm, bankingCommit }) => ({
      tokenizedNotesAgree,
      memorandumsConfirm,
      bankingCommit,
    }),
  ),
});

const reduxConnect = connect(
  selectors,
  null,
);
const formConect = reduxForm({
  form: 'subscriptionForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default compose(
  formConect,
  reduxConnect,
)(Subscription);
