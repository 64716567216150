import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PasswordRecovery from './PasswordRecovery';
import { isRecoveryRequested } from '~redux/profile/selectors';

const selector = createStructuredSelector({
  isRecoveryRequested,
});

export { PasswordRecovery };

export default connect(
  selector,
  null,
)(PasswordRecovery);
