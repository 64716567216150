import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import PersonalInfo from './PersonalInfo';

import { compose } from '~utils';

const selectors = createStructuredSelector({});

const reduxConnect = connect(
  selectors,
  null,
);

const formConect = reduxForm({
  form: 'signupInvestorForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default compose(
  reduxConnect,
  formConect,
)(PersonalInfo);
