import React from 'react';
import classname from 'classnames';
import styles from './FileUpload.module.scss';
import uploadArrow from '~image-assets/investor/upload-arrow.png';

const DEFAULT_SELECTED_TEXT = '1 file selected';

const FileUpload = ({
  label,
  name,
  footer,
  onChange,
  value,
  showSelected = false,
  labelClassName = '',
  headerClassName = '',
  footerClassName = '',
  dropClassName = '',
}) => (
  <div className={styles.container}>
    {label && (
      <span className={classname(styles.header, headerClassName)}>{label}</span>
    )}
    <input
      type="file"
      id={name}
      className={styles.input}
      onChange={e => onChange(e.target.files[0])}
    />
    <label className={classname(styles.label, labelClassName)} htmlFor={name}>
      <img className={styles.icon} src={uploadArrow} alt="upload icon" />
      {(value && <span className={styles.name}>{value.name}</span>) || (
        <span className={classname(styles.drop, dropClassName)}>
          Drop file here or <span className={styles.link}>browse</span>
        </span>
      )}
    </label>
    {footer && (
      <span className={classname(styles.footer, footerClassName)}>
        {showSelected && value ? DEFAULT_SELECTED_TEXT : footer}
      </span>
    )}
  </div>
);

export default FileUpload;
