import React, { Component } from 'react';
import { Form, Button, Row, Tabs } from 'antd';
import { TextAreaField } from 'redux-form-antd';
import { Field } from 'redux-form';

import { ROUTES } from '~constants/routes';

import AssetFileUpload from '../AssetFileUpload';

import formStyle from '../Form.module.scss';
import styles from './AssetInformation.module.scss';
import { maxLength, minLength, required } from '~redux/redux-form-validators';

const { TabPane } = Tabs;

export default class AssetInformation extends Component {
  handleSubmit = () => {
    const {
      asset: { id },
      formValues: { description },
      updateAssetFields,
      history,
    } = this.props;

    updateAssetFields({ id, description });

    setTimeout(() => {
      history.push(ROUTES.ASSETS_SETTINGS);
    }, 1500);
  };

  componentWillUnmount() {
    this.props.removeSelectedAsset();
  }

  render() {
    const { valid } = this.props;

    return (
      <div className={styles.container}>
        <Tabs
          defaultActiveKey="Description"
          tabPosition="left"
          className={styles.tabs}
        >
          <TabPane tab="Description" key="Description">
            <Form onSubmit={this.handleSubmit} className={styles.form}>
              <Form.Item className={styles.input}>
                <Field
                  name="description"
                  type="text"
                  label="Description of the asset"
                  placeholder="Describe your asset"
                  component={TextAreaField}
                  rows={12}
                  validate={[required, minLength(10), maxLength(100000)]}
                />
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Files" key="Files">
            <AssetFileUpload />
          </TabPane>
        </Tabs>
        <Row className={formStyle.actions}>
          <Button
            disabled={!valid}
            className={formStyle.submit}
            htmlType="submit"
            onClick={this.handleSubmit}
          >
            send in review
          </Button>
        </Row>
      </div>
    );
  }
}
