import React, { Component } from 'react';
import { Table as Tbl, Button, Popover } from 'antd';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';

import styles from './Table.module.scss';
import TableFooter from '../Components/TableFooter';
import StatusText from '../Components/StatusText';
import ContributionConfirmationModal from '../Components/ContributionConfirmationModal';
import ResendEmailModal from '../Components/ResendEmailModal';

const { Column } = Tbl;

export default class Table extends Component {
  state = {
    showMore: false,
    declineModalVisible: false,
    confirmationModalVisible: false,
    selectedContributionId: '',
    isTransactionModalOpen: false,
    isResendModalOpen: false,
    record: null,
  };

  toggleShowMore = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
  };

  showDeclineModal = ({ id, User: { email } }) => {
    this.setState({
      declineModalVisible: !this.state.declineModalVisible,
      selectedContributionId: id,
      email,
    });
  };

  showConfirmationModal = ({ id, User: { email } }) => {
    this.setState({
      confirmationModalVisible: !this.state.confirmationModalVisible,
      selectedContributionId: id,
      email,
    });
  };

  handleSubmit = async () => {
    const { declineContribution, approveContribution } = this.props;
    const {
      confirmationModalVisible,
      selectedContributionId,
      email,
    } = this.state;

    if (confirmationModalVisible) {
      approveContribution(selectedContributionId, email);
    } else {
      declineContribution(selectedContributionId, email);
    }

    this.handleCancel();
  };

  handleCancel = () => {
    const { confirmationModalVisible } = this.state;

    if (confirmationModalVisible) {
      this.setState({
        confirmationModalVisible: false,
      });
    } else {
      this.setState({
        declineModalVisible: false,
      });
    }
  };

  openResendModal = record => {
    this.setState({
      isResendModalOpen: true,
      record,
    });
  };

  closeResendModal = () => {
    this.setState({ isResendModalOpen: false, record: null });
  };

  handleResendEmailSubmit = () => {
    const { sendContributionEmail } = this.props;

    const {
      record: { id },
    } = this.state;

    sendContributionEmail({ id });

    this.setState({ isResendModalOpen: false, record: null });
  };

  render() {
    const { list, status, className } = this.props;

    const {
      showMore,
      declineModalVisible,
      isResendModalOpen,
      confirmationModalVisible,
    } = this.state;

    const tblClass = classnames({
      [styles.tbl]: true,
      [styles[className]]: true,
    });

    return (
      <>
        <ContributionConfirmationModal
          visible={confirmationModalVisible}
          handleCancel={this.handleCancel}
          handleSubmit={this.handleSubmit}
          type="confirm"
        />

        <ContributionConfirmationModal
          visible={declineModalVisible}
          handleCancel={this.handleCancel}
          handleSubmit={this.handleSubmit}
          type="decline"
        />

        <ResendEmailModal
          visible={isResendModalOpen}
          handleResendEmailSubmit={this.handleResendEmailSubmit}
          closeResendModal={this.closeResendModal}
        />

        <div className={classnames(styles.tWrapper, tblClass)}>
          <Tbl
            dataSource={showMore ? list : list.slice(0, 2)}
            rowKey="id"
            pagination={showMore ? { simple: true } : false}
          >
            <Column
              title={() => (
                <StatusText
                  status={status}
                  number={list.length}
                  toggleShowMore={this.toggleShowMore}
                />
              )}
              colSpan={2}
              dataIndex="Asset.name"
              key="assetName"
            />

            <Column
              colSpan={0}
              className={styles.userName}
              key="userName"
              render={(text, { User }) => `${User.firstName} ${User.lastName}`}
            />

            <Column
              key="IBAN"
              render={(text, { iban }) => (
                <>
                  <Popover placement="right" content={iban}>
                    <p>
                      {iban.length > 20 ? iban.substr(0, 20) + '...' : iban}
                    </p>
                  </Popover>
                </>
              )}
            />

            <Column key="reference" dataIndex="reference" />

            <Column
              dataIndex="amount"
              key="amount"
              render={(text, { amount, Asset }) => (
                <div>
                  <NumberFormat
                    value={amount}
                    displayType="text"
                    thousandSeparator={true}
                    prefix={`${Asset && Asset.currency} `}
                  />
                </div>
              )}
            />

            {status === 'Pending' ? (
              <Column
                key="Actions"
                className={styles.actions}
                render={(text, record) => (
                  <div className={styles.confirmationBtns}>
                    <Button
                      className={classnames(styles.confirm, styles.btn)}
                      onClick={() => this.showConfirmationModal(record)}
                    >
                      CONFIRM
                    </Button>
                    <Button
                      className={classnames(styles.decline, styles.btn)}
                      onClick={() => this.showDeclineModal(record)}
                    >
                      DECLINE
                    </Button>
                  </div>
                )}
              />
            ) : (
              <Column key="Actions" className={styles.actions} />
            )}

            <Column
              title=""
              key="Contact"
              render={(text, record) => (
                <>
                  <a
                    href={`mailto:${record.User && record.User.email}`}
                    className={styles.contact}
                  >
                    Contact
                  </a>
                  <span
                    className={styles.contact}
                    onClick={() => this.openResendModal(record)}
                  >
                    Resend email
                  </span>
                </>
              )}
            />
          </Tbl>
          {list.length > 2 && (
            <TableFooter
              toggleShowMore={this.toggleShowMore}
              showMore={showMore}
              status={status}
            />
          )}
        </div>
      </>
    );
  }
}
