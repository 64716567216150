import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Contact from './Contact';

import { isAuthenticated, status } from '~redux/profile/selectors';
import { asset } from '~redux/assets/selectors';

const selector = createStructuredSelector({
  isAuthenticated,
  status,
  asset,
});

export default connect(
  selector,
  null,
)(Contact);
