import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import { TextField } from 'redux-form-antd';
import { Field } from 'redux-form';
import { minLength, required } from '~redux/redux-form-validators';
import { ROUTES } from '~constants/routes';

import ss from './Form.module.scss';
import formStyle from '../../../../components/Assets/Form/Form.module.scss';

export default class ResetPasswordForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    const { err, history } = this.props;

    if (err && err.code === 'NOT_FOUND') {
      setTimeout(() => {
        history.push(ROUTES.RESET);
      }, 1500);
    }
  }

  render() {
    const { handleSubmit, onSubmit, valid, buttonText } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSubmit)} className={ss.form}>
        <Form.Item className={ss.input}>
          <Field
            name="password"
            type="password"
            label="Choose a new password"
            placeholder="New password"
            component={TextField}
            validate={[minLength(8), required]}
          />
        </Form.Item>
        <Form.Item className={ss.input}>
          <Field
            name="confirmation_password"
            type="password"
            label="Confirm password"
            placeholder="Confirm password"
            component={TextField}
            validate={[minLength(8), required]}
          />
        </Form.Item>
        <div className={ss.bottom}>
          <Button
            disabled={!valid}
            htmlType="submit"
            className={formStyle.submit}
          >
            {buttonText || 'Set new password'}
          </Button>
        </div>
      </Form>
    );
  }
}
