import React, { Component } from 'react';
import { Form, Button, Layout, Row } from 'antd';
import QRCode from 'qrcode.react';
import { TextField } from 'redux-form-antd';
import { Field } from 'redux-form';

import styles from './SetupTwoFA.module.scss';
import formStyle from '../../components/Assets/Form/Form.module.scss';

const { Content } = Layout;

export default class SetupTwoFA extends Component {
  renderQR() {
    const { handleSubmit, twoFA, twoFASetuped } = this.props;

    return (
      <Form onSubmit={handleSubmit} className={styles.form}>
        {!twoFASetuped && <QRCode value={twoFA.authURL} size={256} />}
        <Field
          name="code"
          type="text"
          label="Enter code"
          placeholder="code"
          autoFocus
          component={TextField}
        />
        <Button className={formStyle.next} htmlType="submit">
          Submit code
        </Button>
      </Form>
    );
  }

  render() {
    const { setupTwoFA, twoFA, twoFASetuped, email } = this.props;

    return (
      <Layout>
        <Content className={styles.content}>
          <Row>
            To continue, you need to setup/pass the second factor for{' '}
            <b>{email}</b>:
          </Row>
          <Row className={styles.qr}>
            {!twoFA && !twoFASetuped ? (
              <Button onClick={setupTwoFA} className={formStyle.next}>
                Setup TwoFA
              </Button>
            ) : (
              this.renderQR()
            )}
          </Row>
        </Content>
      </Layout>
    );
  }
}
