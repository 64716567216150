import React from 'react';
import { Row, Layout } from 'antd';
import styles from './Group.module.scss';
import Item from '../Item';
import Button from '../Button';

import { STATUSES } from '~constants/assets';
import { ROUTES } from '~constants/routes';

class Group extends React.Component {
  render() {
    const { status, label, list } = this.props;
    const assets = list.filter(asset => asset.status === status);

    return assets.length > 0 || status === STATUSES.PENDING ? (
      <Layout className={styles.container}>
        <h4 className={styles.title}>{label}</h4>
        <Row className={styles.row}>
          {assets.map(asset => (
            <Item
              key={asset.id}
              item={asset}
              isEditActive={status === STATUSES.PENDING}
            />
          ))}
          {status === STATUSES.PENDING && (
            <Button to={ROUTES.ADD_ASSET} isTooltipShown content="+" />
          )}
        </Row>
      </Layout>
    ) : null;
  }
}

export default Group;
