import { reduxForm, reset } from 'redux-form';

import Form from './Form';

const formConect = reduxForm({
  form: 'loginInvestorForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmitSuccess: (result, dispatch) => dispatch(reset('loginInvestorForm')),
});

export default formConect(Form);
