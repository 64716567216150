import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Drawer, Icon } from 'antd';
import { ROUTES } from '~constants/routes';
import NavigationMenu from './components/NavigationMenu';

import styles from './Header.module.scss';
import logo from '~image-assets/logos/ELTENEMO_FINAL-Bleu-Sigle.png';

class Header extends React.Component {
  lastScrollTop = 0;
  state = {
    isMenuOpen: false,
    isHeaderVisible: false,
  };

  componentDidMount() {
    this.handleScroll();

    window.addEventListener('scroll', this.handleScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > this.lastScrollTop) {
      this.setState({
        isHeaderVisible: false,
      });
    } else {
      this.setState({
        isHeaderVisible: true,
      });
    }

    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  };

  showMenu = () => this.setState({ isMenuOpen: true });
  hideMenu = () => this.setState({ isMenuOpen: false });

  render() {
    const { isMenuOpen, isHeaderVisible } = this.state;
    const { simpleLayout } = this.props;

    return (
      <div
        className={cn(
          styles.header,
          isHeaderVisible && !isMenuOpen && styles.headerIsVisible,
        )}
      >
        <Link to={ROUTES.HOME}>
          <img className={styles.logo} src={logo} alt="Logo" />
        </Link>
        {!simpleLayout && (
          <>
            <NavigationMenu desktopOnly hideMenu={this.hideMenu} />

            <Icon
              className={styles.menuIcon}
              type="menu"
              onClick={this.showMenu}
            />

            <Drawer
              placement="left"
              className={styles.drawer}
              onClose={this.hideMenu}
              visible={isMenuOpen}
            >
              <NavigationMenu hideMenu={this.hideMenu} />
            </Drawer>
          </>
        )}
      </div>
    );
  }
}

export default Header;
