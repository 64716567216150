import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Dashboard from './Dashboard';

import { rate } from '../../redux/dashboard/selector';
import { getRate } from '../../redux/dashboard/actions';

import { status } from '../../redux/profile/selectors';

//import { getPortfolio } from '../../redux/profile/actions';

const selectors = createStructuredSelector({
  ticker: rate,
  status,
  //portfolio,
});

const actions = {
  getTickerData: getRate,
  //getPortfolio,
};

export default connect(
  selectors,
  actions,
)(Dashboard);
