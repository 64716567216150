import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { compose } from '~utils';

import { changePhone } from '~redux/profile/actions';
import { updatedPhone, user, phone } from '~redux/profile/selectors';
import ChangePhoneNumber from './ChangePhoneNumber';

const selectors = createStructuredSelector({
  updatedPhone,
  phone,
  user,
});
const actions = { changePhone, onFormReset: reset };

const formConect = reduxForm({
  form: 'changePhoneNumber',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
});

const reduxConect = connect(
  selectors,
  actions,
);

export default compose(
  reduxConect,
  formConect,
)(ChangePhoneNumber);
