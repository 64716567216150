import React from 'react';

import styles from './Benefits.module.scss';

const Benefits = ({ title, subtitle, text, list }) => (
  <div className={styles.benefits}>
    <div className={styles.wrap}>
      <div className={styles.benefit}>
        <div className={styles.title}>
          {title} <br />
          {subtitle}
        </div>
        <div className={styles.text}>{text}</div>
      </div>
      {list.map(({ title, img, content }) => (
        <div className={styles.benefit} key={title}>
          <img className={styles.image} src={img} alt={title} />
          <div className={styles.subtitle}>{title}</div>
          <div className={styles.content}>{content}</div>
        </div>
      ))}
    </div>
  </div>
);

export default Benefits;
