import { prefix } from './actions';
import { createSelector } from 'reselect';
import { location } from '../router/selectors';
import { USER_ROLES } from '~constants/users';
import { ROUTES } from '~constants/routes';

const { SUPER_ADMIN, COMPANY_ADMIN } = USER_ROLES;

const fromSelf = selector =>
  createSelector(
    state => state[prefix],
    selector,
  );

const fromUser = selector => fromSelf(s => !!s.user && selector(s.user));

export const loading = fromSelf(s => s.loading);
export const error = fromSelf(s => s.error);
export const isPasswordChanged = fromSelf(s => s.isPasswordChanged);
export const isRecoveryRequested = fromSelf(s => s.isRecoveryRequested);
export const successfullyRegistered = fromSelf(s => s.successfullyRegistered);
export const twoFA = fromSelf(s => s.twoFA);

export const ibansUsed = fromSelf(s => s.ibansUsed);

export const isAuthenticated = fromUser(u => !!u);
export const role = fromUser(u => u.role);
export const email = fromUser(u => u && u.email);
export const firstName = fromUser(u => u && u.firstName);
export const lastName = fromUser(u => u && u.lastName);
export const phone = fromUser(u => u && u.phone);
export const status = fromUser(u => u && u.status);
export const twoFAPassed = fromUser(u => u.twoFAPassed);
export const twoFASetuped = fromUser(u => u.twoFASetuped && u.twoFAActive);
export const isCompanyAdmin = fromUser(
  u => u.role === USER_ROLES.COMPANY_ADMIN,
);

export const username = createSelector(
  firstName,
  lastName,
  (firstName, lastName) => `${firstName} ${lastName}`,
);

export const isSuperAdmin = fromUser(u => u.role === USER_ROLES.SUPER_ADMIN);
export const needSetupTwoFASelector = fromUser(user => {
  const { role, twoFASetuped, twoFAPassed } = user;
  const adminRoles = [COMPANY_ADMIN, SUPER_ADMIN];

  return adminRoles.includes(role) && (!twoFASetuped || !twoFAPassed);
});

export const investorType = fromUser(u => u && u.investorType);

export const isAdmin = createSelector(
  isCompanyAdmin,
  isSuperAdmin,
  (isCompanyAdmin, isSuperAdmin) => isCompanyAdmin || isSuperAdmin,
);

export const referral = fromUser(u => u && u.referral);

export const uniqueIbans = state => {
  const { user } = state.profile;

  if (!user) return [];

  const { iban } = user;

  return [...new Set(iban)];
};

const passwordRecoveryForm = state => state.form.passwordRecovery;
const resetPasswordForm = state => state.form.resetPassword;
const changePhoneNumberForm = state => state.form.changePhoneNumber;
const changeFinancialInfoForm = state => state.form.changeFinancialInfo;
const profileContactForm = state => state.form.profileContactForm;

export const user = state => state.profile.user;

export const code = createSelector(
  passwordRecoveryForm,
  passwordRecovery => {
    if (!passwordRecovery || !passwordRecovery.values) return;

    const { email, ...rest } = passwordRecovery.values;

    return Object.values({ ...rest }).join('');
  },
);

export const recoveryPasswordValues = createSelector(
  passwordRecoveryForm,
  resetPasswordForm,
  user,
  (passwordRecovery, resetPassword, user) => {
    if (passwordRecovery && !user) {
      return passwordRecovery.values;
    }

    if (resetPassword && user) {
      return {
        ...resetPassword.values,
        email: user.email,
      };
    }
  },
);

export const updatedPhone = createSelector(
  changePhoneNumberForm,
  changePhoneNumber => {
    if (!changePhoneNumber || !changePhoneNumber.values) return {};

    return changePhoneNumber.values.phone;
  },
);

export const profile = state => {
  const { user } = state.profile;

  if (!user) return {};

  return user;
};

export const financialInfoFormValues = state =>
  (state.form.changeFinancialInfo && state.form.changeFinancialInfo.values) ||
  {};

export const profileContactFormValues = createSelector(
  profileContactForm,
  changeFinancialInfoForm,
  profile,
  (profileContactForm, changeFinancialInfoForm, profile) => {
    const { lastName, firstName } = profile;

    if (profileContactForm) {
      const defaultValues = {
        ...profileContactForm.values,
        subject: 'Contact from profile page',
        lastName,
        firstName,
      };
      if (changeFinancialInfoForm) {
        return {
          ...defaultValues,
          ...changeFinancialInfoForm.values,
          subject: 'Request modification',
        };
      } else {
        return {
          ...defaultValues,
          subject: 'Lost Access to Wallet',
        };
      }
    }
  },
);

export const notifications = fromSelf(s =>
  s.notifications
    .filter(notification => notification.status === 'NEW')
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
);

export const userContributionData = fromUser(u => {
  const { id, firstName, email } = u;

  return {
    UserId: id,
    name: firstName,
    email,
  };
});

export const investorData = state => {
  const { user } = state.profile;

  if (!user) return {};

  const {
    sumsubName,
    email,
    sumsubCountry,
    sumsubCity,
    sumsubZip,
    sumsubAddress,
    id: userId,
  } = user;

  return {
    name: sumsubName,
    email,
    country: sumsubCountry,
    city: sumsubCity,
    zip: sumsubZip,
    address: sumsubAddress,
    userId,
  };
};

export const redirectLoggedInUserUrl = createSelector(
  location,
  isCompanyAdmin,
  isSuperAdmin,
  (location, isCompanyAdmin, isSuperAdmin) => {
    const fromPathname =
      location.state && location.state.from && location.state.from.pathname;
    return (
      fromPathname ||
      (isSuperAdmin && ROUTES.ADMIN_ROOT) ||
      (isCompanyAdmin && ROUTES.ASSETS_SETTINGS) ||
      ROUTES.HOME
    );
  },
);
