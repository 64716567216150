import { combineReducers } from 'redux';
import * as actions from './actions';

const DEFAULT_ASSET = {
  files: {
    logoImage: [],
    coverImage: [],
    assetImages: [],
    assetDocuments: [],
    assetVideo: [],
    // TODO: are the docs below still needed?
    assetMemorandum: [],
    assetSubscription: [],
  },
};

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.LOAD_REQUEST:
      case actions.ALL_REQUEST:
      case actions.GET_ASSET_REQUEST:
      case actions.UPLOAD_FILE_REQUEST:
      case actions.REMOVE_FILE_REQUEST:
      case actions.UPDATE_ASSET_REQUEST:
      case actions.START_SALE_REQUEST:
      case actions.GET_CAROUSEL_ASSETS_REQUEST:
        return true;
      case actions.LOAD_SUCCESS:
      case actions.ALL_SUCCESS:
      case actions.LOAD_FAILURE:
      case actions.ALL_FAILURE:
      case actions.GET_ASSET_FAILURE:
      case actions.GET_ASSET_SUCCESS:
      case actions.UPLOAD_FILE_SUCCESS:
      case actions.UPLOAD_FILE_FAILURE:
      case actions.REMOVE_FILE_SUCCESS:
      case actions.REMOVE_FILE_FAILURE:
      case actions.UPDATE_ASSET_SUCCESS:
      case actions.UPDATE_ASSET_FAILURE:
      case actions.START_SALE_SUCCESS:
      case actions.START_SALE_FAILURE:
      case actions.GET_CAROUSEL_ASSETS_SUCCESS:
      case actions.GET_CAROUSEL_ASSETS_FAILURE:
        return false;
      default:
        return state;
    }
  },
  error(state = null, action) {
    switch (action.type) {
      case actions.GET_ASSET_FAILURE:
        return action.error.message;
      case actions.GET_ASSET_REQUEST:
      case actions.GET_ASSET_SUCCESS:
        return null;
      default:
        return state;
    }
  },
  list(state = [], action) {
    switch (action.type) {
      case actions.LOAD_SUCCESS:
      case actions.ALL_SUCCESS:
      case actions.GET_CAROUSEL_ASSETS_SUCCESS:
        return action.data;
      case actions.APPROVE_SUCCESS:
      case actions.UPDATED:
      case actions.UPDATE_ASSET_SUCCESS:
        return state.map(asset =>
          asset.id === action.data.id ? action.data : asset,
        );
      case actions.ADD_SUCCESS:
        return [...state, action.data];
      case actions.START_SALE_SUCCESS:
        return state.map(asset =>
          asset.id === action.data.asset.id ? action.data.asset : asset,
        );
      default:
        return state;
    }
  },
  selectedAsset(state = DEFAULT_ASSET, action) {
    switch (action.type) {
      case actions.GET_ASSET_SUCCESS:
      case actions.ADD_SUCCESS:
        return {
          ...action.data,
          files: { ...DEFAULT_ASSET.files, ...action.data.files },
        };
      case actions.UPDATE_ASSET_SUCCESS:
        return action.data.asset;
      case actions.REMOVE_SELECTED_ASSET:
        return DEFAULT_ASSET;
      case actions.UPLOAD_FILE_SUCCESS:
      case actions.REMOVE_FILE_SUCCESS:
        return {
          ...state,
          files: action.data.files,
        };
      default:
        return state;
    }
  },
  uploadedFiles(state = null, action) {
    switch (action.type) {
      case actions.UPLOAD_FILE_SUCCESS:
      case actions.REMOVE_FILE_SUCCESS:
        return action.data.files;
      default:
        return state;
    }
  },
  isFileRemoved(state = false, action) {
    switch (action.type) {
      case actions.REMOVE_FILE_SUCCESS:
        return true;
      case actions.REMOVE_FILE_FAILURE:
      case actions.REMOVE_FILE_REQUEST:
        return false;
      default:
        return state;
    }
  },
  isFullScreenSliderOpen(state = false, action) {
    switch (action.type) {
      case actions.TOGGLE_FULLSCREEN_SLIDER:
        return action.isOpen;
      default:
        return state;
    }
  },
  activeSlide(state = 0, action) {
    switch (action.type) {
      case actions.SET_ACTIVE_SLIDE:
        return action.activeSlide;
      case actions.GET_ASSET_REQUEST:
        return 0;
      default:
        return state;
    }
  },
});
