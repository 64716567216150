import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  asset,
  activeSlide,
  isFullScreenSliderOpen,
} from '~redux/assets/selectors';
import {
  toggleOpenFullScreenSlider,
  setActiveSlide,
} from '~redux/assets/actions';

import ImageCarousel from './ImageCarousel';

const selector = createStructuredSelector({
  asset,
  activeSlide,
  isFullScreenSliderOpen,
});

const actions = { toggleOpenFullScreenSlider, setActiveSlide };

export default connect(
  selector,
  actions,
)(ImageCarousel);
