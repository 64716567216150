import React, { Component } from 'react';
import { Layout } from 'antd';
import Stepper from '../../components/Stepper';
import Form from '../../components/Assets/Form';
import Modal from '../../components/Modal';
import styles from './AddAsset.module.scss';

import { STEPS, STEP_INFO } from '~constants/assets';

import realEstate from '~image-assets/add-asset/real_estate.png';

const { Content } = Layout;

export default class AddAsset extends Component {
  state = { step: 0, isOpenModal: false };

  handleNextClick = () => {
    const { step } = this.state;

    if (step < STEPS.ASSETS_INFORMATION) this.setState({ step: step + 1 });
  };

  handlePrevClick = () => {
    const { step } = this.state;

    if (step > STEPS.TYPE) this.setState({ step: step - 1 });
  };

  handleAddClick = async asset => {
    const { addAsset } = this.props;
    await addAsset(asset);
    this.setState({ isOpenModal: true });
    this.handleNextClick();
  };

  handleCloseModal = () => {
    this.setState({ isOpenModal: false });
  };

  render() {
    const { history } = this.props;
    const { step, isOpenModal } = this.state;

    return (
      <Layout>
        <Content className={styles.content}>
          <div className={styles.header}>
            <Stepper steps={STEP_INFO} step={step} />
          </div>
          <div>
            <Form
              step={step}
              onNext={this.handleNextClick}
              onPrev={this.handlePrevClick}
              onSubmit={this.handleAddClick}
              history={history}
            />
          </div>
        </Content>
        <Modal
          img={realEstate}
          altImg="Asset logo"
          header="You've just finished creating your asset!"
          footer={null}
          mainText="Your asset will be reviewed by our team within three days. You will be notified by email when everything is ready."
          visible={isOpenModal}
          onCancel={this.handleCloseModal}
          extraClass="addAssetModal"
        />
      </Layout>
    );
  }
}
