import React, { Component } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Layout } from 'antd';
import classnames from 'classnames';

import MainHeader from '../../components/MainHeader';
import MainFooter from '../../components/MainFooter';
import LeftMenu from '../../components/LeftMenu';
import classes from './MainLayout.module.scss';
import { ROUTES } from '~constants/routes';

const { Content } = Layout;

export default class MainLayout extends Component {
  static propTypes = exact({
    children: PropTypes.element,
    pathname: PropTypes.string.isRequired,
    needSetupTwoFA: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isCompanyAdmin: PropTypes.bool.isRequired,
    isSuperAdmin: PropTypes.bool.isRequired,
    init: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  });

  componentDidMount() {
    this.props.init();
  }

  render() {
    const {
      needSetupTwoFA,
      isAuthenticated,
      isCompanyAdmin,
      isSuperAdmin,
      pathname,
      logout,
      children,
    } = this.props;

    const isHomePage = pathname === ROUTES.ADMIN_ROOT;

    const contentClass = classnames({
      [classes.content]: true,
      [classes.home]: isHomePage,
    });

    return (
      <Layout className={classes.root}>
        <MainHeader
          needSetupTwoFA={needSetupTwoFA}
          isAuthenticated={isAuthenticated}
          isSuperAdmin={isSuperAdmin}
          pathname={pathname}
          logout={logout}
        />
        <Content className={contentClass}>
          {isCompanyAdmin && !needSetupTwoFA && (
            <LeftMenu pathname={pathname} />
          )}
          {children}
        </Content>
        <MainFooter isAuthenticated={isAuthenticated} />
      </Layout>
    );
  }
}
