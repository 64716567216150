export const STEPS = {
  AMOUNT: 0,
  IBAN: 1,
  FINISH: 2,
};

export const STEP_INFO = {
  [STEPS.AMOUNT]: {
    name: 'Amount',
    title: 'Use the slider below to choose the amount.',
  },
  [STEPS.IBAN]: {
    name: 'IBAN',
    title: 'Please fill in your IBAN.',
  },
  [STEPS.FINISH]: {
    name: 'Finish',
    title: 'Thank you!',
  },
};
