import React from 'react';
import { Field } from 'redux-form';
import styles from './AssetType.module.scss';

import realEstate from '~image-assets/add-asset/real_estate.svg';
import equity from '~image-assets/add-asset/equity.svg';
import investmentFund from '~image-assets/add-asset/investment-fund.svg';
import { ASSET_TYPES } from '~constants/assets';

const options = [
  {
    id: ASSET_TYPES.REAL_ESTATE,
    label: 'Mezzanine',
    img: realEstate,
  },
  { id: ASSET_TYPES.EQUITY, label: 'Equity', img: equity },
  {
    id: ASSET_TYPES.INVESTMENT_FUND,
    label: 'Investment fund',
    img: investmentFund,
  },
];

const TypeSelect = ({ input: { onChange }, onSubmit }) =>
  options.map(option => (
    <div
      key={option.id}
      className={styles.card}
      onClick={() => {
        onChange(option.id);
        onSubmit();
      }}
    >
      <img className={styles.img} src={option.img} alt={option.label} />
      <div className={styles.label}>{option.label}</div>
    </div>
  ));

const AssetType = ({ handleSubmit }) => (
  <form className={styles.container}>
    <Field name="type" component={TypeSelect} onSubmit={handleSubmit} />
  </form>
);

export default AssetType;
