import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Tabs, Spin } from 'antd';

// import Steps from './Steps';
import AccountInformation from './AccountInformation';
import ChangePassword from './ChangePassword';
import ChangePhoneNumber from './ChangePhoneNumber';
import ResetTwoFa from './ResetTwoFa';
import Investments from './Investments';
import CloseAccount from './CloseAccount';
import Referral from './Referral';

import s from './ProfilePage.module.scss';
import { ROUTES } from '../../constants/routes';

const { Content } = Layout;
const { TabPane } = Tabs;

export default class ProfilePage extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const {
      loadContributions,
      profile: { id },
      getIbans,
    } = this.props;
    loadContributions(id);

    getIbans();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.twoFA !== this.props.twoFA && this.props.twoFA === 'success')
      window.location.reload();
  }

  handleTabChange = key => {
    this.props.history.push(key);
  };

  render() {
    const {
      profile: { firstName, lastName, status, investorType, phone, id, email },
      loading,
      list,
      isAdmin,
      resetTwoFA,
      history,
      updateUser,
      history: {
        location: { pathname },
      },
    } = this.props;

    return (
      <Spin spinning={loading} size="large">
        <Layout>
          <Content>
            <div className={s.container}>
              <div className={s.top}>
                <div className={s.row}>
                  <div className={s.bio}>
                    <p className={s.name}>{`${firstName} ${lastName}`}</p>
                    <p className={s.email}>{email}</p>
                  </div>
                </div>
                {/* <Steps kycStatus={status} /> */}
              </div>
              <div className={s.bottom}>
                <Tabs
                  className={s.tabs}
                  activeKey={pathname}
                  onChange={this.handleTabChange}
                >
                  <TabPane tab="Personal information" key={ROUTES.PROFILE_INFO}>
                    <AccountInformation
                      lastName={lastName}
                      firstName={firstName}
                      investorType={investorType}
                      status={status}
                      history={history}
                      id={id}
                      updateUser={updateUser}
                    />
                    <div className={s.half}>
                      <ChangePassword />
                      <ChangePhoneNumber phone={phone} />
                    </div>
                    {isAdmin && <ResetTwoFa resetTwoFA={resetTwoFA} />}
                    <Referral />
                    <CloseAccount />
                  </TabPane>
                  <TabPane tab="Investments" key={ROUTES.PROFILE_TRANSACTIONS}>
                    <Investments list={list} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </Content>
        </Layout>
      </Spin>
    );
  }
}
