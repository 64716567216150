import React, { Component } from 'react';
import { Button, Modal, Row } from 'antd';

import formStyle from '../../../components/Assets/Form/Form.module.scss';
import ss from './CloseAccount.module.scss';

export default class CloseAccount extends Component {
  state = {
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleDelete = async () => {
    const { closeAccount, email, logout } = this.props;

    const {
      data: {
        user: { status },
      },
    } = await closeAccount(email);

    if (status === 'CLOSED') {
      setTimeout(() => {
        logout();
      }, 1500);
    }
  };

  render() {
    const { visible } = this.state;

    return (
      <div className={ss.content}>
        <Modal
          visible={visible}
          onCancel={this.handleCancel}
          className={ss.modal}
          footer={null}
        >
          <p className={ss.title}>Delete my account</p>
          <p className={ss.row}>
            Are you sure you want to delete your account?
          </p>
          <Row className={formStyle.actions}>
            <Button
              key="CANCEL"
              onClick={this.handleCancel}
              className={formStyle.submit}
            >
              CANCEL
            </Button>
            <Button
              htmlType="button"
              className={formStyle.back}
              onClick={this.handleDelete}
            >
              Confirm
            </Button>
          </Row>
        </Modal>

        <div className={ss.row}>
          <p>Delete my account</p>
          <Button
            htmlType="button"
            className={formStyle.next}
            onClick={this.showModal}
          >
            Delete
          </Button>
        </div>
      </div>
    );
  }
}
