import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.LIST_REQUEST:
      case actions.GRANT_REQUEST:
      case actions.CREATE_CONTACT_REQUEST:
      case actions.CONTACT_FORM_REQUEST:
      case actions.ADD_INVESTOR_REQUEST:
      case actions.PROFILE_CONTACT_FORM_REQUEST:
        return true;
      case actions.LIST_SUCCESS:
      case actions.LIST_FAILURE:
      case actions.GRANT_SUCCESS:
      case actions.GRANT_FAILURE:
      case actions.CREATE_CONTACT_SUCCESS:
      case actions.CREATE_CONTACT_FAILURE:
      case actions.CONTACT_FORM_SUCCESS:
      case actions.CONTACT_FORM_FAILURE:
      case actions.ADD_INVESTOR_SUCCESS:
      case actions.ADD_INVESTOR_FAILURE:
      case actions.PROFILE_CONTACT_FORM_SUCCESS:
      case actions.PROFILE_CONTACT_FORM_FAILURE:
        return false;
      default:
        return state;
    }
  },
  selected(state = null, action) {
    switch (action.type) {
      case actions.SHOW_SUCCESS:
        return action.id;
      case actions.HIDE:
        return null;
      default:
        return state;
    }
  },
  list(state = [], action) {
    switch (action.type) {
      case actions.LIST_SUCCESS:
        return action.data;
      case actions.SHOW_REQUEST:
      case actions.GRANT_REQUEST:
        return state.map(user =>
          user.id === action.id ? { ...user, loading: true } : user,
        );
      case actions.SHOW_SUCCESS:
      case actions.GRANT_SUCCESS:
        return state.map(user => (user.id === action.id ? action.data : user));
      case actions.SHOW_FAILURE:
      case actions.GRANT_FAILURE:
        return state.map(user =>
          user.id === action.id ? { ...user, loading: false } : user,
        );
      default:
        return state;
    }
  },

  investors(state = [], action) {
    switch (action.type) {
      case actions.LIST_INVESTORS_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },

  isInvestorTypeModalOpen(state = false, action) {
    switch (action.type) {
      case actions.TOGGLE_INVETSTOR_TYPE_MODAL:
        return action.isOpen;
      default:
        return state;
    }
  },

  isMinInvestmentModalOpen(state = false, action) {
    switch (action.type) {
      case actions.TOGGLE_MIN_INVESTMENT_MODAL:
        return action.isOpen;
      default:
        return state;
    }
  },
});
