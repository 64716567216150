import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';

import {
  getDataByStatus,
  getRemainingDays,
} from '../../../../helpers/assetUtils';
import {
  STATUSES,
  ASSET_TYPES_NAMES,
  ASSET_TYPE_LABELS_RATE,
  ASSET_TYPE_LABELS_PERIOD,
} from '~constants/assets';

import mainImg from '~image-assets/marketplace/asset-cover.jpg';

import ss from './Asset.module.scss';
import formStyle from '../../../../components/Assets/Form/Form.module.scss';

class Asset extends React.Component {
  renderContent = () => {
    const {
        asset: {
          name,
          city,
          country,
          status,
          currency,
          holdingPeriod,
          grossYield,
          files,
          vestingValue,
          financed,
          beneficiary,
          type,
          id,
          duration,
          saleStartedAt,
        },
        userInvestedAssets,
      } = this.props,
      comingSoon = status === STATUSES.COMING_SOON,
      closed = status === STATUSES.ENDED,
      isOpen = !comingSoon && !closed,
      canUserView = isOpen || userInvestedAssets.includes(id);

    const coverImg =
      files.coverImage && files.coverImage[0]
        ? files.coverImage[0].url
        : mainImg;

    const { title, button, label } = getDataByStatus(status);

    const remainingSaleDays = getRemainingDays(saleStartedAt, duration);

    const isEnded = STATUSES.ENDED === status;

    return (
      <>
        <div className={ss.head}>
          {title} {beneficiary}
        </div>
        <div className={ss.type}>{ASSET_TYPES_NAMES[type]}</div>
        <div className={ss.name}>{name}</div>
        <div className={ss.divider} />
        <div className={ss.location}>
          <FontAwesomeIcon className={ss.icon} icon={faMapMarkerAlt} />
          <span>
            {city}, {country}
          </span>
        </div>
        <div className={ss.divider} />
        {!isEnded && (
          <div className={ss.price}>
            <NumberFormat
              value={vestingValue}
              displayType="text"
              thousandSeparator={true}
              prefix={`${currency} `}
            />
          </div>
        )}
        <div className={ss.imgWrap}>
          <div className={ss.arrow}>&#8594;</div>
          <span className={cn({ [ss.ended]: isEnded, [ss.sale]: !isEnded })}>
            {label}
          </span>
          <img className={ss.img} src={coverImg} alt={name} />
        </div>
        <div className={ss.footer}>
          <div className={cn(ss.half, ss.interestRate)}>
            <p>{ASSET_TYPE_LABELS_RATE[type]}</p>
            <p className={ss.green}>{grossYield}%</p>
          </div>
          <div className={ss.half}>
            <span>{ASSET_TYPE_LABELS_PERIOD[type]}</span>
            <p className={ss.green}>{holdingPeriod} months</p>
          </div>
        </div>
        {!comingSoon && (
          <div className={ss.progress}>
            <div className={ss.description}>
              <p>
                <span className={ss.bold}>{financed}%</span>financed
              </p>
              {!closed && (
                <p>
                  <span className={ss.bold}>{remainingSaleDays} </span>
                  Days left
                </p>
              )}
            </div>
            <div className={ss.line}>
              <div
                className={ss.subline}
                style={{
                  width: `${financed}%`,
                  backgroundColor: '#252930',
                }}
              />
            </div>
          </div>
        )}
        <div className={ss.details}>
          <Button
            className={cn(
              formStyle.submit,
              ss.button,
              (!canUserView || comingSoon) && ss.disabled,
            )}
          >
            {button}
          </Button>
        </div>
      </>
    );
  };

  render() {
    const {
        asset: { id, status },
        userInvestedAssets,
      } = this.props,
      { link } = getDataByStatus(status),
      comingSoon = status === STATUSES.COMING_SOON,
      isClosed = status === STATUSES.ENDED,
      isOpen = !comingSoon && !isClosed,
      canUserView = userInvestedAssets.includes(id);

    return (
      <div
        className={cn(ss.root, {
          [ss.demo]: comingSoon || (!isOpen && !canUserView),
        })}
      >
        {isOpen && (
          <Link to={link.replace(':id', id)}>{this.renderContent()}</Link>
        )}
        {comingSoon && this.renderContent()}
        {isClosed && canUserView && (
          <Link to={link.replace(':id', id)}>{this.renderContent()}</Link>
        )}
        {isClosed && !canUserView && this.renderContent()}
      </div>
    );
  }
}

export default Asset;
