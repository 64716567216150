import React, { useEffect } from 'react';

import s from '../PrivacyPolicy.module.scss';

const PrivacyText = () => {
  useEffect(() => {
    const modal = document.getElementsByClassName(
      'ant-modal-confirm-body-wrapper',
    )[0];

    if (!modal) return;

    modal.scrollTo(0, 0);
  }, []);

  return (
    <>
      <p className={s.center}>
        <strong>07/12/2021</strong>
      </p>
      <p>
        Eltenemo SA (hereafter «<strong>Eltenemo</strong>») is a company with its seat
        at Route du Manège 54A, 1965 Sion, Switzerland.
      </p>
      <p>
        Eltenemo is committed to respect your privacy and to comply with the
        provisions of the Swiss Data Protection Act (<strong>DPA</strong>) and
        the EU General Data Protection Regulation (<strong>GDPR</strong>).
      </p>
      <p>
        This privacy policy describes how Eltenemo collects, uses, transfers and
        retains the Personal Data (as defined below under section 1.1) when
        using our website («<strong>Website</strong>») and/or platform (the “
        <strong>Platform</strong>”) and/or services (“
        <strong>Services</strong>”) (hereunder together the “
        <strong>Services</strong>”) and informs you on your rights ( hereafter
        the «<strong>Privacy Policy</strong>»). If you have a question or
        request, please send us an email at the following address:
        <a href="mailto:contact@wecantokenize.com">
          {' '}
          contact@wecantokenize.com
        </a>
      </p>
      <p>
        This Privacy Policy is separate and in addition to client
        confidentiality obligations we may owe you. Please refer to our terms
        and conditions, applicable to your engagement, for further details on
        confidentiality.
      </p>
      <p>
        <strong>By using our</strong>
        <strong> Services </strong>
        <strong>
          you expressly agree that Eltenemo collect and process your Personal Data
          (as defined below), pursuant to the provisions of this Privacy Policy.
        </strong>
      </p>
      <p>
        <strong>1. The information we collect</strong>
      </p>
      <p>
        <strong>1.1 Personal Data</strong>
      </p>
      <p>
        The data collected by Eltenemo includes all information necessary for Eltenemo to
        carry out its contractual and legal obligations (“
        <strong>Personal Data</strong>”):
      </p>
      <p>
        - Any information necessary to perform our Know Your Client (KYC)
        obligations, that is personal identification information, including
        corporate name, first name, family name, date of birth, gender,
        addresses, emails, telephone numbers, nationality, citizenship,
        residency, identity documents. The Personal Data includes any document
        that you provide to us, such as a copy of a passport;
      </p>
      <p>
        - Information on relevant family members and business relationships;
      </p>
      <p>
        - Education, professional qualifications, employment and professional
        information, references;
      </p>
      <p>- Banking details;</p>
      <p>
        - Information collected by our Website as described below (see section
        1.3).
      </p>
      <p>
        <strong>1.2 Sources</strong>
      </p>
      <p>
        Personal Data is collected at different times and from different
        sources:
      </p>
      <p>
        - When you or your organization/company contact us to contemplate or
        engage our services;
      </p>
      <p>
        - Information gathered through our team in the framework of our
        contractual obligations;
      </p>
      <p>- Through email, fax or telephone communications;</p>
      <p>- Our Website.</p>
      <p>
        <strong>1.3 Cookies, tracking and other similar techniques</strong>
      </p>
      <p>
        1.3.1 In order to optimize your experience on the Website, cookies and
        similar tracking techniques may be used. These are small files that are
        saved on the web browser and that store certain configurations and data
        in the web browser to exchange them with Eltenemo’s system. These are not
        used under any circumstances to store your Personal Data.
      </p>
      <p>
        You may refuse the use of cookies in the settings of your web browser.
        Your attention is drawn to the fact that by disabling their use, you may
        not have access to the Website’s functions in full.
      </p>
      <p>
        1.3.2 The Website uses Google Analytics, which is a service provided by
        Google Inc. located in the United States of America. Google Analytics is
        a tool which analyses the use of the Website. This tool may use cookies
        to collect anonymous information and generate reports in usage
        statistics of the Website without the User being personally identifiable
        by Google. The information produced by these cookies (including the
        User’s IP address) can be sent to, and stored on, Google’s servers in
        the United States of America. Google observes the data protection
        requirements of the Swiss-US Privacy Shield Frameworks and is registered
        with the US Department of Commerce’s Privacy Shield program. If the User
        does not wish Google Analytics to record the User’s visit data, the User
        can install a browser add-on to disable Google Analytics.
      </p>
      <p>
        1.3.3 You are further informed that the Website also uses other third
        parties services like the above-mentioned Google Analytics such as Mix
        Panel, Hotjar and others.
      </p>
      <p>
        By using the Website, you declare to consent to the data processing by
        Google, Google Analytics, Mix Panel, Hotjar and others of the data
        collected regarding you, in accordance with the aforementioned and
        Google’s privacy policy. You acknowledge that Eltenemo does not assume any
        responsibility whatsoever for this process.
      </p>
      <p>
        1.3.4 The Website may use social plugins for various social network
        websites (hereinafter referred to as “<strong>Social network</strong>”),
        such as facebook.com (operated by Facebook, Inc.), twitter.com (operated
        by Twitter, Inc.) and plus.google.com (operated by Google, Inc.)
        (hereinafter referred to as the “<strong>Plugin(s)</strong>”). When the
        User visits a website that contain Plugins, the User’s browser will
        directly connect to the servers of the respective Social network. The
        content of the Plugin is directly transmitted to the User’s browser from
        each Social network and is integrated into the Website. By integrating
        the Plugin, the Social network receives the information that the User
        has visited the Website.
      </p>
      <p>
        If you are logged on a Social network, it can associate your visit to
        the account of the respective Social network. If you interact with the
        Plugin, the corresponding information from your browser is directly sent
        to the respective Social network and stored there.
      </p>
      <p>
        For more information on the purpose and scope of data collection and
        further processing and use of data by the respective Social network as
        well as your rights and ways to protect your privacy, please see the
        privacy policy of the respective Social network.
      </p>
      <p>
        <strong>2. Why we collect and process your Personal Data</strong>
      </p>
      <p>The Personal Data collected and processed by Eltenemo may be used to:</p>
      <p>(i) Contact you;</p>
      <p>(ii) Identify you;</p>
      <p>
        (iii) Perform our contractual duties regarding you or your
        organization/company;
      </p>
      <p>(iv) Conduct administrative or operational processes within Eltenemo;</p>
      <p>
        (v) Process and respond to requests, enquiries or complaints received
        from you or someone within your organization/company;
      </p>
      <p>
        (vi) Manage and administer your or your organzation/company’s business
        relationship with Eltenemo, including processing payments and billing;
      </p>
      <p>
        (vii) Comply with any legal obligation where Eltenemo is required to process
        the Personal Data, such as keeping records for legal purposes
        (anti-money laundering, tax, corporate);
      </p>
      <p>(viii) Provide, monitor and improve Eltenemo’s services;</p>
      <p>(ix) Send newsletters and marketing material;</p>
      <p>(x) Monitor the use of the Website;</p>
      <p>
        (xi) Comply with any requests of or duties towards governmental
        authorities, law enforcement agencies, regulatory bodies or courts;
      </p>
      <p>
        (xii) Establish, exercise or defend the legal rights of Eltenemo or for the
        purposes of legal proceedings;
      </p>
      <p>
        (xiii) For any other legitimate business purpose or overriding interest
        of Eltenemo.
      </p>
      <p>
        <strong>3. Sharing of information, access and transfers</strong>
      </p>
      <p>
        <strong>3.1 Access by Eltenemo</strong>
      </p>
      <p>
        The management and employees of Eltenemo may access your Personal Data to
        comply with Eltenemo’s contractual and legal duties.
      </p>
      <p>
        <strong>
          3.2 Access by service providers, business partners and others
        </strong>
      </p>
      <p>
        You hereby expressly authorise Eltenemo to use service providers and to
        delegate certain tasks (such as IT services and infrastructure, server
        location, maintenance and other services) to third parties. You further
        expressly authorise Eltenemo to transfer Personal Data to third parties in
        the event of a merger, sale, liquidation or transfer of all or a portion
        of Eltenemo’s assets. This implies that Personal Data may be communicated to
        such companies outside Switzerland.
      </p>
      <p>
        In this context, you are hereby informed that certain jurisdictions do
        not have legislation providing an appropriate level of protection within
        the meaning of the Swiss Federal Act on Data Protection. If the User’s
        Personal Data has to be shared with such companies and the other
        conditions of the DPA and/or GDPR are not met, Eltenemo will request your
        consent.
      </p>
      <p>
        <strong>
          3.3 Compliance with applicable laws and requests by authorities ;
          overriding interests of Eltenemo
        </strong>
      </p>
      <p>
        Eltenemo may transfer Personal Data to third parties such as local or foreign
        governmental authorities, law enforcement agencies, regulatory bodies or
        courts, when such transfer is reasonably necessary to comply with
        provisions of the law or a mandatory legal request or to protect an
        overriding interest of Eltenemo.
      </p>
      <p>
        <strong>4. Storage and Security</strong>
      </p>
      <p>
        Your Personal Data is entered into our electronic systems and paper
        files that are processed and stored physically and electronically on
        servers located in Switzerland.
      </p>
      <p>
        We take all reasonable and necessary measures to ensure that your
        Personal Data is processed and stored securely and in accordance with
        this Privacy Policy. Eltenemo implements appropriate administrative,
        technical and physical measures which are reasonably necessary to
        protect the confidentiality, the integrity and the availability of your
        Personal Data.
      </p>
      <p>
        <strong>5. Retaining of Personal Data</strong>
      </p>
      <p>
        We retain your Personal Data as long as necessary for the purposes
        mentioned above, to provide our Services and to satisfy our contractual
        and legal obligations (such as reporting or accounting obligations).
      </p>
      <p>
        If the Personal Data is no longer required for the execution of our
        contractual duties, it will be returned or deleted unless such data is
        necessary to implement a legal duty, in particular retention duties
        pursuant to tax or commercial law. This includes in particular the Swiss
        code of obligations, the Swiss Anti-Money Laundering Act, the Swiss VAT
        Act, and Swiss tax law.
      </p>
      <p>
        <strong>6. Your rights</strong>
      </p>
      <p>
        You have a right of <strong>access</strong> pursuant to Article 8 DPA
        (Article 15 GDPR), a right of <strong>rectification</strong> where the
        information we hold about you is inaccurate pursuant to A rticle 5 DPA
        (Article 16 GDPR), a right to request that your Personal Data be{' '}
        <strong>erased</strong> according to Article 5 LPD (Article 17 GDPR) or
        to <strong>restrict</strong> the use of your personal information
        pursuant to Articles 12, 13, 15 DPA (article 18 GDPR), a right to object
        to the processing of Personal Data pursuant to Article 4 DPA (article 21
        GDPR) and, where applicable, <strong>data portability</strong> according
        to Article 20 GDPR. You may also submit a <strong>complaint</strong>{' '}
        with the competent data protection authority if you have any concerns
        (Article 77 GDPR).
      </p>
      <p>
        If you have a question or request, please send us an email at the
        following address:{' '}
        <a href="mailto:contact@wecantokenize.com">
          contact@wecantokenize.com.
        </a>
      </p>
      <p>
        You may withdraw your consent at any time. Please note that such
        withdrawal shall only apply for future collecting/processing of your
        Personal Data and does not impact the collecting/processing which
        occurred in the past. Please note that Eltenemo may nevertheless process your
        Personal Data in presence of legal obligations or an overriding interest
        of Eltenemo, including exercising its legal rights. Withdrawing your consent
        may lead to the end of the business relationship with Eltenemo.
      </p>
      <p>
        <strong>7. Miscellaneous</strong>
      </p>
      <p>
        Please inform us as soon as possible if any of your Personal Data
        changes (including your correspondence details). Failure to provide
        accurate information or to update information when it changes may have a
        detrimental impact upon our ability to provide our services.
      </p>
      <p>
        You are liable for the accuracy and extensiveness of the data
        transferred to Eltenemo and for providing updates should your Personal Data
        change.
      </p>
      <p>
        Eltenemo cannot be held responsible for any situation that may arise due to
        us having any inaccurate, incomplete, inauthentic or otherwise deficient
        personal data that you or a third party has provided to us.
      </p>
      <p>
        This Privacy Policy may be amended at any time. Eltenemo shall inform you of
        any such amendments.
      </p>
    </>
  );
};

export default PrivacyText;
