export const ALLOWED_HTML_TAGS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'br',
  'hr',
  'abbr',
  'address',
  'b',
  'blockquote',
  'cite',
  'code',
  'i',
  'em',
  'strong',
  'p',
  'pre',
  'q',
  's',
  'small',
  'strong',
  'sub',
  'sup',
  'template',
  'time',
  'u',
  'img',
  'svg',
  'video',
  'source',
  'picture',
  'figure',
  'canvas',
  'audio',
  'track',
  'a',
  'ul',
  'ol',
  'li',
  'dl',
  'dt',
  'dd',
  'table',
  'thead',
  'tbody',
  'th',
  'tr',
  'td',
  'tfoot',
  'caption',
  'col',
  'colgroup',
  'style',
  'div',
  'span',
  'section',
  'article',
  'summary',
  'details',
];

export const ALLOWED_HTML_ATRIBUTES = {
  a: ['href'],
  img: ['src', 'alt'],
  video: ['control', 'autoplay', 'name'],
  audio: ['control', 'autoplay', 'name'],
  source: ['src', 'type'],
};
