import React, { Component } from 'react';
import ss from './Dashboard.module.scss';
import cn from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Chart from './Chart';
import logo from '../../assets/images/logos/EFIKO_Logo_FINAL.png';
import { ROUTES } from '../../constants/routes';

const TOKEN_NAME = 'token';

const portfolio = {
  IVZ: 12,
  GBP: 25,
};

const getSign = number =>
  new Map([[-1, ''], [1, '+'], [0, '']]).get(Math.sign(number)) || '';

const getClass = number =>
  new Map([[-1, ss.negative], [1, ss.positive], [0, ss.neutral]]).get(
    Math.sign(number),
  ) || ss.neutral;

export default class Dashboard extends Component {
  componentDidMount() {
    const { getTickerData } = this.props;
    getTickerData();
  }

  render() {
    const { ticker } = this.props;
    return (
      <div className={ss.wrapper}>
        <div className={ss.container}>
          <div className={cn(ss.dashboard, ss.divider)}>
            <div className={ss.marketBlock}>
              <div className={cn(ss.marketData, ss.divider)}>
                <div>
                  <span className={ss.ticker}>{ticker.id}</span>
                  <span className={ss.companyName}>{ticker.longName}</span>
                </div>
                <div>
                  <div className={ss.main}>
                    {ticker.price} <span className={ss.currency}>EUR</span>
                  </div>
                  <span className={ss.sub}>
                    {moment(ticker.lastUpdate).format('DD/MM/YYYY')}
                  </span>
                </div>
                <div
                  className={cn(
                    ss.oneDayChange,
                    getClass(ticker.priceChange1Day),
                  )}
                >
                  <span className={ss.main}>
                    {getSign(ticker.percentChange1Day)}{' '}
                    {(ticker.percentChange1Day || 0).toFixed(2)} %
                  </span>
                  <span className={ss.sub}>
                    {getSign(ticker.priceChange1Day)}{' '}
                    {(ticker.priceChange1Day || 0).toFixed(2)}
                  </span>
                </div>
              </div>

              <div className={cn(ss.block, ss.divider)}>
                <span className={ss.title}>Open</span>
                <span className={ss.value}>{ticker.open || '- -'}</span>
              </div>
              <div className={cn(ss.block, ss.divider)}>
                <span className={ss.title}>Prev close</span>
                <span className={ss.value}>
                  {ticker.previousClosingPriceOneTradingDayAgo}
                </span>
              </div>
              <div className={cn(ss.block, ss.divider)}>
                <span className={ss.title}>Volume</span>
                <span className={ss.value}>{ticker.volume}</span>
              </div>

              <div className={ss.block}>
                <span className={ss.title}>Share class</span>
                <span className={ss.value}>{ticker.shareClass}</span>
              </div>
              <div className={ss.block}>
                <span className={ss.title}>Geographic focus</span>
                <span className={ss.value}>{ticker.fundAssetClassFocus}</span>
              </div>
              <div className={ss.block}>
                <span className={ss.title}>52 week range</span>
                <span className={ss.value}>
                  {ticker.lowPrice52Week} - {ticker.highPrice52Week}
                </span>
              </div>
            </div>

            <div className={ss.portfolio}>
              <div className={cn(ss.header, ss.divider)}>Your portfolio</div>
              <div className={ss.portfolioBlock}>
                <div className={ss.portfolioTicker}>
                  <img className={ss.logo} src={logo} alt="Logo" />
                  <span>{TOKEN_NAME}</span>
                </div>
                <div className={ss.amounts}>
                  <span className={ss.tokenAmount}>
                    {portfolio.IVZ.toLocaleString('fr-CH', {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                  <span className={ss.fiatAmount}>{portfolio.GBP} £</span>
                </div>
              </div>
              <Link to={ROUTES.PROFILE_TRANSACTIONS} className={ss.link}>
                Access to your transaction history >
              </Link>
            </div>

            <Chart />
          </div>
        </div>
      </div>
    );
  }
}
