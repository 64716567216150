import { combineReducers } from 'redux';

import profile from './profile/reducer';
import form from './forms/reducer';
import { connectRouter } from './router/reducer';
import users from './users/reducer';
import assets from './assets/reducer';
import contributions from './contributions/reducer';
import notify from './notify/reducer';
import dashboard from './dashboard/reducer';

export default history =>
  combineReducers({
    form,
    users,
    assets,
    notify,
    profile,
    contributions,
    router: connectRouter(history),
    dashboard,
  });
