import React, { Component } from 'react';
import { Button, Tooltip } from 'antd';

import formStyle from '../../../components/Assets/Form/Form.module.scss';
import s from './AccountInformation.module.scss';
import { ROUTES } from '~constants/routes';
import {
  USER_STATUS,
  INVESTOR_TYPE_LABELS,
  SWITCHED_INVESTOR_TYPE,
} from '~constants/users';

export default class AccountInformation extends Component {
  handleClick = async () => {
    const { status, history, updateUser, investorType } = this.props;

    if (status.includes('KYC')) {
      return history.push(ROUTES.CONTACT_US);
    }

    await updateUser({
      investorType: SWITCHED_INVESTOR_TYPE[investorType],
      notificationMessage: `Your investor type was changed to ${[
        INVESTOR_TYPE_LABELS[SWITCHED_INVESTOR_TYPE[investorType]],
      ]}`,
    });
  };

  render() {
    const { lastName, firstName, investorType, status } = this.props;

    const hasWhitelistingStarted = status !== USER_STATUS.ACTIVE;

    return (
      <div className={s.content}>
        <h3>Account information</h3>
        <div className={s.row}>
          <div>
            <p className={s.title}>First Name</p>
            <p className={s.name}>{firstName}</p>
          </div>
          <div className={s.lastName}>
            <p className={s.title}>Last Name</p>
            <p className={s.name}>{lastName}</p>
          </div>
          <div>
            <p className={s.title}>Type of investor</p>
            <p className={s.name}>{INVESTOR_TYPE_LABELS[investorType]}</p>
          </div>
          <Tooltip
            title={
              hasWhitelistingStarted
                ? 'You will be redirected to the contact form, if you have already started the whitelisting!'
                : ''
            }
            trigger="hover"
          >
            <Button
              htmlType="button"
              className={formStyle.next}
              onClick={this.handleClick}
            >
              Switch to{' '}
              {[INVESTOR_TYPE_LABELS[SWITCHED_INVESTOR_TYPE[investorType]]]}
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  }
}
