import React from 'react';
import cn from 'classnames';

import styles from './List.module.scss';

const List = ({ list, transparent }) => (
  <div className={cn(styles.list, transparent ? styles.transparent : null)}>
    {list.map(({ title, img, content }) => (
      <div className={styles.item} key={title}>
        <img className={styles.image} src={img} alt={title} />
        <div className={styles.title}>{title}</div>
        <div className={styles.divider} />
        <ul className={styles.content}>
          {content.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    ))}
  </div>
);

export default List;
