import { takeEvery, put } from 'redux-saga/effects';
import { PROFILE_FAILURE } from '../profile/actions';
import { putNotification } from './actions';
import { notification } from 'antd';

const DEFAULT_TYPE = 'USER_FLOW';

const toString = obj =>
  Object.entries(obj)
    .map(([key, val]) => `${key}: ${val}`)
    .join(', ');

function* notifyAction(action) {
  if (action.data && action.data.success) {
    const { message, description, type = DEFAULT_TYPE } = action.data.success;

    notification.success({ message, description }, 7);
    yield put(putNotification({ message, description, type }));
  }

  /*if (action.error && action.type !== PROFILE_FAILURE) {
    const { message, description, fields, type = DEFAULT_TYPE } = action.error;

    notification.error(
      {
        message: message || toString(fields),
        description,
      },
      7,
    );
    yield put(putNotification({ message, description, type }));
  }*/
}

export function* notify() {
  yield takeEvery('*', notifyAction);
}
