import React from 'react';
import { Modal, Button } from 'antd';
import cn from 'classnames';

import formStyle from '../../../../../components/Assets/Form/Form.module.scss';
import s from '../../Asset.module.scss';
import { USER_STATUS } from '~constants/users';
import { ROUTES } from '~constants/routes';

const MinInvestmentModal = ({
  isMinInvestmentModalOpen,
  toggleMinInvestmentModal,
  toggleInvestorTypeModal,
  sendApplicantRequest,
  history,
  updateUser,
  profile,
}) => {
  const handleMinInvestmentOk = async () => {
    await sendApplicantRequest(profile);

    updateUser({ status: USER_STATUS.KYC_PENDING });

    history.push(ROUTES.ONBOARDING);
  };

  const handleClick = async () => {
    toggleMinInvestmentModal(false);

    await handleMinInvestmentOk();
  };

  const handleCancel = () => {
    toggleInvestorTypeModal(true);

    toggleMinInvestmentModal(false);
  };

  return (
    <Modal
      visible={isMinInvestmentModalOpen}
      footer={null}
      className={s.modal}
      onCancel={handleCancel}
    >
      <div className={s.wrapper}>
        <h3 className={s.title}>Qualified investor info</h3>

        <p className={s.text}>
          A qualified
          <b> (experienced/well-informed/professional) investor </b>
          is a high-net-worth <b>investor</b> who possesses the experience,
          knowledge and expertise to make its own investment decisions and
          properly assess the risks that it incurs.
        </p>
        <p className={s.text}>
          I confirm that my intention is to invest at least 125 000 euros and I
          understand that I will be considered a well-informed investor.
        </p>
        <p className={cn(s.text, s.red)}>
          This investment proposal is not opened to the public. We accept only
          qualified investors at the moment.
        </p>
        <div className={s.buttons}>
          <Button onClick={handleCancel} className={cn(formStyle.back, s.btn)}>
            Cancel
          </Button>
          <Button onClick={handleClick} className={cn(formStyle.submit, s.btn)}>
            I confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MinInvestmentModal;
