import { connect } from 'react-redux';
import { getCarouselAssets } from '~redux/assets/actions';
import Home from './Home';

const selector = null;

const actions = { getCarouselAssets };

export default connect(
  selector,
  actions,
)(Home);
