import React, { Component } from 'react';
import { Form, Button, Row } from 'antd';
import { TextField } from 'redux-form-antd';
import { Field } from 'redux-form';
import cn from 'classnames';
import {
  required,
  minLength,
  email,
  phone,
} from '~redux/redux-form-validators';
import PhoneInput from '../../../../components/PhoneInput';

import formStyle from '../../../../components/Assets/Form/Form.module.scss';
import styles from '../RightForm/RightForm.module.scss';

export default class PersonalInfo extends Component {
  render() {
    const { handleSubmit, valid } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Item className={styles.input}>
          <Field
            name="firstName"
            type="text"
            label={
              <label>
                <span>First name </span>
                <span className={styles.required}>*</span>
              </label>
            }
            placeholder="Enter your first name"
            required
            component={TextField}
            validate={[required, minLength(2)]}
          />
        </Form.Item>
        <Form.Item className={styles.input}>
          <Field
            name="lastName"
            type="text"
            label={
              <label>
                <span>Last name </span>
                <span className={styles.required}>*</span>
              </label>
            }
            placeholder="Enter your last name"
            required
            component={TextField}
            validate={[required, minLength(2)]}
          />
        </Form.Item>
        <Form.Item className={styles.input}>
          <Field
            name="email"
            type="text"
            label={
              <label>
                <span>Email name </span>
                <span className={styles.required}>*</span>
              </label>
            }
            placeholder="name@company.com"
            required
            component={TextField}
            validate={[required, email]}
          />
        </Form.Item>
        <Form.Item className={cn(styles.phoneInput, styles.optionalLabel)}>
          <Field
            name="phone"
            type="text"
            label={
              <>
                <span>Phone</span>
                <span className={styles.optional}>Optional</span>
              </>
            }
            placeholder="+41"
            required
            component={PhoneInput}
            extraClass="signup"
            validate={[phone, minLength(8)]}
          />
        </Form.Item>
        <Form.Item className={styles.input}>
          <Field
            name="password"
            type="password"
            placeholder="********"
            label={
              <label>
                <span>Choose a password </span>
                <span className={styles.required}>*</span>
              </label>
            }
            required
            component={TextField}
            validate={[required, minLength(8)]}
          />
        </Form.Item>
        <Form.Item className={styles.input}>
          <Field
            name="confirmation_password"
            type="password"
            placeholder="********"
            label={
              <label>
                <span>Confirm your password </span>
                <span className={styles.required}>*</span>
              </label>
            }
            required
            component={TextField}
            validate={[required, minLength(8)]}
          />
        </Form.Item>

        <Row className={cn(formStyle.actions, styles.actions)}>
          <Button
            className={formStyle.next}
            htmlType="submit"
            disabled={!valid}
          >
            Next
          </Button>
        </Row>
      </Form>
    );
  }
}
