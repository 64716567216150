import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { list } from '~redux/assets/selectors';
import Carousel from './Carousel';

const selector = createStructuredSelector({
  list,
});

export default connect(
  selector,
  null,
)(Carousel);
