import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Layout } from 'antd';
import { Table } from 'antd';
import styles from './Investors.module.scss';
import { ROUTES } from '~constants/routes';

const { Content } = Layout;
const { Column } = Table;

class Investors extends Component {
  componentWillMount() {
    this.props.listInvestors();
  }

  render() {
    const { loading, investors } = this.props;
    return (
      <Layout>
        <Content className={styles.content}>
          <h1 className={styles.title}>Investors</h1>
          <h2 className={styles.heading}>
            Here you can see the list of your investors
          </h2>
          <div className={styles.tWrapper}>
            <Table
              rowKey="id"
              size="small"
              loading={loading}
              dataSource={investors}
              pagination={false}
              className={styles.table}
            >
              <Column title="name" dataIndex="sumsubName" />
              <Column title="email" dataIndex="email" />
              <Column title="country" dataIndex="sumsubCountry" />
              <Column title="city" dataIndex="sumsubCity" />
              <Column title="address" dataIndex="sumsubAddress" />
            </Table>
            <Link to={ROUTES.ADD_INVESTOR} className={styles.container}>
              <Button
                className={styles.button}
                type="primary"
                shape="circle"
                icon="plus"
                size="large"
              />
            </Link>
          </div>
        </Content>
      </Layout>
    );
  }
}

export default Investors;
