import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { ROUTES } from '~constants/routes';

import ss from '../../Contact.module.scss';
import formStyle from '../../../../Assets/Form/Form.module.scss';

const KycPending = () => {
  return (
    <>
      <h1 className={ss.title}>Are you ready to start investing?</h1>
      <Link to={ROUTES.ONBOARDING}>
        <Button className={cn(ss.button, formStyle.submit)} htmlType="button">
          Pending Approval
        </Button>
      </Link>
    </>
  );
};

export default KycPending;
