import React from 'react';
import AssetType from './AssetType';
import AssetSetting from './AssetSetting';
import AssetInformation from './AssetInformation';
import BankInfo from './BankInfo';
import { STEPS, STEP_INFO } from '~constants/assets';

import styles from './Form.module.scss';

const steps = {
  [STEPS.TYPE]: AssetType,
  [STEPS.ASSETS_SETTINGS]: AssetSetting,
  [STEPS.BANK_INFO]: BankInfo,
  [STEPS.ASSETS_INFORMATION]: AssetInformation,
};

class Form extends React.Component {
  render() {
    const {
      step,
      onNext,
      onPrev,
      onSubmit,
      history,
      fieldDisabled,
    } = this.props;
    const Step = steps[step];
    const isLastStep = step === STEPS.BANK_INFO;

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          {`STEP ${step + 1} OF ${Object.values(STEPS).length}`}
        </div>
        <h1 className={styles.title}>{Object.values(STEP_INFO)[step].title}</h1>
        <div className={styles.body}>
          <Step
            onSubmit={isLastStep ? onSubmit : onNext}
            onCancel={onPrev}
            history={history}
            fieldDisabled={fieldDisabled}
          />
        </div>
      </div>
    );
  }
}

export default Form;
