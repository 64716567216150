import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ClosedAsset from './ClosedAsset';

import { getAsset } from '~redux/assets/actions';
import { asset } from '~redux/assets/selectors';
import { profile } from '~redux/profile/selectors';

import logo from '~image-assets/logos/default-logo.jpg';

import { userInvestedAssets, loading } from '~redux/contributions/selectors';
import { loadContributions } from '~redux/contributions/actions';

const media = () => ({ logo });

const selector = createStructuredSelector({
  asset,
  media,
  profile,
  userInvestedAssets,
  loading,
});

const actions = {
  getAsset,
  loadContributions,
};

export default connect(
  selector,
  actions,
)(ClosedAsset);
