import React from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import s from '../Subscription.module.scss';

// TODO: remove the file completely?

const SubscriptionFormText = ({
  saleStartedAt,
  tokenName,
  username,
  email,
  amount,
  iban,
  currency,
}) => {
  const [day, monthYear] = moment(saleStartedAt)
    .format('Do/MMMM YYYY')
    .split('/');

  return (
    <>
      <p className={s.subscriptionTextPar} align="center">
        <strong>
          SUBSCRIPTION FORM FOR TOKENIZED NOTES ISSUED BY WCT (LUXEMBOURG) S.A.
        </strong>
      </p>
      <p className={s.subscriptionTextPar} align="right">
        Luxembourg, {day.substr(0, 2)}
        <sup>{day.substr(2)}</sup> of {monthYear}
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        On 31<sup>st</sup> of October 2019, WCT (Luxembourg) S.A., a public
        limited liability company (<em>société anonyme</em>) incorporated under
        the laws of the Grand Duchy of Luxembourg as an unregulated
        securitisation company (<em>société de titrisation</em>) within the
        meaning of the law relating to securitisation of 22 March 2004, as
        amended, with registered office at 2 Place de Strasbourg, L-2562
        Luxembourg, Grand Duchy of Luxembourg and registered with the Luxembourg
        register of trade and companies under number B238739, acting in respect
        of its compartment {tokenName.substr(-1, 1)} (hereinafter the “
        <strong>Issuer</strong>”) has approved a programme memorandum for a EUR
        100’000’000 (one hundred million) Token Based Note Programme (the “
        <strong>Programme</strong>”).
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        Any person who will be signing this subscription form (the “
        <strong>Subscription Form</strong>”) will be hereinafter referred to as
        a “<strong>Subscriber</strong>”.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        Within the Programme, which includes the terms and conditions, the
        Issuer intends to issue tokenized notes (the “
        <strong>Tokenized Notes</strong>”). The Programme shall be read together
        with the series memorandum dated 04<sup>th</sup> of November 2019 (the “
        <strong>Series Memorandum</strong>”) which sets out the specific issue
        terms of the offer of the Tokenized Notes (the “
        <strong>Issue Terms</strong>”) and the terms of the offer of the tokens
        associated with the Tokenized Notes and referred to in the Issue Terms
        as <strong>{tokenName}</strong> (the “<strong>Tokens</strong>”)
        <strong>.</strong>
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        If there is any conflict between the terms and conditions of the
        Programme and the Issue Terms and the terms of this Subscription Form,
        the Subscription Form shall take priority to the extent it clarifies
        specific rights and obligations of the parties.
      </p>
      <h2 lang="en-US">
        <br />
      </h2>
      <h2>
        <strong>WHEREAS</strong>
      </h2>
      <p className={s.subscriptionTextPar} align="justify">
        (1) The Issuer wishes to offer to the Subscriber the number of Tokenized
        Notes set out in Schedule 1 together with the corresponding number of
        Tokens associated therewith in accordance with the terms and conditions
        of the Programme and the Issue Terms set forth in the Series Memorandum.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        (2) The Subscriber accepts and irrevocably agrees to subscribe to the
        Tokenized Notes for the amount set out in Schedule 1.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        (3) The Subscriber hereby acknowledges to have read and understood the
        Programme and the Series Memorandum and hereby accepts the terms and
        conditions of the Tokenized Notes set out in the Programme and the
        Series Memorandum which are incorporated by reference into and form
        integral part of this Subscription Form.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        (4) The Subscriber hereby confirms having successfully completed the
        onboarding process on the website indicated by the Issuer in the
        Programme and provided all the necessary information to receive the
        Tokens (including but not limited to wallet information and Ethereum
        address) and further confirms that she/he/it will provide necessary
        additional information as may be required.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        <strong>1. Subscription and commitment</strong>
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        1.1 In exchange for the payment by the Subscriber of the sum and date
        indicated in Schedule 1 (the “<strong>Contributed Amount</strong>”), the
        Issuer hereby offers to issue to the Subscriber the number of Tokenized
        Notes indicated in Schedule 1 and to assign to the Public Key of the
        Subscriber the number of Tokens associated with the Tokenized Notes as
        such information is provided in Schedule 1.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        1.2 By signing this Subscription Form, the Subscriber hereby irrevocably
        undertakes and commits to subscribe to the number Tokenized Notes set
        out in Schedule 1 and pay the Contributed Amount to Issuer on the date
        specified in paragraph 1.1.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        <strong>2. Representations by the Subscriber</strong>
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        2.1 The Subscriber hereby represents that she/he/it has full legal
        capacity, power and authority to execute and deliver this Subscription
        Form and to perform her/his/its obligations hereunder.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        2.2 The Subscriber is entering into this Subscription Form for
        her/his/its own account and on her/his/its own behalf, not as a nominee
        or agent, and not with a view to, or for resale in connection with, the
        distribution thereof, and the Subscriber has no present intention of
        selling, granting any participation in, or otherwise distributing the
        same. The Subscriber has such knowledge and experience in financial and
        business matters that the Subscriber is capable of evaluating the merits
        and risks of such transaction, is able to incur a complete loss of the
        full amount without impairing the Subscriber’s financial condition and
        is able to bear the economic risk of such transaction for an indefinite
        period of time.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        <strong>3. Notices</strong>
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        3.1 All notices to the Subscriber in relation to the Tokenized Notes
        and/or the Tokens (as applicable) shall be made in writing by email at
        the email address of the Subscriber indicated in Schedule 1.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        3.2 All notices to the Issuer in relation to the Tokenized Notes and/or
        the Tokens (as applicable) shall be made in writing by registered mail
        at the address below and to the attention of the board of directors of
        the Issuer or at the following email address:{' '}
        <a href="mailto:contact@wecantokenize.com">contact@wecantokenize.com</a>
      </p>
      <p>
        <strong>To the Issuer: </strong>
      </p>
      <p>Address: 2 Place de Strasbourg, L-2562 Luxembourg</p>
      <p>Attention: The board of directors</p>
      <p>
        Email:{' '}
        <a href="mailto:contact@wecantokenize.com">contact@wecantokenize.com</a>
      </p>
      <br />
      <p className={s.subscriptionTextPar} align="justify">
        <strong>4. Method of Payment</strong>
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        <strong> </strong>
        The payment of the Contributed Amount by the Subscriber shall be paid by
        wire transfer to the following bank account of the Issuer:
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        IBAN account number: {iban}
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        Swift BIC code: BCEELULL
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        Bank: Banque et Caisse d’Epargne de l’Etat, Luxembourg
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        In favour of: WCT (Luxembourg) S.A., compartment{' '}
        {tokenName.substr(-1, 1)}
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        <strong>5. Miscellaneous </strong>
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        5.1 Neither this Subscription Form nor the rights contained herein may
        be assigned, by operation of law or otherwise, by either party without
        the prior written consent of the other.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        5.2 In the event any one or more of the provisions of this Subscription
        Form is for any reason held to be invalid, illegal or unenforceable, in
        whole or in part or in any respect, or in the event that any one or more
        of the provisions of this Subscription Form operate or would
        prospectively operate to invalidate this Subscription Form, then and in
        any such event, such provision(s) only will be deemed null and void and
        will not affect any other provision of this Subscription Form and the
        remaining provisions of this Subscription Form will remain operative and
        in full force and effect and will not be affected, prejudiced, or
        disturbed thereby.
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        <strong>6. Governing law and jurisdiction</strong>
      </p>
      <p className={s.subscriptionTextPar} align="justify">
        This Subscription Form, and all rights and obligations hereunder, will
        be governed by and construed in accordance with the laws of Grand Duchy
        of Luxembourg and the courts of Grand Duchy of Luxembourg shall have
        exclusive jurisdiction to settle any dispute arising in connection with
        this Subscription Form.
      </p>
      <p>
        <strong>
          By confirming this Subscription Form, the Subscriber hereby agrees to
          be bound by its terms and irrevocably subscribes to the Tokenized
          Notes.
        </strong>
      </p>

      <br />

      <p>
        <strong>SCHEDULE I: Tokenized Notes and Token information</strong>
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              <p>Date of subscription</p>
            </td>
            <td>
              <p>{moment().format('DD MMM YYYY')}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Notice to the Subscriber</p>
            </td>
            <td>
              <p>
                <strong>To the Subscriber</strong>:
              </p>
              <p>Attention: To {username}</p>
              <p>Email: {email}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contributed Amount</p>
            </td>
            <td>
              <p>
                <NumberFormat
                  value={amount}
                  displayType="text"
                  thousandSeparator={true}
                  prefix={`${currency} `}
                />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SubscriptionFormText;
