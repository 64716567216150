import React, { Component } from 'react';
import Table from './Table';
import { Layout, Spin } from 'antd';

import styles from './Table/Table.module.scss';

const { Content } = Layout;

const colNames = [
  'Asset',
  'Name',
  'IBAN',
  'Reference number',
  'Amount',
  'Actions',
];

const TblDivider = ({ cols = [] }) => {
  // create empty blocks of appropriate width and color if columns weren't passed to the Divider
  const columns = cols.length ? cols : Array.from(Array(colNames.length));

  return (
    <div className={styles.thead}>
      {columns.map((el, idx) =>
        el === 'IBAN' ? (
          <div key="IBAN">
            <p>IBAN</p>
          </div>
        ) : (
          <div key={el || idx}>
            <p>{el}</p>
          </div>
        ),
      )}
    </div>
  );
};

export default class Contributions extends Component {
  componentDidMount() {
    const {
      profile: { id },
      loadByOwner,
    } = this.props;
    loadByOwner(id);
  }

  render() {
    const { list, loading } = this.props;
    return (
      <Layout>
        <Content className={styles.content}>
          <Spin spinning={loading} size="large">
            <h1 className={styles.title}>Contributions management</h1>
            <div className={styles.tcontainer}>
              <TblDivider cols={colNames} />
              <Table
                list={list.filter(
                  contribution => contribution.status === 'PENDING',
                )}
                status="Pending"
                className="tPending"
              />
              <TblDivider />
              <Table
                list={list.filter(
                  contribution => contribution.status === 'APPROVED',
                )}
                status="Approved"
                className="tApproved"
              />
              <TblDivider />
              <Table
                list={list.filter(
                  contribution => contribution.status === 'DECLINED',
                )}
                status="Declined"
                className="tDeclined"
              />
            </div>
          </Spin>
        </Content>
      </Layout>
    );
  }
}
