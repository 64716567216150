import { connect } from 'socket.io-client';

import { UPDATED } from '../assets/actions';

const handlers = {
  UPDATED_ASSET: UPDATED,
};

class Ws {
  constructor(token, dispatch) {
    this.io = connect(
      '/',
      { query: { token: `Bearer ${token}` } },
    );
    this.dispatch = dispatch;

    Object.entries(handlers).forEach(([name, type]) =>
      this.io.on(name, data => this.dispatch({ type, data })),
    );
  }
}

export default function wsMiddleware(api) {
  return ({ dispatch }) => {
    new Ws(api.client.token, dispatch);
    return next => action => next(action);
  };
}
