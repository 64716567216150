import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import EmailForm from '../../../../EmailForm';
import { createStructuredSelector } from 'reselect';

import { createContact } from '~redux/users/actions';
import { assetFormValues } from '~redux/users/selectors';

import { compose } from '~utils';

const source = () => 'Efiko website';
const selectors = createStructuredSelector({
  formValues: assetFormValues,
  source,
});

const actions = {
  onSubmit: createContact,
};

const reduxConnect = connect(
  selectors,
  actions,
);

const formConect = reduxForm({
  form: 'assetContact',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
});

export default compose(
  reduxConnect,
  formConect,
)(EmailForm);
