import React from 'react';
import { Form, Button, Row, Slider } from 'antd';
import { Field } from 'redux-form';
import { TextField } from 'redux-form-antd';
import { createNumberMask } from 'redux-form-input-masks';

import formStyle from '../../Assets/Form/Form.module.scss';
import styles from './Amount.module.scss';

import {
  required,
  positiveNumber,
  minValue,
  maxValue,
  divisibleBy,
} from '~redux/redux-form-validators';
import {
  ASSET_TYPE_LABELS_RATE,
  ASSET_TYPE_LABELS_PERIOD,
} from '~constants/assets';
import { ROUTES } from '~constants/routes';

const currencyMask = prefix =>
  createNumberMask({
    prefix: `${prefix} `,
    decimalPlaces: 0,
    locale: 'en-US',
  });

class Amount extends React.Component {
  constructor(props) {
    super(props);

    const {
      getAsset,
      asset: { minInvestment },
      change,
    } = props;

    const assetId = window.location.pathname.split('/').pop();

    getAsset(assetId);

    change('amount', minInvestment);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      change,
      asset: { minInvestment },
    } = this.props;

    if (minInvestment !== prevProps.asset.minInvestment) {
      change('amount', minInvestment);
    }
  }

  handleSliderChange = val => {
    const value = Number(val);
    this.props.change('amount', value);
  };

  handleBack = () => {
    const {
      history: { push },
      asset: { id },
    } = this.props;
    return push(ROUTES.PROJECT.replace(':id', id));
  };

  render() {
    const {
      asset: {
        name,
        city,
        country,
        grossYield,
        currency,
        holdingPeriod,
        type,
        minInvestment,
        vestingValue,
        investedAmount,
      },
      amount,
      valid,
      onSubmit,
    } = this.props;

    return (
      <>
        <div className={styles.top}>
          <h4>You are interested to invest in:</h4>
          <div className={styles.info}>
            <p className={styles.title}>{name}</p>
            <p className={styles.location}>
              {city}, {country}
            </p>
            <div className={styles.divider} />
            <div className={styles.estimation}>
              <p>
                {ASSET_TYPE_LABELS_RATE[type]}: {grossYield}%
              </p>
              <p>
                {ASSET_TYPE_LABELS_PERIOD[type]}: {holdingPeriod} months
              </p>
            </div>
          </div>
        </div>
        <Form onSubmit={onSubmit}>
          <div className={styles.form}>
            <Form.Item>
              <p className={styles.label}>
                How much are you willing to invest?
              </p>
              <Slider
                name="sliderValue"
                tipFormatter={val => `${currency} ${val.toLocaleString()}`}
                min={minInvestment}
                max={vestingValue - investedAmount}
                step={5000}
                tooltipVisible
                tooltipPlacement={
                  amount < vestingValue * 0.75 ? 'bottomLeft' : 'bottomRight' // changes placement if amount is > 75% to prevent going out of bounds
                }
                onChange={this.handleSliderChange}
                value={amount}
              />
            </Form.Item>
            <Form.Item className={styles.input}>
              <Field
                name="amount"
                type="text"
                label="You can also specify your amount"
                placeholder={`${currency} ${amount}`}
                component={TextField}
                validate={[
                  required,
                  positiveNumber,
                  minValue(minInvestment),
                  maxValue(vestingValue),
                  divisibleBy(5000),
                ]}
                {...currencyMask(currency)}
              />
            </Form.Item>
          </div>
          <Row className={formStyle.actions}>
            <Button onClick={this.handleBack} className={formStyle.back}>
              go back
            </Button>
            <Button
              disabled={!valid}
              className={formStyle.next}
              htmlType="submit"
            >
              continue
            </Button>
          </Row>
        </Form>
      </>
    );
  }
}

export default Amount;
