import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Spin } from 'antd';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
};

class GuestRoute extends React.PureComponent {
  render() {
    const {
      loading,
      isAdmin,
      isAuthenticated,
      needSetupTwoFASelector,
      redirectLoggedInUserUrl,
      component: Component,
      location,
      ...rest
    } = this.props;

    if (loading) return <Spin size="large" style={style} />;

    if (isAuthenticated && !needSetupTwoFASelector)
      return <Redirect to={{ pathname: redirectLoggedInUserUrl }} />;

    return <Route component={Component} {...rest} />;
  }
}

export default GuestRoute;
