import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import {
  loading,
  isAuthenticated,
  needSetupTwoFASelector,
} from '~redux/profile/selectors';
import { createStructuredSelector } from 'reselect';

const selector = createStructuredSelector({
  isAuthenticated,
  isTwoFANeeded: needSetupTwoFASelector,
  loading,
});

export default withRouter(
  connect(
    selector,
    null,
  )(PrivateRoute),
);
