import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { investmentFormValues } from '~redux/users/selectors';
import { asset } from '~redux/assets/selectors';
import { closeSale } from '~redux/assets/actions';
import { profile, investorData } from '~redux/profile/selectors';
import { addContribution } from '~redux/contributions/actions';
import { addInvestor } from '~redux/users/actions';

import { compose } from '~utils';

import Transfer from './Transfer';

const selectors = createStructuredSelector({
  formValues: investmentFormValues,
  asset,
  profile,
  investorData,
});

const actions = { addContribution, addInvestor, closeSale };

const reduxConnect = connect(
  selectors,
  actions,
);

const formConect = reduxForm({
  form: 'transferForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default compose(
  reduxConnect,
  formConect,
)(Transfer);
