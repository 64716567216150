import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Active from './Active';

import { sendApplicantRequest } from '~redux/profile/actions';
import { toggleInvestorTypeModal } from '~redux/users/actions';

import { profile } from '~redux/profile/selectors';

const selector = createStructuredSelector({
  profile,
});

const actions = {
  sendApplicantRequest,
  toggleInvestorTypeModal,
};

export default connect(
  selector,
  actions,
)(Active);
