import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Form from './Form';

import { getAsset } from '~redux/assets/actions';

import { compose } from '~utils';

const actions = { getAsset };

const reduxConnect = connect(
  null,
  actions,
);

const formConect = reduxForm({
  form: 'investmentForm',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
});

export default compose(
  reduxConnect,
  formConect,
)(Form);
