import { connect } from 'react-redux';

import { confirm } from '~redux/profile/actions';

import ConfirmEmail from './ConfirmEmail';

const selector = null;

const actions = {
  confirm,
};

export { ConfirmEmail };

export default connect(
  selector,
  actions,
)(ConfirmEmail);
